import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { alertActions } from '../../slices/alert';
import { Alert } from 'react-bootstrap';

const AlertNotification = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useSelector(x => x.alert.value);
    const [show, setShow] = useState(true);

    useEffect(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    }, [location]);

    if (!alert) return null;

    return (
        
            <p className='text-gold'>{alert.message}</p> 
           
    );
}

export default AlertNotification;