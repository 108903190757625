import { loadStripe } from '@stripe/stripe-js';


const { REACT_APP_STRIPE_SECERATE_KEY,REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
let stripePromise;
const GetStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default GetStripe;