import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Row, Col, Container } from 'react-bootstrap'

import CheckoutForm from "../../../components/payment/CheckoutForm";
import { useLocation } from "react-router-dom";

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState(location.state?.payment_intent_client_secret);
  const [event,setEvent] = useState(location.state?.event);
  const [subId,setSubId] = useState(location.state?.subId);
  const [data,setData] = useState(location.state?.data);

  const appearance = {
    theme: 'night',
  };

  const options = {
    clientSecret,
    appearance,
  };


  useEffect(() => {
    //console.log(options);
    if (!clientSecret) {
      return;
    }
  }, []);
  return (
    <div className='main-content'>
      <section>

        <Container>
          <Row className="justify-content-center align-items-center height-self-center">
            
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm event={event} sub={subId} data={data}/>
                </Elements>
              )}
            
          </Row>
        </Container>

      </section>
    </div>
  );
}

export default Payment;