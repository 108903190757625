import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "subscriptions/";

const create = async (data) => {
  return axios
  .post(API_URL + "create", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      if (response.data) {
        localStorage.setItem("sub", JSON.stringify(response.data));
      }
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
    });  
       
};


const update = async (data) => {
  return axios
  .post(API_URL + "update", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      if (response.data) {
        localStorage.setItem("sub", JSON.stringify(response.data));
      }
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
    });  
}; 

const find = async (data) => {
  
  return axios
  .post(API_URL + "find", {userId:data?.id}
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      if (response.data) {
        localStorage.setItem("sub", JSON.stringify(response.data));
      }
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
    });  
}; 

const subscriptionlist = async (data) => {
  
  return axios
  .post(API_URL + "subscriptionlist", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      if (response.data) {
        localStorage.setItem("sub", JSON.stringify(response.data));
      }
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
    });  
}; 

const cancelsubscription = async (data) => {
  
  return axios
  .post(API_URL + "cancelsubscription", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
    });  
}; 




export default {
    create,
    update,
    find,
    subscriptionlist,
    cancelsubscription
};