import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { TAG } from "../../../constants/enum"
import FreeEvent from '../../../components/event/FreeEvent';
import SubscriptionEvent from '../../../components/event/SubscriptionEvent';
import PPVEvent from '../../../components/event/PPVEvent';
import EventService from '../../../services/event.service';
import { useSelector } from 'react-redux';
import InitializeGoogleAnalytics, { TrackPageView } from '../../../components/utils/googleanalytics';
import Login from './auth/login';


const EmbedEvent = (props) => {

    let navigate = useNavigate();
    const [payType, setPayType] = useState();
    const [data, setData] = useState({});
    const { user: currentUser } = useSelector(state => state.auth);
    const { id } = useParams();

    //console.log( id );

    // if (!currentUser) {
    //     navigate('/embed/login');
    // }

    const eventDetails = async () => {
        let res = await EventService.find(id);
        setPayType(res.data.tags[0]?.name);
        setData(res.data);
    }

    useEffect(() => {
      eventDetails();
      InitializeGoogleAnalytics();
     // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
      TrackPageView('pageview',window.location.pathname + window.location.search,'Combat Labs - Event');
    }, [id]);


    return (
        <>
            {/* {data && payType === TAG.F  && Object.keys(data).length && (
                <FreeEvent event={data}></FreeEvent>
            )
            }
            {data && payType === TAG.SUB  && Object.keys(data).length && (
                <SubscriptionEvent event={data}></SubscriptionEvent>
            )
            } */}
            {!currentUser && ( 
                <Login></Login>
            )}
            {currentUser && data && payType === TAG.PPV  && Object.keys(data).length && (
                <PPVEvent event={data} embed={true}></PPVEvent>
                    )
            }
           
        </>
    )
}

export default EmbedEvent;