import React, { useEffect } from 'react'
import { Container, Row, Col, Accordion, Button, Breadcrumb } from 'react-bootstrap'

import aboutus1 from '../../../assets/images/terms-pp/pp_tc.png'
import InitializeGoogleAnalytics, { TrackGoogleAnalyticsEvent, TrackPageView } from '../../../components/utils/googleanalytics';

const TermsOfUse = () => {

    useEffect  (() => {
        InitializeGoogleAnalytics();
      //  TrackGoogleAnalyticsEvent('Footer', 'TermsOfUse', 'TermsOfUse');
        TrackPageView('pageview',window.location.pathname + window.location.search,'Combat Labs - Terms of Use');
    }, []);
    return (
        <>
              <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${aboutus1})`}}>  
                <Container fluid> 
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                <h2 className="title">Terms of Use</h2>
                                <Breadcrumb className="main-bg">
                                     <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                                     <Breadcrumb.Item active>Terms of Use</Breadcrumb.Item>
                              </Breadcrumb>
                            </nav>
                        </Col>
                    </Row> 
                </Container>
            </div>
            <main id="main" className="site-main">
            <Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-privacy-policy">
                                <div className="mb-3">
                                    {/* <h4 className="mb-3">1. What Personal Information About Users Does streamit Collect?</h4> */}
                                    <p className="my-3">Agreement to the Terms and Conditions
Welcome to the websites (the “Sites”) and the mobile applications (the “Apps”), owned and operated by Combat Labs, Inc., a Texas corporation (referred to as "Combat Labs," “We,” “Our” or “Us”).
The terms and conditions set forth herein apply to all Sites and Apps owned and operated by Combat Labs, Inc including, but not limited to Combatlabs.tv, Combatlabs.io, Combatcast.tv, the Combat Labs, Inc App, the CombatLabs TV App and any other interim sites, mobile applications or portals designed, owned and operated by Combat Labs, Inc for purposes of providing content streaming services (“Services”). Content (“Content”) includes audio, videos, music, photos, text, graphics and other files or materials you may view on, access through, or contribute to the Sites, the Apps and the Services.
The Sites, the Apps and the Services available on the them are provided to you subject to: (i) the terms and conditions set forth herein, (ii) the Privacy Policy, and (iii) other terms and conditions and policies which you may find throughout our Sites or the Apps in connection with certain functionality, features or promotions as well as customer service, all of which are deemed a material part of and incorporated within these terms and conditions (collectively, "Terms and Conditions").
Accessing, browsing, downloading, installing, or using Our Sites, the Apps or the Services offered therein constitutes your understanding and agreement to be bound by the Terms and Conditions and expresses your acknowledgement that you have read and understood them and consented to them. We update our Terms and Conditions periodically and your access and use of the Sites and the Apps will be subject to the version of the Terms and Conditions effective as of the time of your use. IF YOU DO NOT AGREE TO ANY OF THESE TERMS, YOU MAY NOT USE THE SITES, THE APPS AND THE SERVICES PROVIDED THEREIN.
Eligibility for Use of the Sites and the Services
You affirm that you have reached the age of majority in the jurisdiction of your residence, or you are an emancipated minor, or have obtained the informed legal parental or guardian consent to access and use the Sites, the Apps and the Services and are fully able to enter into, agree to and abide by the Terms and Conditions set forth in herein.  Combat Labs, Inc hereby grants you permission to access and use the Sites, the Apps and the Services solely for personal non-commercial use, as set forth herein, provided that in your use of the Service, you will comply with all applicable laws.
Availability of Services
The availability of the Sites, the Apps, the Content and the Services, may be affected by factors, which are not within the control of Combat Labs, Inc, including, but not limited to, event delays or cancellations, third party technical problems, network delays,  program rescheduling or force major circumstances. You understand and agree that We under no obligation to provide You with any specific Content or Services under the Terms and Conditions.
Access to the Services. Technical Requirements
In order to access the Services, you must have access to the World Wide Web, either directly or through devices that access Web-based content, and must pay any service fees associated with such access. Not all of the features available through the Services, including certain live streaming audio, video or access to high-quality video, will be available to you unless your computer or mobile device satisfies the minimum technical requirements that are presented when you first register for the Services. As we make changes to the Services, the minimum technical requirements for access to the Services may change. You are responsible for determining whether your device satisfies the minimum technical requirements before you register to access the Services.  If We change the minimum technical requirements after your initial registration to access the Services and your device no longer satisfies the minimum technical requirements, your exclusive remedy will be to request termination of your access to the Services under the provisions set forth herein.
In order to access the Services, you will have to create an account on the Site or the App. When creating your account, you must provide accurate and complete information. Any information provided by You in connection with your registration of an account on the Sites or the Apps will be stored by Combat Labs, Inc in accordance with the terms of our Privacy Policy. We kindly ask that, prior to using the Site, you review our Privacy Policy with respect to the collection, use, and protection of your information.
You are not authorized to use third party accounts without their permission. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure and confidential. Combat Labs, Inc will not be responsible or  liable for your losses caused by any unauthorized use of your account. You must notify Combat Labs, Inc immediately of any unauthorized use of your account.
Content Viewing and Content Proprietary Rights
These Terms and Conditions to all users of the Sites, Apps and the Services, including contributors of Content to the Sites, Apps and Services.
The Services and the Content may contain links to third party websites, mobile applications and/or services that are not owned or controlled by Combat Labs, Inc. Combat Labs, Inc has no control over, and assumes no responsibility for, the Content and policies of any third party websites, mobile applications and/or services. By using the Services, you expressly relieve Combat Labs, Inc from any and all liability arising from your use of any third-party website, mobile application and/or service.
The Content, and the trademarks and logos (“Marks”) embedded therein are subject to copyright and other intellectual property rights of Combat Labs or its licensors. All trademarks, logos and any other proprietary designations displayed within the Content constitutes intellectual property of Combat Labs, Inc. or its licensors.  Content is provided to you as is. You may access Content for your information and personal use solely as intended through the provided functionality of the Services and as permitted under these Terms and Conditions. You shall not download, copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise use any Content for any other purposes without the prior written consent of the respective owners of the Content.
You acknowledge that the Sites and the Apps aggregate Content from different sources, and that Combat Labs, Inc is not responsible or liable in any way for the accuracy, usefulness, safety, or intellectual property rights of such Content. You acknowledge that you may be exposed to Content that is inaccurate, offensive or indecent and you hereby waive any legal rights or remedies that you may have against Combat Labs, Inc with respect to this, and agree to indemnify and hold harmless Combat Labs, Inc, its shareholders, directors, officers, employees, affiliates, licensors, and licensees regarding all matters related to your use of the Services.
You may submit Content to the Services. You understand that Combat Labs, Inc does not guarantee any confidentiality with respect to any Content you submit. You shall be solely responsible for this Content and the consequences of submitting and publishing it on the Services. You confirm and warrant that you own or have the necessary licenses, rights and permissions to publish the Content that you submit; and you license to Combat Labs, Inc all patent, trademark, copyright or other proprietary rights in and to such Content for publication on the Sites, Apps and the Services according to the Terms and Conditions herein.
By submitting Content to Combat Labs, Inc you hereby grant Combat Labs, Inc a worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, reproduce, distribute, display, and broadcast the Content on the Sites and the Apps and in connection with the Services provided therein, in any media formats and through any media channels. You also hereby grant each user of the Services a non-exclusive license to access your Content through the Services, and to use, reproduce, distribute, and display such Content as permitted through the functionality of the Sites, Apps and the Services and under these Terms and Conditions. You agree that the Content you submit to the Sites, Apps and the Services will not contain third party copyrighted material, or material that is subject to other third party proprietary rights, unless you have permission from the rightful owner of the material to post the material and to grant Combat Labs, Inc all of the license rights.
Combat Labs, Inc does not endorse any Content submitted to the Sites, Apps and the Services by any user or other content owner, or any opinion, recommendation, or advice expressed therein, and Combat Labs, Inc disclaims any liability in connection with the Content. Combat Labs, Inc does not permit infringement of copyright or any intellectual property rights on the Services and Combat Labs, Inc will remove all Content, if properly notified that such Content infringes any intellectual property rights. Combat Labs, Inc reserves the right to remove any Content without prior notice.
Pay Per View and Video on Demand Services
Pay Per View and Video on Demand Services are offered at the prices listed on the Sites or the Apps.  We reserve the right to modify the price of any Pay Per View Services based on market conditions, at any time, without notice.
Payments for Pay Per View and Video on Demand Services on the Sites are administered by third party payment gateway service providers. Combat Labs, Inc utilizes third party payment gateway providers, which allow use of variety types of credit cards.  In order to purchase Pay Per View Services, you may be required to provide complete and accurate information to the payment gateway providers, including, without limitation, your name, address, telephone number, e-mail address, credit card information and billing address.
Payments for Pay Per View and Video on Demand Services on the Apps are administered by an embedded credit card payment processor.  Information provided in connection with payments on the Apps is governed by our Privacy Policy.
Upon completion of a purchase, the Site and the Apps will provide confirmation of the purchase containing an order ID number. Please retain the order ID to allow you to access the Content you have purchased on multiple devices you may decide to use. These purchases will also be associated to your user account. 
Pay Per View and Video on Demand purchases are final excepting the specific circumstances set forth in the Refund Policy.
Refund Policy
Combat Labs, Inc will process refunds for Pay Per View and Video on Demand Services within 24-72 hours after the refund request was submitted. Refunds shall be issued only in the following cases:
1. You made double/multiple purchases in error error using the same registration/login data.
2. 
3. The Pay Per View Content was not available because an event was cancelled or the provider did not make the Content available for streaming.
4. 
5. Video on Demand Content was not available because the Publisher removed the Content.
6. 
7. More than 50% of the Content had streaming issues (no sound, or poor sound & video quality).


1. Refunds for Pay Per View Services must be submitted no later than 48 hours after a live broadcast of an event has finished.
2. Refunds for Video on Demand subscriptions must be submitted while subscription is active or no later than 48 hours from the time of subscription termination. 
3. 
To process your refund, please send an e-mail to sales@combatlabs.io and please reference the order ID number provided at the time of purchase of the Content for which a refund is requested. For purchases administered by iTunes, please submit a request for refund directly to iTunes along with the order ID number and  please send Us a supplemental email referencing the request to iTunes and the order ID number so that we can assist you with any other information necessary for the processing of the refund.
Restrictions on Pay Per View and Video on De    mand Services
Pay Per View and Video on Demand Services are provided solely for personal and residential use. Commercial establishments in any jurisdiction throughout the world are required to obtain a license from Combat Labs, Inc or the Content providers for Pay Per View and Video on Demand Services.
Free of Charge Services
The Sites and the Apps offer certain Services and Content free of charge.  To access free of charge Services and Content you need to create an account as provided above in section Access to Services.
Other Purchases
Additional terms and conditions may apply to purchases of goods or services and to specific portions or features of the Sites, including contests, promotions, sweepstakes or other similar features, all of which terms are made a part of these Terms of Service by this reference. Combat Labs, Inc may make changes to any products or services offered on the Sites, or to the applicable prices for any such products or services, at any time, without notice.
Warranty Disclaimer
YOU AGREE THAT YOUR USE OF THE SITES, THE APPS AND THE SERVICES SHALL BE AT YOUR SOLE RISK. COMBAT LABS, INC., ITS OFFICERS, EMPLOYEES, AND AFFILIATES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE OF IT. Combat Labs, Inc MAKES NO WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT OR ANY LINKED CONTENT AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF THE CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR TERMINATION OF TRANSMISSION FROM THE SERVICE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT MADE AVAILABLE THROUGH THE SERVICES. Combat Labs, Inc DOES NOT WARRANT, ENDORSE, OR GUARANTEE FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE OR ANY LINKED SERVICES OR FEATURED IN ANY BANNER OR OTHER FORM OF ADVERTISING.
COMBAT LABS, INC. PRESENTS AND PROVIDES THE SITES AND THE APPS "AS IS" WITHOUT WARRANTY, EITHER EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A SPECIFIC PURPOSE, FREEDOM FROM COMPUTER VIRUS, OR NON-INFRINGEMENT.
Limitation of Liability
IN NO EVENT SHALL COMBAT LABS, INC., ITS OFFICERS, EMPLOYEES, OR AFFILIATES, BE LIABLE TO YOU FOR ANY DIRECT OR INDIRECT DAMAGES RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF THE CONTENT, (II) PERSONAL OR PROPERTY DAMAGE OF ANY NATURE, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR TERMINATION OF TRANSMISSION FROM THE SERVICE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED THROUGH THE SERVICE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT MADE AVAILABLE THROUGH THE SERVICES.
Combat Labs, Inc makes no warranties that the Service is appropriate or available for use in other locations. If you access or use the Services from other jurisdictions, you are responsible for compliance with the local law.
Proprietary Rights
All information and Content available on the Sites, the Apps and their look and feel, including but not limited to domain name, trademarks, logos, service marks, trade names, content, features, designs, colors, product design, product features and descriptions, write-ups, functions, text, graphics, logos, button icons, images, audio clips, data compilations and software, and the compilation and organization thereof (collectively referred to as the "Proprietary Material") constitute worldwide intellectual property of Combat Labs, Inc or Our licensors.  The Proprietary Material is protected by European Union, United States and international laws, including laws governing copyrights, service marks and trademarks. Except for the very limited license set forth herein, or as required under applicable law, the Proprietary Material or any portion of the Sites or the Apps cannot be used, reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited, in whole or in part, for any purpose whatsoever.
Restrictions on Use of the Sites and the Apps
You are not authorized to: (i) modify, reverse engineer or create any derivative works based upon the Sites, the Apps or any Proprietary Material; (ii) frame or utilize framing techniques to enclose the Site or any portion thereof; (iii) use any meta tags, "hidden text", robots, spiders, crawlers, or other tools, whether manual or automated, to collect, scrape, index, mine, republish, redistribute, transmit, sell, license or download the Site, Proprietary Material (except caching or as necessary to view the Site), or the personal information of others without our prior written permission or authorization; (iv) make any use of the Site, the Apps or any Proprietary Material other than for personal use; (v) impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with any person or entity; (vi) "stalk" or otherwise harass including advocating harassment of another, entrap or harm any third party including harming minors in any way; (vii) violate or solicit the violation of any applicable local, state, national or international law; (vii) transmit, upload, post, e-mail, share, distribute, reproduce, or otherwise make available any software viruses, malware, program, code, file, or other material intended to interrupt, disrupt, alter, destroy, or limit any part of the Site; and/or; (viii) engage or make any unsolicited or unauthorized advertising, solicitation or promotional material, including chain letters, mass mailings, or any form of "spam."
Indemnity
To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Combat Labs, Inc, its officers, employees and affiliates, from and against any claims, damages, obligations, losses, liabilities or costs (including but not limited to attorney’s fees) arising from: (i) your use of and access to the Sites, the Apps and the Services; (ii) your violation of any term of these Terms and Conditions; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This defense and indemnification obligation will survive these Terms of Service and your use of the Services.
DMCA
If you are a copyright owner or a representative of a copyright owner and you believe that any Content infringes your copyright, you may submit a notification according to the Digital Millennium Copyright Act (“DMCA”) to sales@combatlabs.io with the following information in writing:
• Identification of the copyrighted work claimed to have been infringed;
• Identification of the material that is claimed to be infringing;
• Your mailing address, telephone number and email address;
• A statement that you have a good faith belief that use of the material is not authorized by the copyright owner, its representative, or the law; and
• A statement that the information in the notification is accurate, and that you are the copyright owner or you are authorized to act on behalf of the copyright owner.</p><br/>
                                </div>
                                {/* <div className="mb-3">
                                    <h4 className="mb-3">2. Cookies and Web Beacons</h4>
                                    <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed.</p>
                                    <p className="mb-0">Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-3">
                                    <h4 className="mb-3">3. Third Party Payment Gateway – Financial Information</h4>
                                    <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-3">
                                    <h4 className="mb-3">4. Disclosure Children’s Privacy</h4>
                                    <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-0">
                                    <h4 className="mb-3">5. Data transfer, storage &amp; processing globally</h4>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            {/* <Container fluid id="faqAccordion">
                <Row>
                    <Col lg="12">
                        <Accordion className="mm-accordion career-style faq-style" defaultActiveKey="0">
                            <Card className="mm-accordion-block accordion">
                                <div className="active-faq clearfix" id="headingOne">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="0">
                                                    <span>Lorem ipsum dolor sit </span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse eventKey="0" className="accordion-details">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS. 
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="mm-accordion-block">
                                <div className="active-faq clearfix" id="headingTwo">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="1">
                                                    <span>consectetur adipiscing elit</span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse className="accordion-details" eventKey="1">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS.
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="mm-accordion-block ">
                                <div className="active-faq clearfix" id="headingThree">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="2">
                                                    <span>Etiam sit amet justo non</span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse className="accordion-details" eventKey="2">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS.
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="mm-accordion-block ">
                                <div className="active-faq clearfix" id="headingFour">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="3">
                                                    <span>velit accumsan laoreet</span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse className="accordion-details"  eventKey="3">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS.
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="mm-accordion-block">
                                <div className="active-faq clearfix" id="headingFive">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="4">
                                                    <span>Donec volutpat metus in erat</span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse className="accordion-details" eventKey="4">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS.
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="mm-accordion-block">
                                <div className="active-faq clearfix" id="headingSix">
                                    <Container fluid>
                                        <Row>
                                            <Col sm="12">
                                                <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="5">
                                                    <span>quam quis massa tristique</span>
                                                </Accordion.Toggle>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Accordion.Collapse className="accordion-details" eventKey="5">
                                    <p className="mb-0">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                        aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft
                                        beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                        sustainable VHS.
                                    </p>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container> */}
            </main>
        </>
    )
}

export default TermsOfUse;