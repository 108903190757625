import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row, Col, Container } from 'react-bootstrap'
import FormattedDate from "../utils/FormattedDate"
import 'swiper/swiper-bundle.css';
import { useSelector } from 'react-redux';
import VideoPlayer from '../video/VideoPlayer';
import Recent from './Recent';

const SubscriptionEvent = (props) => {

    let navigate = useNavigate();

    const { user: currentUser } = useSelector(state => state.auth);
    const { sub: currentSubscription } = useSelector(state => state.sub);
    const [data, setData] = useState(props.event);
    const videoUrl = data.video?.videoUrl;
    let type = "application/x-mpegURL";

    // Check if the video URL has .mp4 extension
    if (videoUrl && videoUrl.toLowerCase().endsWith(".mp4")) {
        type = "video/mp4";
    }

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: false,
        fluid: true,
        poster: data.event_promotional_photo,
        sources: [{
            src: videoUrl,
            type: type
        }]
    };

    useEffect(() => {

        if (!currentUser) {
            navigate('/login');
        }
        
        //console.log(currentSubscription)
    
        if ( currentSubscription == null  || !currentSubscription?.isActive) {
            navigate('/subscribe',{
                state: {  // location state
                    event: data,
                },
            });
        }
    }, []);
    
    return (
        <>
            
            <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12"  onContextMenu={e => e.preventDefault()} >
                                     {/* <div className='video-container iq-main-slider'> */}
                                        <VideoPlayer  {...videoJsOptions} > 
                                        </VideoPlayer>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <section id="event-detail">
                            <div className='container-fluid'>
                                <Row>
                                    <Col lg="12">
                                        <div className="trending-info season-info g-border">
                                            <h4 className="trending-text big-title text-uppercase mt-0">{data.name}</h4>
                                            <div className="d-flex align-items-center text-white text-detail mb-0">
                                                <FormattedDate date={data.event_date} />
                                            </div>
                                            <p className="trending-dec w-100 mb-0">{data.description}
                                            </p>
                                            {/* <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                                                <li className="share">
                                                    <span><i className="ri-share-fill"></i></span>
                                                    <div className="share-box">
                                                        <div className="d-flex align-items-center">
                                                            <Link to="#" className="share-ico"><i className="ri-facebook-fill"></i></Link>
                                                            <Link to="#" className="share-ico"><i className="ri-twitter-fill"></i></Link>
                                                            <Link to="#" className="share-ico"><i className="ri-links-fill"></i></Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> 
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section> */}
                        {data && (<Recent event={data}></Recent>)}
                    </div>
        </>
    )
}

export default SubscriptionEvent;



