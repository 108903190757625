import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { TAG } from "../../../constants/enum";
import "swiper/swiper-bundle.css";
import EventService from "../../../services/event.service";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import FormattedDate from "../../../components/utils/FormattedDate";
import Recent from "../../../components/event/Recent";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import Featuring from "../../../components/bout/featuring";
import boutService from "../../../services/bout.service";
import { logout } from "../../../slices/auth";

const Embed = (props) => {
  const [payType, setPayType] = useState();
  const [data, setData] = useState();
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [fighters, setFigheters] = useState(props.fighters);
  let dispatch = useDispatch();

  const listContenders = async (id) => {
    let listFighters = [];
    await boutService
      .listContendersByEvent({ eventId: id })
      .then((response) => {
        if (response) {
          response.map((item) => {
            let contender1 = item.fighter1?.user;
            let contender2 = item.fighter2?.user;

            if (contender1) {
              listFighters.push({
                id: contender1.id,
                name: contender1.fullName,
                weight: item.fighter1.weight,
                height: item.fighter1.height,
              });
            }
            if (contender2) {
              listFighters.push({
                id: contender2.id,
                name: contender2.fullName,
                weight: item.fighter2.weight,
                height: item.fighter2.height,
              });
            }
          });
          setFigheters(listFighters);
        }
      });
  };

  const eventDetails = async () => {
    let res = await EventService.findByEmbedcode(code);

    setPayType(res.data.tags[0]?.name);
    setData(res.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(logout());
    eventDetails();
    // listContenders(code);
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Event Details"
    );
  }, [code]);

  return (
    <>
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <main className="main-content">
          <div className="mandalorian">
            {data?.name && (
              <Link
                to={{
                  pathname: "/embed/event/" + data.id,
                  state: {
                    item: data,
                  },
                }}
              >
                <div
                  class="banner-wrapper overlay-wrapper iq-main-slider"
                  style={{
                    backgroundImage:
                      "url(" + data.event_promotional_photo + ")",
                  }}
                >
                  <div class="banner-caption">
                    <div class="movie-detail">
                      <Row>
                        <Col xl="12" lg="12" md="12">
                          <div class="trending-info p-0">
                            <h1 class="trending-text big-title text-uppercase mt-0">
                              {data.name}
                            </h1>
                            <div
                              class="d-flex flex-wrap align-items-center text-white text-detail"
                              data-animation-in="fadeInLeft"
                            >
                              {data?.event_date && (
                                <FormattedDate date={data.event_date} />
                              )}
                            </div>
                            {data.description && (
                              // <div class="d-flex flex-wrap align-items-center text-white text-detail" data-animation-in="fadeInLeft">
                              <p>{data.description}</p>
                              // </div>
                            )}
                            <div
                              class="d-flex flex-wrap align-items-center text-white "
                              data-animation-in="fadeInLeft"
                            >
                              <span className="">
                                Combat Type:{" "}
                                {data?.combattypes
                                  ?.map((combattype) => combattype.name)
                                  .join(", ")}
                              </span>
                            </div>
                            <div
                              class="d-flex flex-wrap align-items-center text-white"
                              data-animation-in="fadeInLeft"
                            >
                              <div className="iq-secondary title genres">
                                <span className="">
                                  {" "}
                                  Venue Name & Location: {data?.location},{" "}
                                  {data?.city}, {data?.state}
                                </span>
                              </div>
                            </div>
                            <div
                              class="d-flex flex-wrap align-items-center text-white"
                              data-animation-in="fadeInLeft"
                            >
                              <span className="">
                                Tag:{" "}
                                {data?.tags?.map((tag) => tag.name).join(", ")}{" "}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div class="position-relative mt-5">
                      <div class="d-flex align-items-center">
                        <div className="play-button">
                          <i class="ri-play-fill"></i>
                        </div>
                      </div>
                      {payType === TAG.PPV && (
                        <h4 className="w-name text-white font-weight-700">
                          Buy ${data?.price}
                        </h4>
                      )}
                      {(payType === TAG.SUB || payType === TAG.F) && (
                        <h4 className="w-name text-white font-weight-700">
                          Play now
                        </h4>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </Link>
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default Embed;
