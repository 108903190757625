import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout1 from '../layouts/backend/layout1';
import BlankLayout from '../layouts/blanklayout';
import Login from '../views/backend/auth/login';
import SignUp from '../views/backend/auth/signup';
import ForgotPassword from '../views/backend/auth/forgot-password';
import ResetPassword from '../views/backend/auth/reset-password';
import Home from '../views/backend/home/home';
import Payment from '../views/backend/payment/Payment';
import Promotion from '../views/backend/promotion/Promotion';
import EventDetail from '../views/backend/event/details';
import Event from '../views/backend/event/event';
import Events from '../views/backend/event/events';
import TermsOfUse from '../views/backend/home/termsOfUse';
import PrivacyPolicy from '../views/backend/home/privacyPolicy';
import FAQ from '../views/backend/home/faq';
import UserEvent from '../views/backend/event/mylist';
import Subscribe from '../views/backend/subscribe/Subscribe';
import Status from '../views/backend/payment/PaymentStatus';
import Embed from '../views/backend/embed/embed';
import EmbedEvent from '../views/backend/embed/event';
import EmbedLogin from '../views/backend/embed/auth/login';
import EmbedSignUp from '../views/backend/embed/auth/signup';
import EmbedForgotPassword from '../views/backend/embed/auth/forgot-password';
import EmbedResetPassword from '../views/backend/embed/auth/reset-password';

import { useDispatch, useSelector } from 'react-redux';
import EventBus from '../common/EventBus';
import { logout } from '../slices/auth';
import { useCallback, useEffect } from 'react';
import { removeSubscription } from '../slices/sub';
import Error404 from '../views/backend/error/error404';
import Error500 from '../views/backend/error/error500';
import EmbedLayout from '../layouts/embedlayout';
import UserProfile from '../views/backend/app/usermanagement/userprofile';
import UserAccountSetting from '../views/backend/app/usermanagement/useraccountsetting';
import Fighter from '../views/backend/fighter/fighter';
import ViewTicket from '../views/backend/event/ticketdetail';

const LayoutsRoute = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(removeSubscription());
    dispatch(logout());
  }, [dispatch]);

  const error500 = useCallback(() => {
    navigate('/500');
  }, [dispatch]);

  useEffect(() => {
    EventBus.on('logout', () => {
      logOut();
    });

    EventBus.on('error500', () => {
      error500();
    });

    return () => {
      EventBus.remove('logout');
      EventBus.remove('error500');
    };
  }, [currentUser, logOut, error500]);

  return (
    <Routes>
      <Route path="/extra-pages" component={BlankLayout} />
      <Route exact path="/" element={<Layout1></Layout1>}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login></Login>} />
        <Route path="sign-up" element={<SignUp></SignUp>} />
        <Route path="forgot-password" element={<ForgotPassword></ForgotPassword>} />
        <Route path="reset-password/:token" element={<ResetPassword></ResetPassword>} />
        <Route path="checkout" element={<Payment></Payment>}></Route>
        <Route path=":subdomain" element={<Promotion></Promotion>}></Route>
        <Route path=":subdomain/:slug" element={<EventDetail></EventDetail>} />
        <Route path=":subdomain/:slug/ppv" element={<Event></Event>} />
        <Route path=":subdomain/:slug/ticket" element={<Event></Event>} />
        <Route path=":subdomain/:slug/ppv/:fighterId" element={<Event></Event>} />
        <Route path=":subdomain/:slug/ticket/:fighterId" element={<Event></Event>} />
        <Route path="events" element={<Events></Events>} />
        <Route path="terms-of-service" element={<TermsOfUse></TermsOfUse>} />
        <Route path="manage-profile" element={<UserProfile></UserProfile>} />
        <Route
          path="setting"
          element={<UserAccountSetting></UserAccountSetting>}
        />
        <Route path="fighter/:id" element={<Fighter></Fighter>} />
        <Route
          path="privacy-policy"
          element={<PrivacyPolicy></PrivacyPolicy>}
        />
        <Route path="faq" element={<FAQ></FAQ>} />
        <Route path="mylist" element={<UserEvent></UserEvent>} />
        <Route path="mylist/viewticket" element={<ViewTicket></ViewTicket>} />
        <Route path="subscribe" element={<Subscribe></Subscribe>} />

        <Route path="status" element={<Status></Status>} />
      </Route>
      <Route path="/*" element={<Error404 />}>
        <Route index element={<Error404 />} />
        <Route path="500" element={<Error500 />} />
      </Route>
      <Route path="embed/" element={<EmbedLayout />}>
        <Route path="eventdetails/:code" element={<Embed />} />
        <Route path="event/:id" element={<EmbedEvent />} />
        <Route path="login" element={<EmbedLogin />} />
        <Route path="sign-up" element={<EmbedSignUp />} />
        <Route path="forgot-password" element={<EmbedForgotPassword></EmbedForgotPassword>} />
        <Route path="reset-password/:token" element={<EmbedResetPassword></EmbedResetPassword>} />
      </Route>
    </Routes>
  );
};

export default LayoutsRoute;
