import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../Card'
//img


const CommingSoon = (props) => {
  let navigate = useNavigate();
  useEffect(
    () => {
      //count down pluing js
      function getTimeRemaining(endtime) {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
          total,
          days,
          hours,
          minutes,
          seconds
        };
      }

      function initializeClock(elem, endtime) {
        const clock = document.querySelector(elem)
        const daysSpan = clock.querySelector('[data-days]')
        const hoursSpan = clock.querySelector('[data-hours]')
        const minutesSpan = clock.querySelector('[data-minutes]')
        const secondsSpan = clock.querySelector('[data-seconds]')

        function updateClock() {
          const t = getTimeRemaining(endtime)

          daysSpan.innerHTML = t.days
          hoursSpan.innerHTML = ('0' + t.hours).slice(-2)
          minutesSpan.innerHTML = ('0' + t.minutes).slice(-2)
          secondsSpan.innerHTML = ('0' + t.seconds).slice(-2)

          if (t.total <= 0) {
            clearInterval(timeinterval)
            navigate(0);
          }
        }

        updateClock()
        const timeinterval = setInterval(updateClock, 1000)
      }

      let time = document.querySelector('.countdown').getAttribute('data-date')
      if (time === undefined) {
        time = Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000
      }
      const deadline = new Date(time)
      initializeClock('.countdown', deadline)
    }
  )

  return (
    <>
      <div className="iq-comingsoon">
        <div className='mandalorian'>
          {props.data.event_promotional_photo && (
            <div class="banner-wrapper overlay-wrapper iq-main-slider" style={{ backgroundImage: "url(" + props.data.event_promotional_photo + ")" }}>
              <div id="comingsoon-overlay">
                  <Row className="justify-content-center">
                    <Col sm="8" className="text-center">
                      <div className="iq-comingsoon-info">
                        <h2 className="mt-4 mb-1">Stay tuned! Event will start shortly.</h2>
                        {/* <p>We are working very hard to give you the best experience possible!</p> */}
                        <ul className="countdown d-flex align-items-center list-inline" data-date={props.data.event_date}>
                          <li className="col-md-6 col-lg-3">
                            <Card>
                              <Card.Body>
                                <span data-days>0</span> Days
                              </Card.Body>
                            </Card>
                          </li>
                          <li className="col-md-6 col-lg-3">
                            <Card>
                              <Card.Body>
                                <span data-hours>0</span> Hours
                              </Card.Body>
                            </Card>
                          </li>
                          <li className="col-md-6 col-lg-3">
                            <Card>
                              <Card.Body>
                                <span data-minutes>0</span> Minutes
                              </Card.Body>
                            </Card>
                          </li>
                          <li className="col-md-6 col-lg-3">
                            <Card>
                              <Card.Body>
                                <span data-seconds>0</span> Seconds
                              </Card.Body>
                            </Card>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
              </div>
            </div>)}
        </div>

      </div>
    </>
  )
}

export default CommingSoon;