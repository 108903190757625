import React, { useState } from "react";
import { Link } from "react-router-dom";

const Featuring = (props) => {
  const [fighters, setFigheters] = useState(props.fighters);

  return (
    <>
    {fighters && fighters.length > 0 && (
      <section id="event-detail">
        <div className="container-fluid">
          <div
            id="iq-favorites"
            class="s-margin detail-cast-list iq-rtl-direction mt-0 starring mb-5"
          >
            <div class="m-0 row">
              <div class="overflow-hidden p-0 col-sm-12">
                <div class="iq-main-header d-flex align-items-center justify-content-between iq-ltr-direction">
                  <h4 class="main-title">Featuring</h4>
                </div>
              </div>
            </div>
            <ul class="inner-slider list-inline p-0 m-0 row">
              {fighters?.map((fighter) => (
                <li class=" slide-item iq-ltr-direction col-6 col-xl-6 col-lg-6 col-md-6 mt-3">
                  <Link
                    to={{
                      pathname: "/fighter/" + fighter?.id,
                      state: {
                        item: fighter,
                      },
                    }}
                  >
                    <div class="cast-images position-relative mx-0 row">
                      <div class="col-12 img-box p-0 col-sm-4">
                        <img
                          src="../assets/images/person/01.jpg"
                          class="person__poster--image img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="col-12 block-description starring-desc col-sm-8">
                        <h6 class="iq-title">{fighter?.name}</h6>
                        {fighter?.weight ? (
                          <div class="video-time d-flex align-items-center my-2">
                            <span class="text-white">{fighter?.weight}</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section> )}
    </>
  );
};

export default Featuring;
