import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "payments/";

const createStripeSubscription = async (data) => {

  return axios
    .post(API_URL + "stripe/subscription/create", data
      , { headers: authHeader(), 'content-type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*" }).then((response) => {
        return response.data;
      })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
       // navigate('/500');
      }

    })

};


const charge = async (data) => {

  return axios
    .post(API_URL + "stripe/charge", data
      , { headers: authHeader(), 'content-type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*" }).then((response) => {
        return response.data;
      })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
       // navigate('/500');
      }
    });



};

const updateCharge = async (data) => {
  return axios
    .post(API_URL + "stripe/updatecharge", data
      , { headers: authHeader(), 'content-type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*" }).then((response) => {
        return response.data;
      })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
       // navigate('/500');
      }
    });
};

export default {
  charge,
  createStripeSubscription,
  updateCharge,
};