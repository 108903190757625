import React,{useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Col, Row, Container, Form, Tab, Button, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux';

// import Choices from 'react-choices'
import  Card from '../../../../components/CardCustom';

// Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//Import UserProfile Service
import UserProfileService from '../../../../services/userprofile.service';
import AuthService from '../../../../services/auth.service';
import Alert from '../../../../components/AlertNotification/AlertNotification';
//Import Subscription Service
import SubscriptionService from '../../../../services/subscription.service';
//select
import Select from 'react-select';
import user1 from '../../../../assets/images/user/user.jpg';
const moment = require('moment');

const UserProfile = () => {

    let navigate = useNavigate();
    let userId = '';
    let stripeCustomerId = '';
    const { user:currentUser } = useSelector(state => state.auth);
    if( !currentUser ) {
        navigate('/login');
    }
    
    if(currentUser){
        userId = currentUser.user?.id;
        stripeCustomerId = currentUser.user?.stripeCustomerId
        
    }
    
    
    const states = [
        
        {value:"AL",label:"Alabama"},
        {value:"AK",label:"Alaska"},
        {value:"AZ",label:"Arizona"},
        {value:"AR",label:"Arkansas"},
        {value:"CA",label:"California"},
        {value:"CO",label:"Colorado"},
        {value:"CT",label:"Connecticut"},
        {value:"DE",label:"Delaware"},
        {value:"DC",label:"District Of Columbia"},
        {value:"FL",label:"Florida"},
        {value:"GA",label:"Georgia"},
        {value:"HI",label:"Hawaii"},
        {value:"ID",label:"Idaho"},
        {value:"IL",label:"Illinois"},
        {value:"IN",label:"Indiana"},
        {value:"IA",label:"Iowa"},
        {value:"KS",label:"Kansas"},
        {value:"KY",label:"Kentucky"},
        {value:"LA",label:"Louisiana"},
        {value:"ME",label:"Maine"},
        {value:"MD",label:"Maryland"},
        {value:"MA",label:"Massachusetts"},
        {value:"MI",label:"Michigan"},
        {value:"MN",label:"Minnesota"},
        {value:"MS",label:"Mississippi"},
        {value:"MO",label:"Missouri"},
        {value:"MT",label:"Montana"},
        {value:"NE",label:"Nebraska"},
        {value:"NV",label:"Nevada"},
        {value:"NH",label:"New Hampshire"},
        {value:"NJ",label:"New Jersey"},
        {value:"NM",label:"New Mexico"},
        {value:"NY",label:"New York"},
        {value:"NC",label:"North Carolina"},
        {value:"ND",label:"North Dakota"},
        {value:"OH",label:"Ohio"},
        {value:"OK",label:"Oklahoma"},
        {value:"OR",label:"Oregon"},
        {value:"PA",label:"Pennsylvania"},
        {value:"RI",label:"Rhode Island"},
        {value:"SC",label:"South Carolina"},
        {value:"SD",label:"South Dakota"},
        {value:"TN",label:"Tennessee"},
        {value:"TX",label:"Texas"},
        {value:"UT",label:"Utah"},
        {value:"VT",label:"Vermont"},
        {value:"VA",label:"Virginia"},
        {value:"WA",label:"Washington"},
        {value:"WV",label:"West Virginia"},
        {value:"WI",label:"Wisconsin"},
        {value:"WY",label:"Wyoming"},
    ];
    // set value for default selection
    
    const [passwordChangeMessage, setPsswordChangeMessage] = useState();
    const [errorpassword, setPasswordError] = useState('');
    const [profileMessage, setProfileMessage] = useState('');
    const [errorprofile, setProfileError] = useState('');
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    //const [stripeCustomerId, setStripeCustomerId] = useState('');

    
    const [profileData, setData] = useState({
            
            user:{
                id:'',
                firstname:'',
                lastname:'',
                username:''
            },
            gender:'',
            age:'',
            phone_number:'',
            address_line_1:'',
            address_line_2:'',
            city:'',
            state:'',
            country:'',
            firstname:'',
            lastname:''
        
    });

    const [passwordData, setPasswordData] = useState({
        userId,
    });
    
    //Get user profile details
    const profileDetails = async () => {
        let res = await UserProfileService.find(userId);
        setData(res);
        
    }

    //Get customer's strip subscriptions
    const getSubscriptions = async () => {

        if(userId && stripeCustomerId){
            let resData={
                userId:userId,
                stripeCustomerId:stripeCustomerId
            };
            let res = await SubscriptionService.subscriptionlist(resData);
           
            setUserSubscriptions(res.subscritpions);
        }
    } 

    const handleChange = (e) => {
        setData({...profileData,userId:userId});
        setData({ ...profileData, [e.target.name]: e.target.value });
      };

    const handlePasswordChange = (e) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
        setPasswordError();
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle profile update logic here
        updateProfile(profileData);
    
    };

    const handleSubmitPassword = (e) => {
        e.preventDefault();
        if(passwordData.newpassword === passwordData.confirmPassword){
            updatePassword(passwordData);
        }else{
            setPasswordError("New Password and Verify Password does not match.");
        }
        //updateProfile(profileData);
    
    };

    const cancelSubscription = async (e,subscriptionId,action) => {
        e.preventDefault();
        const reqData = {
            userId: currentUser.user.id,
            subscriptionId: subscriptionId,
            action: action
        }

        const res = await SubscriptionService.cancelsubscription(reqData);
        if(res.status === 200){
           
            getSubscriptions();
           
        }
    }

     //Update Password
     const updatePassword = async (passwordData) => {
       
        await AuthService.changepassword(passwordData).then((response) => {
            setPsswordChangeMessage(response.message);
        },
            (error) => {
                const errorMesages =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                setPasswordError(errorMesages.message);
            }
        );

    }

    const updateProfile = async(profileData) => {
        
        await UserProfileService.update(profileData).then((response) => {
             let responseData = response;
             setData(responseData);
             if(responseData){
                setProfileMessage("Profile Information Updated Succesfully!!");
             }
             
         },
             (error) => {
                 const profileError =
                     (error.response && error.response.data) ||
                     error.message ||
                     error.toString();
                    setProfileError(profileError);
             }
         );
 
     }

    useEffect(() => {
        profileDetails();
        getSubscriptions();
    }, []);


    return (
        <>
            <div className="main-content">
            <Container fluid>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="iq-card-body profile p-0">
                                    <div className="iq-edit-list">
                                        <Nav as="ul" variant="pills" className="iq-edit-profile d-flex">
                                            <Nav.Item as="li" className="col-md-4 p-0">
                                                <Nav.Link  eventKey="first" role="button">
                                                    Personal Information
                                                </Nav.Link>
                                            </Nav.Item>            
                                            <Nav.Item as="li" className="col-md-4 p-0">
                                                <Nav.Link eventKey="second" role="button">
                                                    Change Password
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item as="li" className="col-md-3 p-0">
                                                <Nav.Link  eventKey="third" role="button">
                                                    Email and SMS
                                                </Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item as="li" className="col-md-4 p-0">
                                                <Nav.Link eventKey="fourth" role="button">
                                                    Manage Subscriptions
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                       {profileData && (
                        <Col lg="12">
                            
                                <Tab.Content >
                                    <Tab.Pane eventKey="first" className="fade show">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Personal Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                            
                                                <Form onSubmit={handleSubmit} name='personalDetails'>
                                                    <div className="form-group row align-items-center">
                                                        <div className="col-md-12">
                                                            <div className="profile-img-edit">
                                                                <img className="profile-pic avatar-80 img-fluid" src={user1} alt="profile-pic" style={{height: "150px", width: "150px"}}/>
                                                                {/* <div className="p-image">
                                                                    <i className="ri-pencil-line upload-button"></i>
                                                                    <input className="file-upload" type="file" accept="image/*"/>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Row className=" align-items-center">
                                                        <Form.Group controlId="fname" as={Col} sm="6">
                                                            <Form.Label>First Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="firstname"
                                                                style={{ backgroundColor: 'transparent' }}
                                                                defaultValue={profileData.user.firstname}
                                                                onChange={handleChange}
                                                                placeholder="Enter your name"
                                                                required
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlId="lname" as={Col} sm="6">
                                                            <Form.Label>Last Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lastname"
                                                                defaultValue={profileData.user.lastname}
                                                                onChange={handleChange}
                                                                placeholder="Enter your last name"
                                                                required
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlId="uname" as={Col} sm="6">
                                                            <Form.Label>User Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lastname"
                                                                value={profileData.user.username}
                                                                onChange={handleChange}
                                                                placeholder="Enter your last name"
                                                                required
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Phone:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone_number"
                                                                defaultValue={profileData.phone_number}
                                                                onChange={handleChange}
                                                                placeholder="Enter Phone Number"
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        
                                                        <Form.Group as={Col} sm="6">
                                                            <Form.Label bsPrefix="d-block">Gender:</Form.Label>
                                                            <Form.Control as="select" onChange={handleChange} value={profileData.gender}  name="gender" style={{borderColor:'#404043'}}>
                                                                <option value="">Select Gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Age</Form.Label>
                                                            <Form.Control as="select" onChange={handleChange} value={profileData.age}  name="age" style={{borderColor:'#404043'}}>
                                                                <option value="">Select Age</option>
                                                                <option value="18-25">18-25</option>
                                                                <option value="26-35">26-35</option>
                                                                <option value="36-45">36-45</option>
                                                                <option value="46-55">46-55</option>
                                                                <option value="56-65">56-65</option>
                                                                <option value="65>">65 And Above</option>
                                                            </Form.Control>
                                                            {/* <Form.Control
                                                                type="text"
                                                                name="age"
                                                                defaultValue={profileData.age}
                                                                onChange={handleChange}
                                                                placeholder="Your Age"
                                                            /> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Address Line 2:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="address_line_1"
                                                                defaultValue={profileData.address_line_1}
                                                                onChange={handleChange}
                                                                placeholder="Address Line 1"
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Address Line 2:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="address_line_2"
                                                                defaultValue={profileData.address_line_2}
                                                                onChange={handleChange}
                                                                placeholder="Address Line 2"
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group controlId="cname" as={Col} sm="6">
                                                            <Form.Label>City:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="city"
                                                                defaultValue={profileData.city}
                                                                onChange={handleChange}
                                                                placeholder="Enter your city"
                                                                style={{ backgroundColor: 'transparent' }}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="exampleFormControlSelect4" sm="6">
                                                            <Form.Label>State:</Form.Label>
                                                            <Form.Control as="select" onChange={handleChange} value={profileData.state} name="state" style={{borderColor:'#404043'}}> 
                                                                <option value="">Select State</option>
                                                                {states.map((state, index) => (
                                                                    <option key={index} value={state.value}>
                                                                    {state.label}
                                                                </option>
                                                                ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="exampleFormControlSelect3" sm="6">
                                                            <Form.Label>Country:</Form.Label>
                                                            <Form.Control as="select" name="country" onChange={handleChange} value={profileData.country}  style={{borderColor:'#404043'}}>
                                                                <option value="">Select Country</option>
                                                                <option value="Canada">Canada</option>
                                                                <option value="USA">USA</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                       
                                                    </Row>
                                                    {profileMessage && <p className='text-gold'>Success: {profileMessage}</p>}
                                                    {errorprofile && <p className='text-gold'>Error: {errorprofile}!</p>}
                                                    <Row>
                                                        <div class="col-md-12">
                                                            <Button type="submit" variant="primary" className="btn btn-hover btn-primary1 float-right m-2">Submit</Button>
                                                            <Button type="reset" variant="mr-2" className="btn btn-hover btn-primary1 float-right m-2">Cancel</Button>
                                                        </div>
                                                    </Row>
                                                    
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" className="fade show">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Change Password</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form  onSubmit={handleSubmitPassword} name='changePassword'>
                                                    <Form.Group controlId="cpass">
                                                        <Form.Label>Current Password:</Form.Label>
                                                        <Link to="/" onClick={ e => e.preventDefault()}  className="float-right">Forgot Password</Link>
                                                        <Form.Control  type="Password" name="password" onChange={handlePasswordChange} />
                                                    </Form.Group>
                                                    <Form.Group controlId="npass">
                                                        <Form.Label>New Password:</Form.Label>
                                                        <Form.Control type="Password" name="newpassword" onChange={handlePasswordChange} />
                                                    </Form.Group>
                                                    <Form.Group controlId="vpass">
                                                        <Form.Label>Verify Password:</Form.Label>
                                                        <Form.Control type="Password" name="confirmPassword" onChange={handlePasswordChange} />
                                                    </Form.Group>
                                                    {errorpassword && <p className='text-gold'>Error: {errorpassword}</p>}
                                                    {passwordChangeMessage && <p className='text-gold'>Success: {passwordChangeMessage}</p>}
                                                    <Row>
                                                        <div class="col-md-12">
                                                            <Button type="submit" variant="primary" className="btn btn-hover btn-primary1 float-right m-2">Submit</Button>
                                                            <Button type="reset" variant="mr-2" className="btn btn-hover btn-primary1 float-right m-2">Cancel</Button>
                                                        </div>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third" className="fade show">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Email and SMS</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} className="align-items-center" controlId="emailnotification">
                                                        <Form.Label className="col-md-3">Email Notification:</Form.Label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <Form.Check.Input  bsPrefix="custom-control-input"  defaultChecked />
                                                            <Form.Label bsPrefix="custom-control-label"></Form.Label>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center" controlId="smsnotification">
                                                        <Form.Label bsPrefix="col-md-3">SMS Notification:</Form.Label>
                                                        <div className="col-md-9 custom-control custom-switch">
                                                            <Form.Check.Input  bsPrefix="custom-control-input" defaultChecked />
                                                            <Form.Label bsPrefix="custom-control-label"></Form.Label>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center">
                                                        <Form.Label bsPrefix="col-md-3" htmlFor="npass">When To Email</Form.Label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email01" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email01">You have new notifications.</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email02" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email02">You're sent a direct message</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input  bsPrefix="custom-control-input" id="email03" defaultChecked />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email03">Someone adds you as a connection</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="align-items-center">
                                                        <Form.Label bsPrefix="col-md-3" htmlFor="npass">When To Escalate Emails</Form.Label>
                                                        <div className="col-md-9">
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email04" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email04"> Upon new order.</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email05" />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email05"> New membership approval</Form.Label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox">
                                                                <Form.Check.Input bsPrefix="custom-control-input" id="email06" defaultChecked />
                                                                <Form.Label bsPrefix="custom-control-label" htmlFor="email06"> Member registration</Form.Label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Button type="submit" variant="primary" className="mr-2">Submit</Button>
                                                    <Button type="reset" variant="mr-2" className="iq-bg-danger">Cancel</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth" className="fade show">
                                        
                                            
                                <Card>
                                    <Card.Header>
                                        <Card.Header.Title>
                                            <h4 className="card-title">Subscriptions</h4>
                                        </Card.Header.Title>
                                    </Card.Header>
                                    <Card.Body>
                                    { userSubscriptions.length > 0 && (
                                    <Row>
                                        <Col>
                                            <input type="hidden" name="priceId" value="" />
                                            <div className="table-view">
                                            <table className="data-tables table movie_table table-hover" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    
                                                    <th style={{width: "30%"}}>Subscription</th>
                                                    <th style={{width: "20%"}}>Billing Type</th>
                                                    <th style={{width: "20%"}}>Next Billing</th>
                                                    <th style={{width: "15%"}}>Status</th>
                                                    <th style={{width: "15%"}}>Created Date</th>
                                                    <th style={{width: "5%"}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {userSubscriptions?.map((data, index) => (
                                                <tr>
                                                
                                                <td>{data.plan.product.name}</td>
                                                <td>{data.plan.interval==='year'? ('Yearly') : data.plan.interval==='month'? ('Monthly') : data.plan.interval==='week'? ('Weekly') : data.plan.interval==='day' ? ('Daily') : data.plan.interval }</td>
                                                <td>{moment.unix(data.current_period_end).format("MM/DD/YYYY")}</td>
                                                <td><span className="badge iq-bg-success">{data.canceled_at ? 'Canceled': data.status==='incomplete' ? ('Incomplete') : data.status==='active' ? ('Active') : data.status}</span></td>
                                                <td>{moment.unix(data.created).format("MM/DD/YYYY")}</td>
                                                <td>
                                                    <div className="flex align-items-center list-user-action">
                                                        {data.canceled_at ? 'Canceled' : data.status==='incomplete' ? <Button onClick={(e)=>cancelSubscription(e,data.id,'delete')} className="btn-cnl float-right">Delete</Button>:<Button onClick={(e)=>cancelSubscription(e,data.id,'cancel')} className="btn-cnl float-right">Cancel</Button>}
                                                    </div>
                                                </td>
                                                </tr>

                                            ))}
                                                    
                                            </tbody>
                                            </table>
                                            </div>
                                            </Col>
                                        </Row>
                                        )}
                                        { userSubscriptions.length == 0 && (
                                            <Row>
                                                <Col>
                                                    <div>No Active Subscriptions Found!</div>
                                                </Col>
                                            </Row>
                                        )}
                                        
                                        </Card.Body>
                                        </Card>
                                                
                                                            
                                    </Tab.Pane>
                                </Tab.Content>
                        </Col>
                        )}
                    </Row>
                </Tab.Container>
            </Container>
            </div>
        </>
    )
}

export default UserProfile
