import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "globalsearch/";

const search = async (data) => {
  
  return axios
  .get(API_URL + "search?search_term="+ data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
      console.log(error);
  });
       
};


export default {
    search,
};