import { React } from 'react';
import { Outlet } from 'react-router-dom';

// Partials
import HeaderStyle1 from '../../components/partials/backend/headerstyle/headerstyle1';
import FooterStyle from '../../components/partials/backend/footerstyle/footerstyle'


const Layout1 = () => {
  return (
    <>
      <div className="wraper">
        <HeaderStyle1 />
        <div className="content-page" id="content-page">
           <Outlet></Outlet>
        </div>
      </div>
      <FooterStyle />

    </>
  )
}


export default Layout1;