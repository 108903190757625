//router
import LayoutsRoute from './router/layouts-route';
//import ReactGA from 'react-ga';
//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'
import  './assets/css/custom.css';

function App() {

  return (
    <div className="App">
      <LayoutsRoute />
    </div>
  );
}

export default App;
