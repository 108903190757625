import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "events/";

const recent = async (filter) => {
  return axios
    .post(API_URL + "recent", filter, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      //setContent(_content);

      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    });
};

const upcoming = async (filter) => {
  return axios
    .post(API_URL + "upcoming", filter, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }
    });
};

const filter = async (filterData) => {
  return axios
    .post(API_URL + "filter", filterData, {
      headers: { "Access-Control-Allow-Origin": "*" },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }
    });
};

const find = async (id) => {
  return axios
    .get(API_URL + "find/" + id, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
        // navigate('/500');
      }

      return _content;
    });
};

const findByEmbedcode = async (code) => {
  //console.log("code", code);
  return axios
    .get(API_URL + "find/embed/" + code, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }

      return _content;
    });
};

const findBySlug = async (slug) => {
  return axios
    .get(API_URL + "findbyslug/" + slug, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }

      return _content;
    });
};

const findBySubdomainAndSlug = async (subdomain, slug) => {
  //console.log("code", slug);
  return axios
    .get(API_URL + "findbysubdomainandslug/" + subdomain + "/" + slug, {
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }

      return _content;
    });
};

export default {
  recent,
  upcoming,
  find,
  filter,
  findByEmbedcode,
  findBySlug,
  findBySubdomainAndSlug,
};
