import axios from "axios";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "auth/";

const register = (firstname, lastname, username, pass, consent) => {
  return axios
    .post(
      API_URL + "register",
      {
        firstname: firstname,
        lastname: lastname,
        username: username,
        password: pass,
        consent: consent,
      },
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => {
      // console.log(response.data );
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const login = (email, pass) => {
  return axios
    .post(
      API_URL + "login",
      {
        username: email,
        password: pass,
      },
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("sub");
};

const changepassword = (passwordData) => {
  return axios
    .post(API_URL + "changepassword", passwordData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const verifyIP = () => {
  //console.log("IP",IP);
  return axios
    .post(API_URL + "verifyIP", {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      return error;
    });
};

const forgotpassword = (userData) => {
  return axios
    .post(API_URL + "fogotpassword", userData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const resetpassword = (userData) => {
  return axios
    .post(API_URL + "resetpassword", userData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const sendticket = (userData) => {
  //console.log(userData);
  return axios
    .post(API_URL + "sendticket", userData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

export default {
  register,
  login,
  logout,
  changepassword,
  verifyIP,
  forgotpassword,
  resetpassword,
  sendticket,
};
