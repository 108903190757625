import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "swiper/swiper-bundle.css";
import PromotionService from "../../../services/promotion.service";
import EventService from "../../../services/event.service";
import { Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import Upcoming from "../../../components/event/Upcoming";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import { useSelector } from "react-redux";
import ticketService from "../../../services/ticket.service";
import { TAG } from "../../../constants/enum";

import FormattedDate from "../../../components/utils/FormattedDate";
import TicketButtons from "../../../common/TicketButtons";

// install Swiper modules
SwiperCore.use([Navigation]);

const Promotion = () => {
  const { subdomain } = useParams();
  const [id, setId] = useState();
  const [promotion, setPromotion] = useState();
  const [upcomingEventData, setUpcomingEventData] = useState([]);
  const [recentEventData, setRecentEventData] = useState([]);
  const [key, setKey] = useState("description");
  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);

  const reShapeEvents = (evts, tkts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].isPurchased = false;
      for (let j = 0; j < tkts.length; j++) {
        if (evts[i].id === tkts[j].eventId && tkts[j].type === TAG.PPV) {
          evts[i].isPurchased = true;
          break;
        }
      }
    }

    //console.log(evts);
    return evts;
  };

  const getPromotionDetails = async (subdomain) => {
    const data = await PromotionService.findBySubdomain(subdomain);

    //console.log(data);
    setPromotion(data);
    setId(data.id);

    const filter = {
      promotionId: data.id,
    };

    let tkts = [];
    if (currentUser) {
      const purchasefilter = {
        userId: currentUser.user?.id,
      };

      tkts = await ticketService.findTicketByUser(purchasefilter);
    }

    //console.log(tkts);

    await EventService.recent(filter).then(
      (response) => {
        let evts = response.data;
        setRecentEventData(reShapeEvents(evts, tkts));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  };

  useEffect(() => {
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'Privacy-Policy', 'Privacy-Policy');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Promotion"
    );
    if (subdomain) {
      getPromotionDetails(subdomain);
    }
  }, [subdomain]);

  return (
    <>
      <div class='main-content'>
        <div class='container-fluid'>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='trending-info mt-4 pt-0 pb-4'>
                <div class='row'>
                  <div class='col-md-9 col-12  mb-auto'>
                    <div class='d-md-flex'>
                      <h3
                        class='trending-text big-title text-uppercase mt-0 fadeInLeft animated'
                        data-animation-in='fadeInLeft'
                        data-delay-in='0.6'
                      >
                        {promotion?.name}
                      </h3>
                    </div>

                    <div className='d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4'>
                      <span class='badge badge-secondary font-size-16'>
                        Combat Type:
                      </span>
                      <span className='ml-3 font-Weight-500 genres-info'>
                        {promotion?.combattypes
                          ?.map((combattype) => combattype.name)
                          .join(", ")}
                      </span>
                    </div>
                    <div className='d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4'>
                      <span class='badge badge-secondary font-size-16'>
                        Location:
                      </span>
                      <span className='ml-3 font-Weight-500 genres-info'>
                        {promotion?.state}, {promotion?.country}{" "}
                      </span>
                    </div>
                  </div>
                  <div class='trailor-video col-md-3 col-12 mt-lg-0 mt-4 mb-md-0 mb-1 text-lg-right'>
                    <img
                      width='1920'
                      height='1080'
                      src={promotion?.promotionLogo}
                      class='attachment-medium-large size-medium-large wp-post-image'
                      alt=''
                      loading='lazy'
                      sizes='(min-width: 960px) 75vw, 100vw'
                    />
                  </div>
                </div>
              </div>
              <div
                id='dectripton-1'
                class='tab-pane fade active show'
                role='tabpanel'
              >
                <div class='description-content'>
                  <p>{promotion?.description}</p>
                </div>
              </div>

              {id && <Upcoming promotionId={id}></Upcoming>}
              <div className='d-flex align-items-center justify-content-between mt-3'>
                <h4 className='main-title'>Recent Events</h4>
                {/*<Link className="iq-view-all" to="/movie-category">View All</Link>*/}
              </div>
              <ul class='row list-inline mb-0 iq-rtl-direction'>
                {recentEventData?.map((data, index) => (
                  <li class='slide-item col-lg-3 col-md-4 col-6 mb-4'>
                    <Link
                      to={{
                        pathname:
                          "/" + data.promotion?.subdomain + "/" + data.slug,
                        state: {
                          item: data,
                        },
                      }}
                    >
                      <div class='block-images position-relative  watchlist-first'>
                        <div class='img-box'>
                          <img
                            src={data?.event_promotional_photo}
                            class='cl-recent-slide-image'
                            alt=''
                            loading='lazy'
                          />
                        </div>
                        <div class='block-description'>
                          <h6 class='iq-title text-left'>
                            {/* <a href="../html/genres-detail.html"> */}
                            {data?.name}
                            {/* </a> */}
                          </h6>
                          <div
                            className='trending-list'
                            data-wp_object-in='fadeInUp'
                            data-delay-in='1.2'
                          >
                            <div className='iq-secondary title starring'>
                              <span className='text-body'>
                                {data?.combattypes
                                  ?.map((combattype) => combattype.name)
                                  .join(", ")}
                              </span>
                            </div>
                          </div>
                          <div className='iq-secondary title tag'>
                            Date & Time:{" "}
                            <FormattedDate date={data.event_date} />
                          </div>

                          
                          {data.tags && data.tags.length > 0 && (
                             <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Listing"/>
                          )}
                        </div>
                        <div class='block-social-info'>
                          <ul class='list-inline p-0 m-0 music-play-lists'>
                            {/* <li class="share">
                                                    <span><i class="ri-share-fill"></i></span>
                                                    <div class="share-box">
                                                        <div class="d-flex align-items-center">
                                                            <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-facebook-fill"></i></a>
                                                            <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                                rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-twitter-fill"></i></a>
                                                            <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                        </div>
                                                    </div>
                                                </li> */}
                            {/* <li>
                                                    <span><i class="ri-heart-fill"></i></span>
                                                    <span class="count-box">+3</span>
                                                </li>
                                                <li><span><i class="ri-add-line"></i></span></li> */}
                          </ul>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
              {/* <div class="streamit-content-details trending-info g-border iq-rtl-direction">
                <Table class="movie-sources sources-table" variant="dark">
                  <thead class="trending-pills">
                    <tr>
                      <th class="genres-table-heading">Links</th>
                      <th class="genres-table-heading">Event Name</th>
                      <th class="genres-table-heading">Combat Types</th>
                      <th class="genres-table-heading">Date</th>
                      <th class="genres-table-heading">Location</th>
                    </tr>
                  </thead>
                  <tbody class="trending-pills">
                    {recentEventData?.map((data, index) => (
                      <tr>
                        <td>
                          <a
                            href={
                              "/" + data.promotion?.subdomain + "/" + data.slug
                            }
                            class="play-source movie-play-source btn-hover iq-button"
                          >
                            <i class="ri-play-fill"></i>
                            Play Now
                          </a>
                        </td>
                        <td>{data.name}</td>
                        <td>
                          {data?.combattypes
                            ?.map((combattype) => combattype.name)
                            .join(", ")}
                        </td>
                        <td>
                          {new Date(data?.event_date).toLocaleDateString()}
                        </td>
                        <td>
                          {data?.state}, {data?.country}{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Promotion;
