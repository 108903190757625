import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import footer1 from "../../../../assets/images/footer/01.jpg";

const FooterStyle = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <footer id="contact" className="footer-one iq-bg-dark">
        <div className="footer-top">
          <Container fluid>
            <Row className="footer-standard">
              <Col lg="7">
                <div className="widget text-left">
                  <div className="menu-footer-link-1-container">
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li
                        id="menu-item-7314"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                      >
                        <Link to="/terms-of-service">Terms Of Use</Link>
                      </li>
                      <li
                        id="menu-item-7316"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316"
                      >
                        <Link to="/privacy-policy">Privacy-Policy</Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                      >
                        <Link to="/faq">FAQ & Support</Link>
                      </li>
                      {/* <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                <Link to="/extra-pages/watch-video">Watch List</Link>
                                            </li> */}
                      {/* <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                                                <Link to="/extra-pages/contact">Contact</Link>
                                            </li> */}
                    </ul>
                  </div>
                </div>
                <div className="widget text-left">
                  <div className="textwidget">
                    <p>
                      <small>
                        Copyright &#169; 2023, Combat Labs, Inc. All videos and
                        shows on this platform are trademarks of, and all
                        related images and content are the property of, Combat
                        Labs, Inc. Duplication and copy of this is strictly
                        prohibited. All rights reserved.
                      </small>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6" className="mt-4 mt-lg-0">
                <h6 className="footer-link-title">Follow Us :</h6>
                <ul className="info-share">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/combatlabstv/"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/combatlabs1"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@combatlabs"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col lg="2" md="6" className="mt-4 mt-lg-0">
                <div className="widget text-right">
                  <div className="textwidget">
                    <div className="d-flex align-items-center">
                      <img src={footer1} alt="" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default FooterStyle;
