import React from 'react';
import { Link } from 'react-router-dom';
import { TAG } from "../constants/enum";
// Constants for tag names

const TAG_PLAY = "fa-play";
const TAG_TICKET = "fa-ticket";
const TAG_SUB = "fa-bell";

const TagIcon = ({ tag, currentUser, currentSubscription }) => (
  <i
    className={`fa ${tag.name === TAG.PPV ? TAG_PLAY : tag.name === TAG.IP ? TAG_TICKET : tag.name === TAG.SUB ? TAG_SUB : tag.name === TAG.SUB && currentUser && currentSubscription && currentSubscription.isActive ? TAG_PLAY : " "
      } mr-1`}
    aria-hidden='true'
  ></i>
);

const TagLink = ({ tag, data, label, path, state, currentUser, currentSubscription }) => (
  <Link
    className='btn btn-hover btn-primary1'
    to={path}
    state={state}
  >
    <TagIcon tag={tag} data={data} currentUser={currentUser}
      currentSubscription={currentSubscription} />
    {label}
  </Link>
);

const DetailButton = ({ tags, data, currentUser, currentSubscription }) => {
  return (
    <div className='p-1'>
      {tags.map((tag, tagIndex) => (
        <span key={tagIndex} className='tag p-1'>
          {tag.name === TAG.PPV && data?.price > 0 && (
            <TagLink
              tag={tag}
              data={data}
              label={data.purchased || data.isPurchased ? "Play Now" : `Buy PPV ($` + data.price + `)`}
              path={`/${data.promotion?.subdomain}/${data.slug}/ppv`}
              state={{ item: data, type: 'Pay Per View' }}
              currentUser={currentUser}
              currentSubscription={currentSubscription}
            />
          )}
          {tag.name === TAG.IP && data?.eventtickettype?.length > 0 && (
            <TagLink
              tag={tag}
              data={data}
              label='Buy Ticket'
              path={`/${data.promotion?.subdomain}/${data.slug}/ticket`}
              state={{ item: data, type: "Physical Ticket" }}
            />
          )}
          {tag.name === TAG.SUB &&
            <TagLink
              tag={tag}
              data={data}
              label={currentUser && currentSubscription && currentSubscription?.isActive ? 'Play Now' : 'Subscribe Now'}
              path={`/${data.promotion?.subdomain}/${data.slug}/ppv`}
              state={{ item: data, type: 'Subscription' }}
            />
          }
        </span>
      ))}
    </div>
  )
};

const ListingButtons = ({ data, currentUser, currentSubscription }) => {
  return (
    <div>
      {data.tags.map((tag, tagIndex) => (
        <span key={tagIndex} className='tag'>
          <div className='hover-buttons'>
            <i
              className={`fa ${tag.name === TAG.PPV && data?.price > 0 ? 'fa-play' : tag.name === TAG.IP ? 'fa-ticket' : ''
                } mr-1`}
              aria-hidden='true'
            ></i>

            {tag.name === TAG.PPV && data?.price > 0 && (
              <Link
                to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ppv'}
                state={{ item: data, type: 'Pay Per View' }}
              >
                {data.isPurchased ? 'Play Now' : `Buy PPV ($` + data.price + `)`}
              </Link>
            )}

            {tag.name === TAG.IP && (
              <Link
                to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ticket'}
                state={{ item: data, type: 'Physical Ticket' }}
              >
                Buy Ticket
              </Link>
            )}

            {tag.name === TAG.SUB && (
              <Link
                to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ppv'}
                state={{ item: data, type: 'Subscription' }}
              >
                {
                  currentUser && currentSubscription && currentSubscription.isActive ? (
                    <>
                      <i className="fa fa-play" aria-hidden="true"></i> Play Now
                    </>
                  ) : (
                    <>
                      <i className="fa fa-bell" aria-hidden="true"></i> Subscribe Now
                    </>
                  )
                }
              </Link>
            )}
          </div>
        </span>
      ))}
    </div>
  );
}

const TicketButtons = ({ data, currentUser, currentSubscription, pagetype }) => {
  //console.log("Tags",data.tags);
  //console.log("Type",pagetype);
  return (
    <>
      {pagetype === "Detail" ? (
        <DetailButton tags={data.tags} data={data} currentUser={currentUser} currentSubscription={currentSubscription} />
      ) : (
        <ListingButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} />
      )}
    </>
  );
};

export default TicketButtons;
