import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Form,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Card from "../../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import CustomToggle from "../../../../components/dropdowns";
import GlobalSearch from "../../../GlobalSearch/globalsearch";
//img

import logo from "../../../../assets/images/logo.png";
import { logout } from "../../../../slices/auth";
import user from "../../../../assets/images/user/user.jpg";
import { removeSubscription } from "../../../../slices/sub";

import thumb1 from "../../../../assets/images/notify/thumb-1.jpg";
import thumb2 from "../../../../assets/images/notify/thumb-2.jpg";
import thumb3 from "../../../../assets/images/notify/thumb-3.jpg";

const HeaderStyle1 = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const [expanded, setExpanded] = useState(false);
  const signOut = () => {
    dispatch(removeSubscription());
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        //setLoading(false);
      });
  };

  function logoutEventHandle(e) {
    e.preventDefault();
    signOut();
  }

  return (
    <>
      <header id="main-header">
        <div className="main-header">
          <Container fluid>
            <Row>
              <Col sm="12">
                <Navbar expand="lg" expanded={expanded} className="p-0">
                  <Navbar.Toggle
                    className="c-toggler"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                  >
                    <div className="navbar-toggler-icon">
                      <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                      <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                    </div>
                  </Navbar.Toggle>
                  <Navbar.Brand className="navbar-brand" href="/">
                    <img className="img-fluid logo" src={logo} alt="" />
                  </Navbar.Brand>
                  <Dropdown className="mobile-more-menu">
                    <Dropdown.Toggle
                      to="#"
                      as={CustomToggle}
                      variant="more-toggle"
                    >
                      <i className="ri-more-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="more-menu">
                      <div className="navbar-right position-relative">
                        <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                          <GlobalSearch globalSearch={props.globalSearch} />
                          <Dropdown as="li" className="nav-item m-0">
                            <Dropdown.Toggle
                              href="#"
                              as={CustomToggle}
                              variant="search-toggle position-relative"
                            >
                              <img
                                src={user}
                                className="img-fluid avatar-40 rounded-circle"
                                alt="user"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="iq-sub-dropdown"
                              align="right"
                            >
                              {currentUser ? (
                                <Card className="shadow-none m-0">
                                  <Card.Body className="p-0 pl-3 pr-3">
                                    <Link
                                      to="/manage-profile"
                                      className="iq-sub-card"
                                    >
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-gold"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">
                                            Manage Profile
                                          </h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="" className="iq-sub-card">
                                      <div
                                        className="media align-items-center"
                                        onClick={logoutEventHandle}
                                      >
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-gold"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Logout</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </Card.Body>
                                </Card>
                              ) : (
                                <Card className="shadow-none m-0">
                                  <Card.Body className="p-0 pl-3 pr-3">
                                    <Link to="/login" className="iq-sub-card">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-gold"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Login</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/sign-up" className="iq-sub-card">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-gold"></i>
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Register</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </Card.Body>
                                </Card>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Navbar.Collapse className="">
                    <div className="menu-main-menu-container">
                      <Nav as="ul" id="top-menu" className="ml-auto">
                        <li className="menu-item">
                          <Link onClick={() => setExpanded(false)} to="/">
                            Home
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link onClick={() => setExpanded(false)} to="/events">
                            EVENTS
                          </Link>
                        </li>
                        {currentUser && (
                          <li className="menu-item">
                            <Link
                              onClick={() => setExpanded(false)}
                              to="/mylist"
                            >
                              My List
                            </Link>
                          </li>
                        )}
                        {(!currentSubscription?.isActive ||
                          !currentSubscription) && (
                          <li className="menu-item">
                            <Link
                              onClick={() => setExpanded(false)}
                              to="/subscribe"
                            >
                              SUBSCRIBE
                            </Link>
                          </li>
                        )}
                        {/* <li className="menu-item">
                                                    <Link to="/news">NEWS</Link>
                                                </li> */}
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                  <div className="navbar-right menu-right">
                    <ul className="d-flex align-items-center list-inline m-0">
                      <GlobalSearch globalSearch={props.globalSearch} />

                      <Dropdown as="li" className="nav-item nav-icon">
                        <Dropdown.Toggle
                          href="#"
                          as={CustomToggle}
                          variant="search-toggle"
                        >
                          <div
                            className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                            data-toggle="search-toggle"
                          >
                            <img
                              src={user}
                              className="img-fluid avatar-40 rounded-circle"
                              alt="user"
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="iq-sub-dropdown iq-user-dropdown"
                          align="right"
                        >
                          {currentUser ? (
                            <Card className="shadow-none m-0">
                              <Card.Body className="p-0 pl-3 pr-3">
                                <Link
                                  to="/manage-profile"
                                  className="iq-sub-card setting-dropdown"
                                >
                                  <div className="media align-items-center">
                                    <div className="right-icon">
                                      <i className="ri-file-user-line text-gold"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">Manage Profile</h6>
                                    </div>
                                  </div>
                                </Link>
                                <Link
                                  to=""
                                  className="iq-sub-card setting-dropdown"
                                >
                                  <div
                                    className="media align-items-center"
                                    onClick={logoutEventHandle}
                                  >
                                    <div className="right-icon">
                                      <i className="ri-logout-circle-line text-gold"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">Logout</h6>
                                    </div>
                                  </div>
                                </Link>
                              </Card.Body>
                            </Card>
                          ) : (
                            <Card className="shadow-none m-0">
                              <Card.Body className="p-0 pl-3 pr-3">
                                <Link
                                  to="/login"
                                  className="iq-sub-card setting-dropdown"
                                >
                                  <div className="media align-items-center">
                                    <div className="right-icon">
                                      <i className="ri-file-user-line text-gold"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">Login</h6>
                                    </div>
                                  </div>
                                </Link>
                                <Link
                                  to="/sign-up"
                                  className="iq-sub-card setting-dropdown"
                                >
                                  <div className="media align-items-center">
                                    <div className="right-icon">
                                      <i className="ri-logout-circle-line text-gold"></i>
                                    </div>
                                    <div className="media-body ml-3">
                                      <h6 className="my-0 ">Register</h6>
                                    </div>
                                  </div>
                                </Link>
                              </Card.Body>
                            </Card>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </ul>
                  </div>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderStyle1;
