import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EventService from "../../../services/event.service";
import { gsap } from "gsap";
import { Link, Outlet } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import FormattedDate from "../../../components/utils/FormattedDate";
import { TAG } from "../../../constants/enum";
import TicketButtons from "../../../common/TicketButtons";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import noupcoming from "../../../assets/video/noupcoming.mp4";
import Recent from "../../../components/event/Recent";
import InitializeGoogleAnalytics, {
  TrackGoogleAnalyticsEvent,
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import ticketService from "../../../services/ticket.service";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination, Autoplay]);

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

const Home = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [toggler1, setToggler1] = useState(false);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  const [upcomingEventData, setUpcomingEventData] = useState([]);

  const reShapeEvents = (evts, tkts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].isPurchased = false;
      for (let j = 0; j < tkts.length; j++) {
        if (evts[i].id === tkts[j].eventId && tkts[j].type === TAG.PPV) {
          evts[i].isPurchased = true;
          break;
        }
      }
    }
    return evts;
  };

  const loadHome = async () => {
    let tkts = [];

    if (currentUser) {
      const purchasefilter = {
        userId: currentUser.user?.id,
      };

      tkts = await ticketService.findTicketByUser(purchasefilter);
    }

    await EventService.upcoming().then(
      (response) => {
        setUpcomingEventData(reShapeEvents(response.data, tkts));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setUpcomingEventData(_content);
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    loadHome();
    InitializeGoogleAnalytics();
    //  TrackGoogleAnalyticsEvent('Home', 'Homepage','Homepage Loaded');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Home"
    );
    //  recent();
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div>
          {upcomingEventData.length > 0 && (
            <section id='home' className='iq-main-slider p-0 iq-rtl-direction'>
              <div id='prev5' className='swiper-button swiper-button-prev'>
                <i className='fa fa-chevron-left'></i>
              </div>
              <div id='next5' className='swiper-button swiper-button-next'>
                <i className='fa fa-chevron-right'></i>
              </div>
              <Swiper
                navigation={{
                  prevEl: "#prev5",
                  nextEl: "#next5",
                }}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 5000,
                }}
                onInit={() => {
                  animationInit();
                }}
                onSlideChangeTransitionStart={() => animationInit()}
                loop={true}
                id='home-slider'
                className='slider m-0 p-0 home-slider'
              >
                <SwiperSlide className='slide slick-bg'>
                  <Link
                    to={{
                      pathname: "/subscribe",
                    }}
                  >
                    <video
                      className='video d-block'
                      autoPlay
                      loop
                      muted
                      playsInline
                      preload='auto'
                    >
                      <source src={noupcoming} />
                    </video>
                  </Link>
                </SwiperSlide>
                {upcomingEventData.map((data, index) => (
                  <SwiperSlide
                    key={data.id}
                    className='slide slick-bg swiper-bg'
                    style={{
                      backgroundImage:
                        "url(" + data.event_promotional_photo + ")",
                    }}
                  >
                    <Link
                      to={{
                        pathname:
                          "/" + data.promotion?.subdomain + "/" + data.slug,
                        state: {
                          item: data,
                        },
                      }}
                    >
                      <Container fluid className='position-relative h-100'>
                        <div className='slider-inner h-100'>
                          <Row className='align-items-center  iq-ltr-direction h-100 '>
                            <Col xl='6' lg='7' md='12'>
                              <Link to={"/" + data.promotion?.subdomain}>
                                <div
                                  className='channel-logo'
                                  data-iq-delay='0.5'
                                >
                                  {/* <img src={data.promotion.promotionLogo} className="c-logo" alt="" /> */}
                                  <h5
                                    className='slider-text big-title title text-capitalize'
                                    data-iq-gsap='onStart'
                                    data-iq-position-x='-200'
                                  >
                                    {data.promotion?.name}
                                  </h5>
                                </div>
                              </Link>
                              <h1
                                className='slider-text big-title title text-uppercase'
                                data-iq-gsap='onStart'
                                data-iq-position-x='-200'
                              >
                                {data.name}
                              </h1>
                              <p
                                className='RightAnimate-two'
                                data-iq-gsap='onStart'
                                data-iq-position-y='80'
                                data-iq-delay='0.8'
                              >
                                {data.description}
                              </p>
                              {/* <div className="d-flex flex-wrap align-items-center">
                                             <div className="d-flex align-items-center ml-sm-3 ml-0 mr-4" data-iq-gsap="onStart" data-iq-position-x="-200" data-iq-delay="-0.5">
                                                <FormattedDate className="iq-secondary ml-2" date={data.event_date} />
                                             </div>
                                             <div className="d-flex align-items-center mt-2 mt-md-3 p-2" data-iq-gsap="onStart" data-iq-position-x="-200" data-iq-delay="-0.5">
                                                <span className="badge badge-secondary p-2"></span>
                                                <span className="ml-3"></span>
                                             </div>
                                             
                                          </div> */}
                              <div
                                className='trending-list'
                                data-wp_object-in='fadeInUp'
                                data-delay-in='1.2'
                              >
                                {data.combatTypes &&
                                  data.combatTypes.length > 0 && (
                                    <div className='iq-secondary title starring'>
                                      Combat Type:{" "}
                                      <span className='text-body'>
                                        {data?.combattypes
                                          ?.map((combattype) => combattype.name)
                                          .join(", ")}
                                      </span>
                                    </div>
                                  )}
                                {data.location && (
                                  <div className='iq-secondary title genres'>
                                    Venue Name & Location:{" "}
                                    <span className='text-body'>
                                      {data?.location}, {data?.city},{" "}
                                      {data?.state}
                                    </span>
                                  </div>
                                )}
                                {data.tags && data.tags.length > 0 && (
                                  <div className='iq-secondary title tag'>
                                    Tag:{" "}
                                    <span className='text-body'>
                                      {" "}
                                      {data?.tags
                                        ?.map((tag) => tag.name)
                                        .join(", ")}
                                    </span>
                                  </div>
                                )}
                                {data.event_date && (
                                  <div className='iq-secondary title tag'>
                                    Date & Time:{" "}
                                    <FormattedDate date={data.event_date} />
                                  </div>
                                )}
                              </div>
                              <div
                                className='d-flex align-items-center r-mb-23'
                                data-iq-gsap='onStart'
                                data-iq-position-y='80'
                                data-iq-delay='0.8'
                              >
                                {data.tags && data.tags.length > 0 && ( 
                                  <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Detail"/>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          )}

          {upcomingEventData.length <= 0 && (
            <section className='height-100-vh iq-main-slider'>
              <video
                className='video d-block'
                autoPlay
                loop
                playsInline
                muted
                preload='auto'
              >
                <source src={noupcoming} />
              </video>
            </section>
          )}

          <div className='main-content'>
            <Recent></Recent>
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default Home;
