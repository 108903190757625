import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'swiper/swiper-bundle.css';
import { useSelector } from 'react-redux';
import VideoPlayer from '../video/VideoPlayer';
import Recent from './Recent';

const FreeEvent = (props) => {
    const { user: currentUser } = useSelector(state => state.auth);
    const [data, setData] = useState(props.event);
    const videoUrl = data.video?.videoUrl;
    let type = "application/x-mpegURL";

    // Check if the video URL has .mp4 extension
    if (videoUrl && videoUrl.toLowerCase().endsWith(".mp4")) {
        type = "video/mp4";
    }

    let navigate = useNavigate();

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: false,
        fluid: true,
        liveui: true,
        poster: data.event_promotional_photo,
        sources: [{
            src: videoUrl,
            type: type
        }],
    };

    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
        }
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12" onContextMenu={e => e.preventDefault()}>
                            <VideoPlayer  {...videoJsOptions} >
                            </VideoPlayer>
                        </div>
                    </div>
                </div>

                {data && (<Recent event={data}></Recent>)}
            </div>
        </>
    )
}

export default FreeEvent;



