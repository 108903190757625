import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams} from "react-router-dom";
import { TAG } from "../../../constants/enum";
import FreeEvent from "../../../components/event/FreeEvent";
import SubscriptionEvent from "../../../components/event/SubscriptionEvent";
import PPVEvent from "../../../components/event/PPVEvent";
import EventService from "../../../services/event.service";
import Ticket from "../../../components/event/tickets";
import { useSelector } from "react-redux";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";

const Event = (props) => {
  //console.log("Props:", props);
  let navigate = useNavigate();
  const [payType, setPayType] = useState();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { subdomain, slug, fighterId } = useParams();
  const [data, setData] = useState({});
  if (!currentUser) {
    navigate("/login");
  }

  const eventDetails = async () => {
      let res = await EventService.findBySubdomainAndSlug(subdomain, slug);
      let payType = "Free";
      
      const tags = res.data.tags;
      const physicalTicketIndex = tags.findIndex(tag => tag.name === TAG.IP);
      const ppvTicketIndex = tags.findIndex(tag => tag.name === TAG.PPV);
      const subTicketIndex = tags.findIndex(tag => tag.name === TAG.SUB);
      
      if (physicalTicketIndex !== -1 && window.location.pathname.includes("/ticket")) {
          payType = tags[physicalTicketIndex].name;
      } else if (ppvTicketIndex !== -1) {
          payType = tags[ppvTicketIndex].name;
      } else if (subTicketIndex !== -1) {
          payType = tags[subTicketIndex].name;
      }
      setData(res.data);
      setPayType(payType);
  };

  useEffect(() => {
    eventDetails();
    //setPayType(type);
    
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Event"
    );
  }, [subdomain, slug]);



  return (
    <>
      {data && payType === TAG.F && Object.keys(data).length && (
        <FreeEvent event={data}></FreeEvent>
      )}
      {data && payType === TAG.SUB && Object.keys(data).length && (
        <SubscriptionEvent event={data}></SubscriptionEvent>
      )}
      {data && payType === TAG.PPV && Object.keys(data).length && (
        <PPVEvent event={data} payType={payType} fighterId={fighterId}></PPVEvent>
      )}
      {data && payType === TAG.IP && Object.keys(data).length && (
        <Ticket event={data} fighterId={fighterId}></Ticket>
      )}
    </>
  );
};

export default Event;