import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import "swiper/swiper-bundle.css";
import PaymentService from "../../services/payment.service";
import TicketService from "../../services/ticket.service";
import CheckoutForm from "../payment/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VideoPlayer from "../video/VideoPlayer";
import CommingSoon from "../utils/CommingSoon";
import boutService from "../../services/bout.service";
import Recent from "./Recent";
import authService from "../../services/auth.service";
const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
const PTEvent = (props) => {
  //console.log("IN PTEvent", props.data);

  let navigate = useNavigate();
  //const selectedData = props.event;
  let purchasedTicket = null;
  const [ticket, setTicket] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [contenders, setContenders] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [selectedData, setSelectedData] = useState(props.event);
  const [isEmbed, setIsEmbed] = useState(props.embed ? true : false);
  const [isValidStateCode, setIsValidStateCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.ticket);
  const [payType, setPayType] = useState(props.payType);
  const [stripChargeData, setStripChargeData] = useState();
  const [paymentId, setPaymentId] = useState();
  const appearance = {
    theme: "night",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const isCommingSoon = (date) => {
    const today = new Date();
    if (today > date) {
      return true;
    }
    return false;
  };

  const findTicket = async () => {
    const ticketData = {
      userId: currentUser.user.id,
      eventId: selectedData.id,
      payType: payType,
    };
    //console.log("pass Data", ticketData);
    //console.log("sdasd", payType);

    purchasedTicket =
      payType !== "PT Event"
        ? await TicketService.findTicketByUserEvent(ticketData)
        : "";
    //console.log("Purchase Ticket", purchasedTicket);
    if (!purchasedTicket) {
      return null;
    }

    return purchasedTicket;
  };

  const generatePTTicket = async () => {
    let confirmTkt = await findTicket();
    if (confirmTkt) {
      setTicket(confirmTkt);
      setLoading(false);
      return;
    }

    if (selectedData.isInternational) {
      authService.verifyIP().then((response) => {
        //console.log("IP",response);
        if (
          response.country.isoCode == "US" ||
          response.country.isoCode == "CA"
        ) {
          setIsValidStateCode(false);
          setLoading(false);
          return;
        }
      });
    }
    if (!confirmTkt) {
      
      const finalAmount = parseFloat((
        data.reduce(
          (acc, item) =>
            acc +
            parseFloat(
              item.purchase_qty * ((
                parseFloat(item.price) + // Ensure item.price is treated as a number
                (parseFloat(item.price) * 0.04) + // 4% fee
                2.5 // Additional $2.5
              ))
            ),
          0
        )
      ).toFixed(2));

      if (
        finalAmount == null ||
        finalAmount == undefined ||
        finalAmount == "" ||
        finalAmount == 0
      ) {
        return;
      }

      const stripeData = {
        customerId: currentUser.user.stripeCustomerId,
        amount: (finalAmount * 100).toFixed(0),
        items: 1,
        description: "Event Name:: " + selectedData.name,
        metadata: {
          eventId: selectedData.id,
          eventName: selectedData.name,
          eventDate: selectedData.eventDate,
          eventTime: selectedData.eventTime,
          eventLocation: selectedData.eventLocation,
          type: "In-Person",
        },
      };
      setStripChargeData(stripeData);
      // Create the Subscription
      const res = await PaymentService.charge(stripeData);
      const { paymentId } = res;

      setClientSecret(res.clientSecret);
      setPaymentId(paymentId);
    }

    setLoading(false);
  };

  const listContenders = async () => {
    let listFighters = [];
    await boutService
      .listContendersByEvent({ eventId: selectedData.id })
      .then((response) => {
        if (response) {
          response.map((item) => {
            let contender1 = item.fighter1?.user;
            let contender2 = item.fighter2?.user;

            if (contender1) {
              listFighters.push({
                value: contender1.id,
                label: contender1.fullName,
              });
            }
            if (contender2) {
              listFighters.push({
                value: contender2.id,
                label: contender2.fullName,
              });
            }
          });
          setContenders(listFighters);
        }
      });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
    setLoading(true);
    generatePTTicket();
    listContenders();
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div className='main-content'>
          {!ticket && isValidStateCode && (
            <Container>
              <Row className='justify-content-center align-items-center height-self-center'>
                <section>
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm
                        event={selectedData}
                        data={data}
                        payType={payType}
                        contenders={contenders}
                        embed={isEmbed}
                        paymentId={paymentId}
                        stripChargeData={stripChargeData}
                        fighterId={props.fighterId ? props.fighterId.toString() : null}
                      />
                    </Elements>
                  )}
                </section>
              </Row>
            </Container>
          )}
        </div>
      )}
    </>
  );
};

export default PTEvent;
