import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "userevents/";

const add = async (data) => {
    return axios
    .post(API_URL + "add", data
     , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
        return response.data;
      }).catch(function (error) {
        const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
        
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }

          if (error.response && error.response.status === 500) {
            EventBus.dispatch("error500");
          }
      });  
         
};

const list = async (data) => {
  return axios
  .post(API_URL + "list", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    }).catch(function (error) {
      const _content =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
      
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
    });  
       
};


export default {
    add,
    list,
};