import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "tickets/";

const create = async (data) => {
  return axios
    .post(API_URL + "create", data, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const findTicketByUserEvent = async (data) => {
  return axios
    .post(API_URL + "find/purchase/user/event", data, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const findTicketByUser = async (data) => {
  return axios
    .post(API_URL + "find/purchase/user/all", data, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const redeemTicket = async (data) => {
  return axios
    .post(API_URL + "redeemTicket", data, {
      headers: authHeader(),
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

export default {
  create,
  findTicketByUserEvent,
  findTicketByUser,
  redeemTicket,
};
