import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
//import "videojs-contrib-quality-levels";
//import "videojs-quality-selector/dist/videojs-quality-selector.css";
//import "videojs-hls-quality-selector";

export default class VideoPlayer extends React.Component {
  // Instantiate a Video.js player when the component mounts
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, () => {
      videojs.log("onPlayerReady", this);
    });

    //let qualityLevels = this.player.qualityLevels();

    //this.player.hlsQualitySelector({
    ///  displayCurrentQuality: true,
    //});
  }

  // Dispose the player when the component will unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
  // won't create additional wrapper in the DOM.
  //
  // See: https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        <video
          ref={(node) => (this.videoNode = node)}
          className="vjs-matrix video-js vjs-default-skin vjs-big-play-centered"
        ></video>
      </div>
    );
  }
}
