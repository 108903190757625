import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSubscription } from "../../../slices/sub";
import { STATUS, TAG } from "../../../constants/enum";
import TicketService from "../../../services/ticket.service";
import GetStripe from "../../../components/payment/GetStripe";
import EventService from "../../../services/event.service";
import physicalticketService from "../../../services/physicalticket.service";
import authService from "../../../services/auth.service";
import QRCode from 'qrcode.react';
import AWS from 'aws-sdk';

const { REACT_APP_DOMAIN,REACT_APP_AWS_ACCESS_KEY_ID,REACT_APP_AWS_PRIVATE_KEY_ID } = process.env;
const PaymentStatus = () => {
  const dispatch = useDispatch();
  const [stripe, setStripe] = useState();
  let navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [QRdata, setQRdata] = useState(null);
  const loadStripe = async () => {
    setStripe(await GetStripe());
  };
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_PRIVATE_KEY_ID,
  });
  const qrRef = useRef(null);
  const sendTicketEmail = (ticketdata) => {
    authService.sendticket({
      username: currentUser.user.username,
      //username: "kunal@combatlabs.io",
      domain: REACT_APP_DOMAIN,
      ticketEmailData:ticketdata
    })
    .then((response) => {
      //console.log(response);
    })
    .catch((error) => {
      console.log(error);
    }); 
  }

  const uploadQRCodeS3 = (ticketData) => {
    if (!qrRef.current) {
      console.log("QR Code is null");
      return;
    }
    const canvas = qrRef.current.querySelector('canvas');
    if (!canvas) {
      console.log("No canvas found");
      return;
    }    
    const onCanvasMutation = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'height') {
          observer.disconnect();
          const s3 = new AWS.S3({ region: 'us-east-1' });
          canvas.toBlob(blob => {
              const s3BucketName = 'combatlabs-ticket-qr';
              const s3Key = `QRCode-${Date.now()}.png`;
              const params = {
                  Bucket: s3BucketName,
                  Key: s3Key,
                  Body: blob,
                  ContentType: 'image/png',
                  ACL: 'public-read',
              };
            s3.upload(params, (err, data) => {
                if (err) {
                    console.error("Error", err);
                } else {
                    ticketData.bookingqr = data.Location;
                    sendTicketEmail(ticketData);
                }
            });
          }, 'image/png');
        }
      }
    };
    const observer = new MutationObserver(onCanvasMutation);
    observer.observe(canvas, { attributes: true });
  };


  useEffect(() => {
    // Initialize Stripe.js using your publishable key

    loadStripe();

    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    const eventId = new URLSearchParams(window.location.search).get("eventId");

    const subId = new URLSearchParams(window.location.search).get("subId");

    const type = new URLSearchParams(window.location.search).get("type");

    const payType = new URLSearchParams(window.location.search).get("payType");

    const amount = new URLSearchParams(window.location.search).get("amount");

    const fighterId = new URLSearchParams(window.location.search).get(
      "fighterId"
    );

    const embed = new URLSearchParams(window.location.search).get("embed");

    // const consent = new URLSearchParams(window.location.search).get(
    //   'consent'
    // );

    //create a ticket for the event if the payment is successful
    const createTicket = async (
      data,
      eventId,
      fighterId,
      payType,
      amount,
      isEmbed
    ) => {
      if (data) {
        const ticket = {
          userId: currentUser.user.id,
          paymentId: data.id,
          type: payType == "PT Event" ? TAG.IP : TAG.PPV,
          status: STATUS.PAID,
          price: payType == "PT Event" ? amount : data.amount,
          eventId: eventId,
          contenderId: fighterId,
          consent: true,
        };
        const ticketConfirmData = await TicketService.create(ticket);

        if (payType === "PT Event" && ticketConfirmData) {
          const ticketnumber = ticketConfirmData.id;
          if (ticketnumber !== null) {
            const physicalTicketData = {
              eventId: ticket.eventId,
              ticketId: ticketnumber,
              userId: currentUser.user.id,
            };
            await physicalticketService.update(physicalTicketData);
          }  
          
          const ticketIdData = { ticketId: ticketConfirmData.id };
          let ticketDetails = await physicalticketService.purchasedticketlist(ticketIdData);
          let eventDetails = await EventService.find(eventId);
          const bookingQRUrl = `${process.env.REACT_APP_DOMAIN}tickets/${ticketConfirmData.id}`;
          setQRdata(bookingQRUrl);
          
          const totals = ticketDetails.reduce((acc, item) => {
            acc.totalQty += Number(item.purchase_qty);
            acc.totalPrice += Number(item.priceToCustomer) * Number(item.purchase_qty);
            return acc;
          }, { totalQty: 0, totalPrice: 0 });

          const formatDate = (newDate) => {
            const nowDate = new Date(newDate);
            const today = new Date();
          
            let formatter;
          
            if (today > nowDate) {
              formatter = new Intl.DateTimeFormat('en-us', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
            } else {
              formatter = new Intl.DateTimeFormat('en-us', {
                dateStyle: 'full',
                timeStyle: 'short',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
            }
          
            return formatter.format(nowDate);
          };

          const formatTime = (timeString) => {
            const [hours, minutes] = timeString.split(':');
            const hoursNum = parseInt(hours, 10);
            const suffix = hoursNum >= 12 ? 'PM' : 'AM';
            const adjustedHours = hoursNum % 12 || 12;
            return `${adjustedHours}:${minutes} ${suffix}`;
          };

          const ticketEmailDetail = {
            eventposture: eventDetails.data.event_promotional_photo,
            eventname: eventDetails.data.name,
            eventtime: formatTime(eventDetails.data.event_start_time),
            eventdate: formatDate(eventDetails.data.event_date),
            venue: eventDetails.data.venue?.name + ", " + eventDetails.data.venue?.address.street + ", " + eventDetails.data.venue?.address.city + ", " + eventDetails.data.venue?.address.state + ", " + eventDetails.data.venue?.address.country,
            ticketcount : totals.totalQty,
            amount: `$${totals.totalPrice.toFixed(2)}`,
          };
          
          if(ticketDetails) {
            uploadQRCodeS3(ticketEmailDetail);
          } 
        }
        if (isEmbed) navigate("/embed/event/" + eventId);
        else {
          const ticketConfirm = ticketConfirmData ? true : false;

          await EventService.find(eventId).then((response) => {
            const event = response.data;
            if (response.data) {
              let path = `/${event.promotion?.subdomain}/${event.slug}/ppv`;
              if (payType === "PT Event") {
                path = `/mylist`;
              }
              navigate(path, {
                state: {
                  item: event,
                  type: payType === "PT Event" ? "Physical Ticket" : "Pay Per View",
                  ticketConfirm: ticketConfirm,
                },
              });
            }
          });
        }
        //else navigate("/event/" + eventId);

        // navigate(-1);
      } else {
        navigate("/");
      }
    };

    const generateSubscription = async (subId, type, eventId) => {
      let current_period_start = new Date().getTime();
      let current_period_end;

      //if monthly subscription then add 1 month to current_period_start
      if (type === "monthly") {
        current_period_end = new Date().getTime() + 2592000000;
      }

      if (type === "yearly") {
        current_period_end = new Date().getTime() + 31536000000;
      }

      const input = {
        userId: currentUser.user.id,
        subscriptionId: subId,
        isActive: true,
        current_period_start: current_period_start,
        current_period_end: current_period_end,
        consent: true,
      };

      dispatch(createSubscription({ input }))
        .unwrap()
        .then(() => {
          //setLoading(false);
          //navigate(-1);
        })
        .catch(() => {
          //setLoading(false);
        });

      if (eventId) {
        await EventService.find(eventId).then((response) => {
          if (response.data) {
            const event = response.data;
            if (event) {
              navigate("/" + event.promotion?.subdomain + "/" + event.slug);
            }
          }
        });
      } else {
        navigate("/events");
      }
      //navigate(-1);
      // if (eventId) navigate("/event/" + eventId);
      // else navigate("/events");
    };

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          if (subId && eventId) {
            generateSubscription(subId, type, eventId);
          }

          if (subId && !eventId) {
            generateSubscription(subId, type);
          }

          if (eventId && !subId) {
            createTicket(
              paymentIntent,
              eventId,
              fighterId !== "null" ? fighterId : null,
              payType,
              amount,
              embed ? true : false
            );
          }

          setMessage("Success! Payment received.");
          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again

          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <>
      <div className='main-content'>
        <span>{message}</span>
        <div className="col-sm-4 qrcode d-none" ref={qrRef}>
          <QRCode value={QRdata} size={300} level={'H'} />
        </div>
      </div>
    </>
  );
};

export default PaymentStatus;
