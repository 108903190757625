import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
//import { Navigate, useNavigate } from "react-router-dom";

import { login } from "../../../../slices/auth"
import { clearMessage } from "../../../../slices/message";
import { findSubscription } from '../../../../slices/sub';
import Alert from '../../../../components/AlertNotification/AlertNotification';
import InitializeGoogleAnalytics, { TrackPageView } from '../../../../components/utils/googleanalytics';

const Login = (props) => {
    let navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassoward] = useState('');
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);


    if (isLoggedIn) {
        navigate(0);
    }

    const findActiveSubscription = (data) => {

        if (data) {
            let user = data.user?.user;
            //console.log(user);
            dispatch(findSubscription(user))
                .unwrap()
                .then(() => {
                    navigate(0);
                })
                .catch(() => {
                    //setLoading(false);
                });
        } else {
            navigate(0);
        }
    }

    const signin = () => {
        dispatch(login({ username, password }))
            .unwrap()
            .then((data) => {
                findActiveSubscription(data);
            })
            .catch(() => {
                //setLoading(false);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        signin();
    };

    useEffect(() => {
        dispatch(clearMessage());
        InitializeGoogleAnalytics();
        // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
        TrackPageView('pageview', window.location.pathname + window.location.search, 'Combat Labs - Login');
    }, [dispatch]);


    return (
        <>

            <section className="sign-in-page">
                <Container>

                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="5" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <h3 className="mb-3 text-center">Sign in</h3>
                                        <Form className="mt-4" onSubmit={handleSubmit}>
                                            <Form.Group>
                                                <Form.Control type="email" onChange={(e) => setUsername(e.target.value)} value={username} className="form-control mb-0" id="exampleInputEmail1" placeholder="Enter email" autoComplete="off" required />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Control type="password" onChange={(e) => setPassoward(e.target.value)} value={password} className="form-control mb-0" id="exampleInputPassword2" placeholder="Password" required />
                                            </Form.Group>
                                            <Alert />
                                            <div className="sign-info">
                                                <Button type="submit" className="btn btn-hover btn-primary1">Sign in</Button>
                                                <div className="custom-control custom-checkbox d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex justify-content-center links text-white">
                                        <Link to="/embed/forgot-password" className="text-white ml-2 text-gold">Forgot your password?</Link>
                                    </div>
                                    <div className="d-flex justify-content-center links text-white">
                                        Don't have an account?
                                        <Link to="/embed/sign-up" className="text-white ml-2 text-gold">Sign Up</Link>
                                    </div>
                                    {/*<div className="d-flex justify-content-center links">
                                        <Link to="/extra-pages/recover-pswd" className="f-link">
                                            Forgot your password?
                                        </Link>
                                    </div>*/}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Login