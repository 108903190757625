import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "promotions/";

const find = async (id) => {
  return axios
    .get(API_URL + "find/" + id)
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log(error.toJSON());
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

const findBySubdomain = async (subdomain) => {
  return axios
    .get(API_URL + "find/subdomain/" + subdomain)
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log(error.toJSON());
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
    });
};

export default {
  find,
  findBySubdomain,
};
