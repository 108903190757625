import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Table,
  Button,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import aboutus1 from "../../../assets/images/subscribe/subscription.png";
import PaymentService from "../../../services/payment.service";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";

const right = <i className="fa fa-check-circle-o"></i>;
const wrong = <i className="fa fa-times-circle"></i>;
const {
  REACT_APP_MONTHLY_PLAN_ID,
  REACT_APP_YEARLY_PLAN_ID,
  REACT_APP_STRIPE_SECERATE_KEY,
} = process.env;
const yearlyPlan = REACT_APP_YEARLY_PLAN_ID;
const monthlyPlan = REACT_APP_MONTHLY_PLAN_ID;

const Subscribe = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [event, setEvent] = useState(location.state?.event);
  const [monthly, setMonthly] = useState({
    type: "monthly",
    priceId: monthlyPlan,
    qty: 1,
  });

  const [yearly, setYearly] = useState({
    type: "yearly",
    priceId: yearlyPlan,
    qty: 1,
  });

  const [pricing, setPricing] = useState(yearly);

  const pricingLists = [
    {
      title: "Ad Free Entertainment*",
      icon1: right,
      icon2: right,
    },
    {
      title: "Unlimited Access to On-Demand Library",
      notice: "Does Not include Live PPV Events",
      icon1: right,
      icon2: right,
    },
    {
      title: "New Premium Content Every Month",
      icon1: right,
      icon2: right,
    },
    {
      title: "Video Quality HD(720+)",
      icon1: right,
      icon2: right,
    },
  ];

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      customerId: currentUser.user.stripeCustomerId,
      priceId: pricing.priceId,
    };

    // Create the Subscription
    const res = await PaymentService.createStripeSubscription(data);

    const { clientSecret, subscriptionId } = res;

    setLoading(false);
    navigate("/checkout?payment_intent_client_secret=" + clientSecret, {
      state: {
        // location state
        payment_intent_client_secret: clientSecret,
        event: event,
        subId: subscriptionId,
        data: pricing,
      },
    });
  };

  if (!currentUser) {
    navigate("/login");
  }

  //load price from stripe by prices id
  const loadPricePoint = async () => {
    setLoading(true);

    const stripe = require("stripe")(REACT_APP_STRIPE_SECERATE_KEY);

    const monthlyprice = await stripe.prices.retrieve(monthly.priceId);

    const yearlyprice = await stripe.prices.retrieve(yearly.priceId);

    setMonthly(() => {
      return {
        ...monthly,
        priceRate: monthlyprice.unit_amount_decimal / 100,
      };
    });

    setYearly(() => {
      return {
        ...yearly,
        priceRate: yearlyprice.unit_amount_decimal / 100,
      };
    });

    setLoading(false);
  };

  useEffect(() => {
    loadPricePoint();
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'Privacy-Policy', 'Privacy-Policy');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Subscribe"
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <div>
          <div
            className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
            style={{ backgroundImage: `url(${aboutus1})` }}
          >
            <Container fluid>
              <Row className="align-items-center">
                <Col sm="12">
                  <nav
                    aria-label="breadcrumb"
                    className="text-center iq-breadcrumb-two"
                  >
                    <h2 className="title">Subscription Plan</h2>
                    <Breadcrumb className="main-bg">
                      <Breadcrumb.Item>Subscribe</Breadcrumb.Item>
                      <Breadcrumb.Item active>
                        Subscription Plan
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </nav>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="site-content-contain">
            <div id="content" className="site-content">
              <div id="primary" className="content-area">
                <main id="main" className="site-main">
                  <Container>
                    <Row>
                      <Col lg="12" sm="12">
                        <input
                          type="hidden"
                          name="priceId"
                          value={pricing.priceId}
                        />
                        <div className="iq-pricing-card-two">
                          <div className="table-responsive iq-pricing pt-2">
                            <Table
                              id="my-table"
                              className="table"
                              data-active="yearly"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center iq-price-head"></th>

                                  <th className="text-center iq-price-head premium">
                                    <div
                                      className={`iq-price-box  ${
                                        pricing.type === "yearly"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => setPricing(yearly)}
                                    >
                                      <span className="">Yearly</span>
                                    </div>
                                  </th>
                                  <th className="text-center iq-price-head basic">
                                    <div
                                      className={`iq-price-box ${
                                        pricing.type === "monthly"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => setPricing(monthly)}
                                    >
                                      <span className="">Monthly</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {pricingLists.map((item) => (
                                  <tr key={item.title}>
                                    <th className="text-left" scope="row">
                                      {" "}
                                      {item.title}
                                      {item.notice ? (
                                        <span className="text-gold">
                                          {" ("}
                                          {item.notice}
                                          {" )"}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </th>
                                    {/* <td className={`text-center iq-child-cell ${pricing === 'Basic' ? 'active' : ''}`}>
                                                            {item.icon}
                                                    </td> */}
                                    <td
                                      className={`text-center iq-child-cell ${
                                        pricing.type === "yearly"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {item.icon1}
                                    </td>
                                    <td
                                      className={`text-center iq-child-cell ${
                                        pricing.type === "monthly"
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      {item.icon2}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <div
                            className="align-items-center r-mb-23"
                            data-animation-in="fadeInUp"
                            data-delay-in="1.3"
                          >
                            <p>
                              *Some regional events may stream local ads/banners
                              etc. in the show, you will always have the ability
                              to fast forward/skip over these.
                            </p>
                          </div>
                          <div className="iq-price-bottom">
                            <Row className="iq-select-price">
                              {/*<Col lg="4" md="6" sm="12" className="free">
                                                    <div  className={`iq-price-rate-wrap ${pricing2 === 'free' ? 'active' : '' }`} onClick={() =>setPricing2('free')}>
                                                        <i className="fa fa-check-square"></i>
                                                        <div className="iq-price-label">
                                                            <span className="type1">Free</span>
                                                            <h3 className="iq-price-rate">$0<small> / Month</small></h3>
                                                        </div>
                                                    </div>
                                                    </Col>*/}
                              <Col lg="4" md="6" sm="12">
                                <div
                                  className={`iq-price-rate-wrap  ${
                                    pricing.type === "monthly" ? "" : "active"
                                  }`}
                                  onClick={() => setPricing(yearly)}
                                >
                                  <i className="fa fa-check-square"></i>
                                  <div className="iq-price-label">
                                    <span className="type1">Yearly</span>

                                    <h3 className="iq-price-rate">
                                      ${yearly.priceRate}
                                      <small> / 1 Year</small>
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                              <Col lg="4" md="6" sm="12">
                                <div
                                  className={`iq-price-rate-wrap ${
                                    pricing.type === "monthly" ? "active" : ""
                                  }`}
                                  onClick={() => setPricing(monthly)}
                                >
                                  <i className="fa fa-check-square"></i>
                                  <div className="iq-price-label">
                                    <span className="type1">Monthly</span>
                                    <h3 className="iq-price-rate">
                                      ${monthly.priceRate}
                                      <small> / Month</small>
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div
                              className="align-items-center r-mb-23"
                              data-animation-in="fadeInUp"
                              data-delay-in="1.3"
                            >
                              <div>
                                <Button
                                  className="btn btn-hover iq-button btn-primary1"
                                  onClick={handleSubscribe}
                                >
                                  Subscribe
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </main>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};
export default Subscribe;
