import React, { useEffect } from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'

//img
import aboutus1 from '../../../assets/images/terms-pp/pp_tc.png'
import InitializeGoogleAnalytics, { TrackGoogleAnalyticsEvent, TrackPageView } from '../../../components/utils/googleanalytics';


const PrivacyPolicy = () => {
    useEffect(() => {
        InitializeGoogleAnalytics();
       // TrackGoogleAnalyticsEvent('Footer', 'Privacy-Policy', 'Privacy-Policy');    
        TrackPageView('pageview',window.location.pathname + window.location.search,'Combat Labs - Privacy Policy');
    }, []);
    return (
        <>
           <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{backgroundImage: `url(${aboutus1})`}}>  
                <Container fluid> 
                    <Row className="align-items-center">
                        <Col sm="12">
                            <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                <h2 className="title">Privacy Policy</h2>
                                <Breadcrumb className="main-bg">
                                     <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
                                     <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
                              </Breadcrumb>
                            </nav>
                        </Col>
                    </Row> 
                </Container>
            </div>
            <main id="main" className="site-main">
                <Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="iq-privacy-policy">
                                <div className="mb-3">
                                    {/* <h4 className="mb-3">1. What Personal Information About Users Does streamit Collect?</h4> */}
                                    <p className="my-3">When you use the Combat Labs mobile or TV application, the Combat Labs website(s) and the content feeds and services provided to you on or through the Combat Labs applications (“Service”), we may ask you to provide us with certain personal information that can be used to contact or identify you for use of the Service. Personal information may include your name, address, phone number, and email address. We will use your personal information mainly to provide the Service to you and process your support requests.
When you use the Service, our servers may automatically record information that your device sends. This may include information such as your computer’s or mobile device’s IP address, browser type, the webpage you were visiting or content item you were playing, the time spent, sections of our mobile application that you access, information you search for on the Service, access times and dates, and other statistics. We will use this information to analyze and understand how the Service is used, to provide you with a better experience, to improve the Service, for advertising and other marketing purposes.
When you use third party social networking services via the Service we may collect the user credentials for your account which may include your name, email address, gender, user ID, list of friends, and any other information that you have chosen to make public through such services. We may also collect other non-identifying information that you provide as part of registration, use and personalization of the Service, e.g. location and individual preferences.
We may use your personal information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. If you decide at any time that you no longer wish to receive such communications from us, please follow the provided unsubscribe instructions. We may also use this information to offer you personalized content and advertising.
We may invite you to participate in surveys, contests or sweepstakes. If you decide to participate, we will request certain personal information from you. Participation in such activities is completely voluntary and you will have a choice whether or not to disclose this information. We will use this information to process your participation in the respective activity.
We may implement or allow third-party companies to implement automatic usage tracking processes. The information collected through these automatic tracking processes will be anonymous and will be used in order to improve the Service quality (for example improvement of search results and the advertising selection) and to compile usage statistics.
Our Service may contain advertisements and links to other websites, mobile applications and services. If you visit a third party service by clicking on any type of advertisement or link, you will be redirected to this third party’s website or online service. We do not have any control over these third party websites or services. The fact that we link to a website, display content or any type of advertisement is not an endorsement or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies.
We take all appropriate measures to limit the risk that your information may be lost, damaged or misused. With exception for your non-personally identifiable information, your information is not provided to third parties, unless required to do so by the law. We may disclose any information to government or law enforcement officials if it is required by applicable law as necessary to respond to claims and legal process, or to protect the property and rights of Combat Labs.
You are entitled to access, correct, or delete your information by requesting to do so via the profile sections of the Service or by sending an email to sales@combatlabs.io
If you are a parent or guardian and you become aware that your child uses the Service or has provided us with personal information without your consent, please contact us at sales@combatlabs.io</p><br/>
                                </div>
                                {/* <div className="mb-3">
                                    <h4 className="mb-3">2. Cookies and Web Beacons</h4>
                                    <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed.</p>
                                    <p className="mb-0">Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-3">
                                    <h4 className="mb-3">3. Third Party Payment Gateway – Financial Information</h4>
                                    <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-3">
                                    <h4 className="mb-3">4. Disclosure Children’s Privacy</h4>
                                    <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p><br/>
                                </div>
                                <div className="mb-0">
                                    <h4 className="mb-3">5. Data transfer, storage &amp; processing globally</h4>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nisl dignissim, placerat diam ac, egestas ante. Morbi varius quis orci feugiat hendrerit. Morbi ullamcorper consequat justo, in posuere nisi efficitur sed. Vestibulum semper dolor id arcu finibus volutpat. Integer condimentum ex tellus, ac finibus metus sodales in. Proin blandit congue ipsum ac dapibus. Integer blandit eros elit, vel luctus tellus finibus in. Aliquam non urna ut leo vestibulum mattis ac nec dolor. Nulla libero mauris, dapibus non aliquet viverra, elementum eget lorem</p>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    )
}

export default PrivacyPolicy;