import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { useSelector } from "react-redux";
import "swiper/swiper-bundle.css";
import Select from "react-select";

import EventService from "../../../services/event.service";
import UserEventService from "../../../services/userevent.service";
import Flatpickr from "react-flatpickr";
import CombatTypeService from "../../../services/combattype.service";
import ContenderService from "../../../services/contender.service";
import Recent from "../../../components/event/Recent";
import Upcoming from "../../../components/event/Upcoming";
import TagService from "../../../services/tags.service";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import { TAG } from "../../../constants/enum";
import FomattedDate from "../../../components/utils/FormattedDate";
import TicketButtons from "../../../common/TicketButtons";

// install Swiper modules
SwiperCore.use([Navigation]);

const Events = () => {
  let initFilter = {
    country: "US",
    state: "",
    combatType: "",
    contender: "",
    tags: "",
  };
  const todaydate = new Date();
  todaydate.setHours(0, 0, 0, 0);
  const selectThemeColors = (theme) => ({
    ...theme,
    selectThemeColors: {},
    colors: {
      ...theme.colors,
      primary25: "var(--iq-gold)", // for option hover bg-color
      primary: "var(--iq-primary)", // for selected option bg-color
      primary75: "yellow",
      primary50: "#eab538", //Option on-click bg
      neutral0: "#333", //input background-color
      neutral5: "yellow",
      neutral10: "var(--iq-gold)", // for tags bg-color
      neutral20: "#404043", // for input border-color
      neutral30: "#363232", // for input hover border-color
      //neutral40:'var(--iq-gold)',
      //neutral50:'var(--iq-gold)',
      //neutral50:'var(--iq-gold)',
    },
  });

  const filteredDataRef = useRef(null)

  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: "white", // Change this to the desired text color
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#333" : "white", // Change this to the desired focused color
    }),
  };

  //let noResults = true;

  const [myList, setMyList] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const { date } = useState({ date: new Date() });
  const [applyFilter, setApplyFilter] = useState(initFilter);
  const [filterData, setFilterData] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [combatTypes, setCombatTypes] = useState([]);
  const [contenders, setContenders] = useState([]);
  const [tags, setTags] = useState([]);
  const [eventDates, setEventDates] = useState();
  const [loading, setLoading] = useState(false);

  const states = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District Of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];

  const reShapeEvents = (evts, myEvts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].show = true;
      if (myEvts) {
        for (let j = 0; j < myEvts.length; j++) {
          if (evts[i].id == myEvts[j].id) {
            evts[i].show = false;
            break;
          }
        }
      }
    }
    return evts;
  };

  const filter = async (arrFilterData) => {
    const user = {
      userId: currentUser?.user?.id,
    };

    if (currentUser) {
      myEvents = await UserEventService.list(user);
      setMyList(myEvents?.events);
    }

    await EventService.filter(arrFilterData).then(
      (response) => {
        let resFilter = response.data;
        //console.log("Events==>",evts);
        if (resFilter.length > 0) {
          setNoResults(false);
        } else {
          setNoResults(true);
        }

        setFilterData(reShapeEvents(resFilter, myEvents?.events));
        filteredDataRef.current.scrollIntoView({ behavior: "instant", block: "start" }); 
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  };

  // set value for default selection
  const [selectedOptions, setSelectedOptions] = useState({
    combat: null,
    contenders: null,
    states: null,
    tags: null,
  });

  const handleChange = (selectedValue, selectInputName) => {
    if (selectedValue.length === 0) {
      selectedValue = "";
    }
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [selectInputName]: selectedValue,
    }));
  };

  const handleFilters = (event) => {
    event.preventDefault();
    initFilter = {};
    //alert(event.target.elements.customInPerson.checked);

    let arrFilterData = {
      country: "US",
      state: selectedOptions.states
        ? selectedOptions.states.map((selected) =>
            selected ? selected.value : false
          )
        : false,
      startDate: eventDates ? eventDates[0] : "",
      endDate: eventDates ? eventDates[1] : "",
      combatType: selectedOptions.combats
        ? selectedOptions.combats.map((selected) =>
            selected ? selected.value : false
          )
        : false,
      contender: selectedOptions.contenders
        ? selectedOptions.contenders.map((selected) =>
            selected ? selected.value : false
          )
        : false,
      tags: selectedOptions.tags
        ? selectedOptions.tags.map((selected) =>
            selected ? selected.value : false
          )
        : false,
    };
    setApplyFilter(arrFilterData);
    filter(arrFilterData);
    setNoResults(false);
  };

  const resetForm = () => {
    let arrFilters = {
      country: "US",
      state: "",
      combatType: "",
      event_date: "",
    };
    setApplyFilter(arrFilters);
    setFilterData([]);
    setEventDates([]);
    setNoResults(false);
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      ["states"]: null,
      ["combats"]: null,
      ["contenders"]: null,
      ["tags"]: null,
    }));
  };

  let myEvents;

  const handleMyListClick = async (data, index, type) => {
    setLoading(true);

    const userevent = {
      userId: currentUser.user.id,
      eventId: data.id,
    };

    const res = await UserEventService.add(userevent);

    setLoading(false);
  };

  const filteredInputs = async () => {
    //Get Combat types for DD
    await CombatTypeService.list().then(
      (response) => {
        let arrCombatTypes = response;
        let arrCombat = [];
        arrCombatTypes.map((e, key) => {
          arrCombat.push({ value: e.id, label: e.name });
        });
        setCombatTypes(arrCombat);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setRecentEventData(_content);
      }
    );

    //Get Fighters for DD
    await ContenderService.list().then(
      (response) => {
        let arrContenders = response;
        let contendersArray = [];
        {
          arrContenders &&
            arrContenders.map((e, key) => {
              let userId = e.user.id;
              let name = e.user.fullName;
              contendersArray.push({ value: userId, label: name });
            });
        }
        //console.log("Arrcontenders==>", contendersArray);
        setContenders(contendersArray);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setRecentEventData(_content);
      }
    );

    //Get Costs for DD
    await TagService.list().then(
      (response) => {
        let arrTags = response;
        let tagsArray = [];
        {
          arrTags &&
            arrTags.map((e, key) => {
              let id = e.id;
              let name = e.name;
              tagsArray.push({ value: id, label: name });
            });
        }

        setTags(tagsArray);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setRecentEventData(_content);
      }
    );
  };

  useEffect(() => {
    //listEvents();
    //setLoading(true);
    filteredInputs();
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Events"
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div className='main-content'>
          <section id='iq-event_filter'>
            <Container fluid>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-title'>FILTER BY TYPE OR LOCATION </h4>
                </div>
                <form onSubmit={handleFilters}>
                  <div className='card-body'>
                    <div className='row '>
                      <div class='col-md-4 form-group'>
                        <label class='mt-2 mb-2'>Location:</label>

                        <Select
                          styles={customStyles}
                          theme={selectThemeColors}
                          placeholder='Select Location'
                          options={states}
                          isMulti
                          value={selectedOptions.states}
                          onChange={(selected) =>
                            handleChange(selected, "states")
                          }
                        />
                      </div>
                      <div class='col-md-4 form-group'>
                        <label class='mt-2 mb-2'>Combat Type:</label>

                        <Select
                          styles={customStyles}
                          theme={selectThemeColors}
                          placeholder='Select Combat Types'
                          class='form-control pro-dropdown'
                          options={combatTypes}
                          isMulti
                          value={selectedOptions.combats}
                          onChange={(selected) =>
                            handleChange(selected, "combats")
                          }
                        />
                      </div>
                      <div class='col-md-4 form-group'>
                        <label class='mt-2 mb-2'>Event Type:</label>

                        <Select
                          styles={customStyles}
                          theme={selectThemeColors}
                          placeholder='Select Event Types'
                          class='form-control pro-dropdown'
                          options={tags}
                          isMulti
                          value={selectedOptions.tags}
                          onChange={(selected) =>
                            handleChange(selected, "tags")
                          }
                        />
                      </div>
                      <div class='col-md-4 form-group'>
                        <label class='mt-2 mb-2'>Fighter:</label>

                        <Select
                          styles={customStyles}
                          theme={selectThemeColors}
                          placeholder='Search By Name'
                          class='form-control pro-dropdown'
                          options={contenders}
                          isMulti
                          value={selectedOptions.contenders}
                          onChange={(selected) =>
                            handleChange(selected, "contenders")
                          }
                        />
                      </div>
                      <div class='col-md-4'>
                        <label class=' mt-2' for=''>
                          Select Date Range:
                        </label>
                        <Flatpickr
                          class='mt-2'
                          id='eventDateRange'
                          name='event_date'
                          value={applyFilter.event_date}
                          options={{
                            mode: "range",
                            defaultDate: null,
                            dateFormat: "m-d-Y",
                            onClose: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {   
                              if (selectedDates && selectedDates.length === 2) {
                                var dateStart = instance.formatDate(
                                  selectedDates[0],
                                  "Y-m-d"
                                );
                                var dateEnd = instance.formatDate(
                                  selectedDates[1],
                                  "Y-m-d"
                                );
                                setEventDates([dateStart, dateEnd]);
                              }
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div class='row'>
                      <div class='col-md-12' style={{ zIndex: "0" }}>
                        {noResults && (
                          <Row>
                            <div class='float-left m-2 text-gold'>
                              There are no results matching to your search criteria!!
                            </div>
                          </Row>
                        )}
                        <Button
                          type='submit'
                          className='btn btn-hover btn-primary1 float-right m-2'
                        >
                          Apply Filter
                        </Button>
                        <Button
                          type='reset'
                          onClick={() => resetForm()}
                          className='btn btn-hover btn-primary1 float-right m-2'
                        >
                          Clear All
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Container>
          </section>

          {filterData.length > 0 && (
            <section ref={filteredDataRef} id='iq-filtered-event'>
              <Container fluid>
                <Row>
                  <Col sm='12' className='overflow-hidden'>
                    <div class='mt-3 my-3'>
                      <h4 className='main-title'>Filtered Events</h4>
                      {/*<Link className="iq-view-all" to="/movie-category">View All</Link> */}
                    </div>
                    <div id='upcoming-contens swiper-container'>
                      {/* <div id="prev2" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                        <div id="next2" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}

                      <ul class=' row list-inline  mb-0 iq-rtl-direction '>
                        {filterData?.map((data, index) => (
                          <li class='slide-item col-lg-3 col-md-4 col-6 mb-4'>
                            <Link
                              to={{
                                pathname:
                                  "/" +
                                  data.promotion?.subdomain +
                                  "/" +
                                  data.slug,
                                state: {
                                  item: data,
                                },
                              }}
                            >
                              <div className=' block-images position-relative'>
                                <div className='img-box'>
                                  <img
                                    src={data.event_promotional_photo}
                                    className='slide-image'
                                    alt=''
                                  />
                                </div>
                                <div className='block-description'>
                                  <h6 className='iq-title'>{data.name}</h6>
                                  <div
                                    className='trending-list'
                                    data-wp_object-in='fadeInUp'
                                    data-delay-in='1.2'
                                  >
                                    <div className='iq-secondary title starring'>
                                      <span className='text-body'>
                                        {data?.combattypes
                                          ?.map((combattype) => combattype.name)
                                          .join(", ")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='iq-secondary title tag'>
                                    Date & Time:{" "}
                                    <FomattedDate date={data?.event_date} />
                                  </div>
                                  {/* <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                                <div className="badge badge-secondary p-1 mr-2">5+</div>
                                                                <span className="text-white">2h 30m</span>
                                                            </div> */}
                                  {data.tags && data.tags.length > 0 && (
                                     <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Listing"/>
                                  )}
                                </div>
                                <div className='block-social-info'>
                                  <ul className='list-inline p-0 m-0 music-play-lists'>
                                    {/* <li className="share">
                                                                        <span><i className="ri-share-fill"></i></span>
                                                                        <div className="share-box">
                                                                            <div className="d-flex align-items-center">
                                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                                            </div>
                                                                        </div>
                                                                    </li> */}

                                    {currentUser && data.show && (
                                      <li>
                                        <span
                                          onClick={(e) =>
                                            handleMyListClick(
                                              data,
                                              index,
                                              "upcoming"
                                            )
                                          }
                                        >
                                          <i className='ri-add-line'></i>
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {filterData.length == 0 && <Upcoming></Upcoming>}
          {filterData.length == 0 && <Recent></Recent>}
        </div>
      )}
    </>
  );
};

export default Events;
