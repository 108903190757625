import { React } from 'react';
import { Outlet } from 'react-router-dom';

const EmbedLayout =() => {
    
    return(
        <>
            <div className="">
                <Outlet />
            </div>
       </>
    )
}

export default EmbedLayout;