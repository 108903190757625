import React, { useState, useEffect } from 'react';

const formatDate = (newDate) => {
  const nowDate = new Date(newDate);

  const today = new Date();

  var formatter = null;

  if (today > nowDate) {
    formatter = new Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  } else {
    formatter = new Intl.DateTimeFormat('en-us', {
      dateStyle: 'full',
      timeStyle: 'short',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  return formatter.format(nowDate);
  //UsFormatter.format(nowDate);
};

class FomattedDate extends React.Component {
  render() {
    const className = this.props.className || 'text-body';
    return <span className={className}>{formatDate(this.props.date)}</span>;
  }
}

export default FomattedDate;
