import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { useSelector } from "react-redux";
import EventService from "../../services/event.service";
import UsereventService from "../../services/userevent.service";
import { TAG } from "../../constants/enum";
import ticketService from "../../services/ticket.service";
import FormattedDate from "../../components/utils/FormattedDate";
import TicketButtons from "../../common/TicketButtons";
const Recent = (props) => {
  const data = props.event;
  const [recentEventData, setRecentEventData] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payType, setPayType] = useState();

  let myEvents;
  let filter = {};

  const reShapeEvents = (evts, myEvts, tkts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].show = true;
      if (myEvts) {
        for (let j = 0; j < myEvts.length; j++) {
          if (evts[i].id == myEvts[j].id) {
            evts[i].show = false;
            break;
          }
        }
      }
    }

    for (let i = 0; i < evts.length; i++) {
      evts[i].isPurchased = false;
      for (let j = 0; j < tkts.length; j++) {
        if (evts[i].id === tkts[j].eventId && tkts[j].type === TAG.PPV) {
          evts[i].isPurchased = true;
          break;
        }
      }
    }

    return evts;
  };

  const handleMyListClick = async (data, index, type) => {
    const userevent = {
      userId: currentUser.user.id,
      eventId: data.id,
    };

    const res = await UsereventService.add(userevent);

    if (res) {
      if (type == "recent") {
        setRecentEventData((prevRecentEventData) => {
          const newRecentEventData = { ...prevRecentEventData };
          newRecentEventData[index].show = false;
          const result = Object.values(newRecentEventData);
          return result;
        });
      }
    }
  };

  const listEvents = async () => {
    const user = {
      userId: currentUser?.user?.id,
    };

    if (data && data.promotion) {
      filter = {
        promotionId: data.promotion?.id,
      };
    }

    if (currentUser) {
      myEvents = await UsereventService.list(user);
      setMyList(myEvents?.events);
    }
    let tkts = [];
    if (currentUser) {
      const purchasefilter = {
        userId: currentUser.user?.id,
      };

      tkts = await ticketService.findTicketByUser(purchasefilter);
    }

    await EventService.recent(filter).then(
      (response) => {
        let evts = response.data;
        setRecentEventData(reShapeEvents(evts, myEvents?.events, tkts));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setRecentEventData(_content);
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    listEvents();
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          {recentEventData && (
            <div>
              {data && data.promotion && (
                <section id='event-detail'>
                  <div className='container-fluid'>
                    <Row>
                      <Col lg='12'>
                        <div className='trending-info g-border'>
                          <Link to={"/" + data.promotion?.subdomain}>
                            <h2 className=' big-title text-uppercase mt-0'>
                              {data.promotion?.name}
                            </h2>
                          </Link>
                          {data.description && (
                            <p className='trending-dec w-100 mb-0'>
                              {data.promotion?.description}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>
              )}
              {recentEventData && recentEventData.length > 0 && (
                <section id='iq-recent-events'>
                  <Container fluid>
                    <Row>
                      <Col sm='12' className='overflow-hidden'>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                          <h4 className='main-title'>
                            {data && data.promotion
                              ? "Related Events"
                              : "Recent Events"}{" "}
                          </h4>
                          {/*<Link className="iq-view-all" to="/movie-category">View All</Link>*/}
                        </div>
                        <div id='upcoming-contens'>
                          <div
                            id='prev3'
                            className='swiper-button swiper-button-prev'
                          >
                            <i className='fa fa-chevron-left'></i>
                          </div>
                          <div
                            id='next3'
                            className='swiper-button swiper-button-next'
                          >
                            <i className='fa fa-chevron-right'></i>
                          </div>
                          <Swiper
                            breakpoints={{
                              320: { slidesPerView: 1 },
                              550: { slidesPerView: 2 },
                              991: { slidesPerView: 3 },
                              1400: { slidesPerView: 4 },
                              1801: { slidesPerView: 5 },
                            }}
                            navigation={{
                              prevEl: "#prev3",
                              nextEl: "#next3",
                            }}
                            loop={false}
                            slidesPerView={5}
                            spaceBetween={20}
                            as='ul'
                            className='favorites-slider list-inline row p-0 m-0 iq-rtl-direction'
                          >
                            {recentEventData?.map((data, index) => (
                              <SwiperSlide as='li' key={data.id}>
                                <Link
                                  to={{
                                    pathname:
                                      "/" +
                                      data.promotion?.subdomain +
                                      "/" +
                                      data.slug,
                                    state: {
                                      item: data,
                                    },
                                  }}
                                >
                                  <div className=' block-images position-relative'>
                                    <div className='img-box'>
                                      <img
                                        src={data.event_promotional_photo}
                                        className='slide-image'
                                        alt=''
                                      />
                                    </div>
                                    <div className='block-description'>
                                      <Link
                                        to={{
                                          pathname:
                                            "/" +
                                            data.promotion?.subdomain +
                                            "/" +
                                            data.slug,
                                          state: {
                                            item: data,
                                          },
                                        }}
                                      >
                                        <h6 className='iq-title'>
                                          {data.name}
                                        </h6>
                                        {data.combattypes &&
                                          data.combattypes.length > 0 && (
                                            <div className='iq-secondary title starring'>
                                              <span className='text-body'>
                                                {data?.combattypes
                                                  ?.map(
                                                    (combattype) =>
                                                      combattype.name
                                                  )
                                                  .join(", ")}
                                              </span>
                                            </div>
                                          )}
                                        {data.event_date && (
                                          <div className='iq-secondary title tag'>
                                            Date & Time:{" "}
                                            <FormattedDate
                                              date={data.event_date}
                                            />
                                          </div>
                                        )}
                                        {data.tags && data.tags.length > 0 && (
                                          <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Listing"/>
                                        )}
                                      </Link>
                                    </div>
                                    <div className='block-social-info'>
                                      <ul className='list-inline p-0 m-0 music-play-lists'>
                                        {/* <li className="share">
                                                                        <span><i className="ri-share-fill"></i></span>
                                                                        <div className="share-box">
                                                                            <div className="d-flex align-items-center">
                                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                                            </div>
                                                                        </div>
                                                                    </li> */}
                                        {currentUser && data.show && (
                                          <li>
                                            <span
                                              onClick={(e) =>
                                                handleMyListClick(
                                                  data,
                                                  index,
                                                  "recent"
                                                )
                                              }
                                            >
                                              <i className='ri-add-line'></i>
                                            </span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </Link>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </div>
          )}{" "}
        </>
      )}
    </>
  );
};

export default Recent;
