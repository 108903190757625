import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../slices/auth"
import messageReducer from "../slices/message";
import subReducer from "../slices/sub";
import alertReducer from "../slices/alert";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  sub: subReducer,
  alert: alertReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;