import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Dropdown,
  Form,
  Nav,
} from "react-bootstrap";
import CustomToggle from "../dropdowns";
import { Link } from "react-router-dom";
import GlobalSearchService from "../../services/globalsearch.service";

const GlobalSearch = (props) => {
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 2) {
      await GlobalSearchService.search(e.target.value).then((res) => {
        if (
          res.result.contender.length === 0 &&
          res.result.event.length === 0 &&
          res.result.promotion.length === 0
        ) {
          setSearchResult(false);
        } else {
          setSearchResult(res.result);
        }
      });
    } else {
      setSearchResult([]);
    }
  };

  return (
    <>
      <Dropdown as="li" className="nav-item nav-icon">
        <Dropdown.Toggle
          as={CustomToggle}
          href="#"
          variant="search-toggle device-search"
        >
          <i className="ri-search-line sdfsdf"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="search-box iq-search-bar d-search p-0 m-0"
          align="right"
          style={{ zIndex: "2" }}
        >
          <Form action="#" className="searchbox">
            <div className="position-relative">
              <input
                type="text"
                name="globalsearch"
                autoFocus
                autofocus
                className="text search-input font-size-12"
                onChange={(e) => handleSearch(e)}
                placeholder="type here to search..."
              />
              <i className="search-link ri-search-line"></i>
              <div className="searchbox__result">
                <div className="list-group">
                  {searchResult.contender &&
                    searchResult.contender.length > 0 &&
                    searchResult.contender.map((item, index) => {
                      let fighterName = item.user.firstname;
                      return (
                        <div className="parent" key={index}>
                          {/* <div className="result-child"> */}
                          {item.user && (
                            <>
                              <div className="result-child">
                                <div className="group-title">Fighter</div>
                                <Link
                                  to={{ pathname: "/fighter/" + item.user.id }}
                                  className="list-group-item"
                                >
                                  <div className="media align-items-center">
                                    <i class="ri-arrow-right-fill"></i>{" "}
                                    {item.user.firstname} {item.user.lastname}
                                  </div>
                                </Link>
                              </div>
                            </>
                          )}

                          {item.fighter1 &&
                            item.fighter1.map((fighter1, index) => (
                              <>
                                <div className="result-child">
                                  {index == 0 && (
                                    <div className="group-title">
                                      {fighterName}'s Events
                                    </div>
                                  )}
                                  <Link
                                    to={{
                                      pathname:
                                        "/" +
                                        fighter1.event.promotion?.subdomain +
                                        "/" +
                                        fighter1.event.slug,
                                    }}
                                    className="list-group-item"
                                  >
                                    <div className="media align-items-center">
                                      <i class="ri-arrow-right-fill"></i>{" "}
                                      {fighter1.event.name}
                                      <br />
                                    </div>
                                  </Link>
                                  <Link
                                    to={{
                                      pathname:
                                        "/" +
                                        fighter1.event.promotion?.subdomain,
                                    }}
                                    className="list-group-item"
                                  >
                                    <div className="media align-items-center">
                                      <i class="ri-arrow-right-fill"></i> &nbsp;
                                      <em>
                                        <b>Promotion</b>:{" "}
                                        {fighter1.event.promotion.name}
                                      </em>
                                    </div>
                                  </Link>
                                </div>
                              </>
                            ))}

                          {item.fighter2 &&
                            item.fighter2.map((fighter2, index) => (
                              <>
                                <div className="result-child">
                                  {index == 0 && (
                                    <div className="group-title">
                                      {fighterName}'s Events
                                    </div>
                                  )}
                                  <Link
                                    to={{
                                      pathname:
                                        "/" +
                                        fighter2.event.promotion?.subdomain +
                                        "/" +
                                        fighter2.event.slug,
                                    }}
                                    className="list-group-item"
                                  >
                                    <div className="media align-items-center">
                                      <i class="ri-arrow-right-fill"></i> &nbsp;
                                      {fighter2.event.name}
                                    </div>
                                  </Link>
                                  <Link
                                    to={{
                                      pathname:
                                        "/" +
                                        fighter2.event.promotion?.subdomain,
                                    }}
                                    className="list-group-item"
                                  >
                                    <div className="media align-items-center">
                                      <i class="ri-arrow-right-fill"></i>{" "}
                                      <em>
                                        <b>Promotion</b>:{" "}
                                        {fighter2.event.promotion.name}
                                      </em>
                                    </div>
                                  </Link>
                                </div>
                              </>
                            ))}

                          {/* </div> */}
                        </div>
                      );
                    })}

                  {searchResult.promotion &&
                    searchResult.promotion.length > 0 &&
                    searchResult.promotion.map((item, index) => (
                      <div className="parent">
                        <div className="result-child">
                          <Link
                            to={{ pathname: "/" + item.subdomain }}
                            className="list-group-item"
                          >
                            <div className="media align-items-center">
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}

                  {searchResult.event &&
                    searchResult.event.length > 0 &&
                    searchResult.event.map((item, index) => (
                      <div className="parent">
                        <div className="result-child">
                          <Link
                            to={{
                              pathname:
                                "/" +
                                item.promotion?.subdomain +
                                "/" +
                                item.slug,
                            }}
                            className="list-group-item"
                          >
                            <div className="media align-items-center">
                              {item.name}
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  {!searchResult && (
                    <div className="parent">
                      <div className="result-child">
                        <Link to="#" className="list-group-item">
                          <div className="media align-items-center">
                            No Results!
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
export default GlobalSearch;
