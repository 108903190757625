import React, { useState, useEffect } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { register, login } from "../../../../slices/auth"
import AlertNotification from '../../../../components/AlertNotification/AlertNotification';
import InitializeGoogleAnalytics, { TrackPageView } from '../../../../components/utils/googleanalytics';

const SignUp = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();


    const [input, setInput] = useState({
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        confirmPassword: '',
        consent: false
    });

    const [error, setError] = useState({
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        confirmPassword: '',
        consent: false,
    })

    const onInputChange = e => {
        const { name, value } = e.target;
        if( name == 'consent' ) {
            setInput(prev => ({
                ...prev,
                [name]: e.target.checked
            }));
        }
        else {
            setInput(prev => ({
                ...prev,
                [name]: value
            }));
        }
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "username":
                    if (!value) {
                        stateObj[name] = "Please enter Username.";
                    }
                    break;
                case "firstname":
                    if (!value) {
                        stateObj[name] = "Please enter firstname.";
                    }
                    break;

                case "lastname":
                    if (!value) {
                        stateObj[name] = "Please enter lastname.";
                    }
                    break;
                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;
                case "consent":
                   if (!e.target.checked) {
                        stateObj[name] = "Please agree to the terms and conditions.";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    }



    const signUp = async () => {
     
        if (error.firstname != '' || error.lastname != '' || error.username != '' || error.lastname != '' || error.password != '' || error.confirmPassword != '' || error.consent != '') {
            //console.log('Resolve the pending error');
            return;
        }
       
        dispatch(register({ input }))
            .unwrap()
            .then(() => {
                navigate(-1);
            })
            .catch((err) => {
                //setError({resError:err.message});
            });


    };

    const handleSubmit = (e) => {
        e.preventDefault();
        signUp();
        InitializeGoogleAnalytics();
        // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
        TrackPageView('pageview',window.location.pathname + window.location.search,'Combat Labs - Singup');
    };

    return (
        <>
            <section className="sign-in-page">
                <Container>
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <div>

                                        </div>
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control name='firstname' type="text" className="mb-0" id="exampleInputEmail2" placeholder="First Name" value={input.firstname} onChange={onInputChange} onBlur={validateInput} autoComplete="off" required />
                                                        {error.firstname && <span className='err text-danger'>{error.firstname}</span>}
                                                        
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control name='lastname' type="text" className="mb-0" id="exampleInputEmail3" placeholder="Last Name" value={input.lastname} onChange={onInputChange} onBlur={validateInput} autoComplete="off" required />
                                                        {error.lastname && <span className='err text-danger'>{error.lastname}</span>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="12">
                                                    <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control name='username' type="text" className="form-control mb-0" id="exampleInputEmail2" placeholder="Enter your email"
                                                            value={input.username} onChange={onInputChange} onBlur={validateInput} autoComplete="off" required></Form.Control>
                                                        {error.username && <span className='err text-danger'>{error.username}</span>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control name='password' type="password" className="mb-0" id="exampleInputPassword2" value={input.password} onChange={onInputChange} onBlur={validateInput} autoComplete="off" />
                                                        {error.password && <span className='err text-danger'>{error.password}</span>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label>Repeat Password</Form.Label>
                                                        <Form.Control name="confirmPassword" type="password" className="mb-0" id="exampleInputPassword2" placeholder="Password" required value={input.confirmPassword} onChange={onInputChange} onBlur={validateInput} autoComplete="off" />
                                                        {error.confirmPassword && <span className='err text-danger'>{error.confirmPassword}</span>}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" className='mt-3'>
                                                    <div className="custom-control custom-checkbox">
                                                        <input name="consent" type="checkbox" className="custom-control-input" id="consent" required value={input.consent} onChange={onInputChange} />
                                                        <Form.Label className="custom-control-label" htmlFor="consent"> I agree to  <Link to={
                                                            {
                                                                pathname: "/terms-of-service",
                                                                state: {
                                                                    from: location.pathname
                                                                }
                                                            }}> <u>Terms of use</u></Link> and <Link to={
                                                                {
                                                                    pathname: "/privacy-policy",
                                                                    state: {
                                                                        from: location.pathname
                                                                    }
                                                                }}> <u>Privacy Policy.</u></Link> </Form.Label>
                                                              {error.consent && <span className='err text-danger'>{error.consent}</span>}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <AlertNotification />
                                            <Button type="submit" className="btn btn-hover btn-primary1 my-2 ">Sign Up</Button>
                                        </Form>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex justify-content-center links text-white">
                                        Already have an account?
                                        <Link to="/embed/login" className="text-white ml-2 text-gold">Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SignUp