import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "userprofile/";

const create = async (data) => {
  return axios
  .post(API_URL + "create", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
      
        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
  });
       
};


const update = async (data) => {
  return axios
  .post(API_URL + "update", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
      
        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
  });  
}; 

const find = async (userId) => {

  return axios
  .get(API_URL + "find?userId=" + userId
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
      
        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
  });
       
};

export default {
    create,
    update,
    find,
};