import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { useSelector } from "react-redux";
import EventService from "../../services/event.service";
import UsereventService from "../../services/userevent.service";
import ticketService from "../../services/ticket.service";
import { TAG } from "../../constants/enum";
import TicketButtons from "../../common/TicketButtons";

import FormattedDate from "../../components/utils/FormattedDate";

const Upcoming = (props) => {
  const [eventData, setEventData] = useState(props.event);

  const [promotionId, setPromotionId] = useState(
    props.event?.promotion?.id
      ? props.event?.promotion?.id
      : props.promotionId
      ? props.promotionId
      : null
  );

  const [upcomingEventData, setUpcomingEventData] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(false);
  let myEvents;
  let filter = {};

  const reShapeEvents = (evts, myEvts, tkts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].show = true;
      if (myEvts) {
        for (let j = 0; j < myEvts.length; j++) {
          if (evts[i].id == myEvts[j].id) {
            evts[i].show = false;
            break;
          }
        }
      }
    }

    for (let i = 0; i < evts.length; i++) {
      evts[i].isPurchased = false;
      for (let j = 0; j < tkts.length; j++) {
        if (evts[i].id === tkts[j].eventId && tkts[j].type === TAG.PPV) {
          evts[i].isPurchased = true;
          break;
        }
      }
    }
    return evts;
  };
  const handleMyListClick = async (data, index, type) => {
    const userevent = {
      userId: currentUser.user.id,
      eventId: data.id,
    };

    const res = await UsereventService.add(userevent);

    if (res) {
      if (type == "upcoming") {
        setUpcomingEventData((prevUpcomingEventData) => {
          const newUpcomingEventData = { ...prevUpcomingEventData };
          newUpcomingEventData[index].show = false;
          const result1 = Object.values(newUpcomingEventData);
          return result1;
        });
      }
    }
  };

  const listEvents = async (promotionId) => {
    const user = {
      userId: currentUser?.user?.id,
    };

    if (promotionId) {
      filter = {
        promotionId: promotionId,
      };
    }

    let tkts = [];

    if (currentUser) {
      myEvents = await UsereventService.list(user);
      setMyList(myEvents?.events);

      const purchasefilter = {
        userId: currentUser.user?.id,
      };

      tkts = await ticketService.findTicketByUser(purchasefilter);
    }

    await EventService.upcoming(filter).then(
      (response) => {
        let evts = response.data;
        setUpcomingEventData(reShapeEvents(evts, myEvents?.events, tkts));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setRecentEventData(_content);
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    //console.log("promotionId", promotionId);
    listEvents(promotionId);
  }, []);
  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div>
          {eventData && eventData.promotion && (
            <section id='event-detail'>
              <div className='container-fluid'>
                <Row>
                  <Col lg='12'>
                    <div className='trending-info g-border'>
                      <Link to={"/" + eventData.promotion?.subdomain}>
                        <h2 className=' big-title text-uppercase mt-0'>
                          {eventData.promotion?.name}
                        </h2>
                      </Link>
                      {eventData.promotion?.description && (
                        <p className='trending-dec w-100 mb-0'>
                          {eventData.promotion?.description}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          )}
          {upcomingEventData && upcomingEventData.length > 0 && (
            <section id='iq-upcoming-event'>
              <Container fluid>
                <Row>
                  <Col sm='12' className='overflow-hidden'>
                    <>
                      <div className='d-flex align-items-center justify-content-between mt-3'>
                        <h4 className='main-title'>
                          {" "}
                          {eventData && eventData.promotion
                            ? "Related Events"
                            : "Upcoming Events"}
                        </h4>
                        {/*<Link className="iq-view-all" to="/movie-category">View All</Link> */}
                      </div>
                      <div id='upcoming-contens'>
                        <div
                          id='prev2'
                          className='swiper-button swiper-button-prev'
                        >
                          <i className='fa fa-chevron-left'></i>
                        </div>
                        <div
                          id='next2'
                          className='swiper-button swiper-button-next'
                        >
                          <i className='fa fa-chevron-right'></i>
                        </div>
                        <Swiper
                          breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1801: { slidesPerView: 5 },
                          }}
                          navigation={{
                            prevEl: "#prev2",
                            nextEl: "#next2",
                          }}
                          loop={false}
                          slidesPerView={5}
                          spaceBetween={20}
                          as='ul'
                          className='favorites-slider list-inline row p-0 m-0 iq-rtl-direction'
                        >
                          {upcomingEventData?.map((data, index) => (
                            <SwiperSlide as='li' key={data.id}>
                              <Link
                                to={{
                                  pathname:
                                    "/" +
                                    data.promotion?.subdomain +
                                    "/" +
                                    data.slug,
                                  state: {
                                    item: data,
                                  },
                                }}
                              >
                                <div className=' block-images position-relative'>
                                  <div className='img-box'>
                                    <img
                                      src={data.event_promotional_photo}
                                      className='slide-image'
                                      alt=''
                                    />
                                  </div>
                                  <div className='block-description'>
                                    <Link
                                      to={{
                                        pathname:
                                          "/" +
                                          data.promotion?.subdomain +
                                          "/" +
                                          data.slug,
                                        state: {
                                          item: data,
                                        },
                                      }}
                                    >
                                      <h6 className='iq-title'>{data.name}</h6>
                                      <div
                                        className='trending-list'
                                        data-wp_object-in='fadeInUp'
                                        data-delay-in='1.2'
                                      >
                                        <div className='iq-secondary title starring'>
                                          <span className='text-body'>
                                            {data?.combattypes
                                              ?.map(
                                                (combattype) => combattype.name
                                              )
                                              .join(", ")}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='iq-secondary title tag'>
                                        Date & Time:{" "}
                                        <FormattedDate date={data.event_date} />
                                      </div>
                                      {/* <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                                                <div className="badge badge-secondary p-1 mr-2">5+</div>
                                                                <span className="text-white">2h 30m</span>
                                                            </div> */}

                                      {data.tags && data.tags.length > 0 && (
                                        <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Listing" />
                                      )}
                                    </Link>
                                  </div>
                                  <div className='block-social-info'>
                                    <ul className='list-inline p-0 m-0 music-play-lists'>
                                      {/* <li className="share">
                                                                        <span><i className="ri-share-fill"></i></span>
                                                                        <div className="share-box">
                                                                            <div className="d-flex align-items-center">
                                                                                <Link to="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-facebook-fill"></i></Link>
                                                                                <Link to="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" className="share-ico" tabIndex="0"><i className="ri-twitter-fill"></i></Link>
                                                                                <Link to="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" className="share-ico iq-copy-link" tabIndex="0"><i className="ri-links-fill"></i></Link>
                                                                            </div>
                                                                        </div>
                                                                    </li> */}

                                      {currentUser && data.show && (
                                        <li>
                                          <span
                                            onClick={(e) =>
                                              handleMyListClick(
                                                data,
                                                index,
                                                "upcoming"
                                              )
                                            }
                                          >
                                            <i className='ri-add-line'></i>
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>{" "}
                    </>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default Upcoming;
