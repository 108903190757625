import React,{useState,useEffect} from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useNavigate ,Link} from 'react-router-dom'
import authService from "../../../../services/auth.service";
const { REACT_APP_DOMAIN } = process.env;
const ForgotPassword = (props) => {

    let navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        authService.forgotpassword({username:email,domain:REACT_APP_DOMAIN + 'embed/'})
            .then((response) => {
                if(response.status === 200 || response.status === 201 || response.status === 202) {
                    setSubmitted(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            
            <section className="sign-in-page">
                <Container>
                    {submitted === false ? (
                    <Row className="row justify-content-center align-items-center height-self-center">
                        <Col lg="5" md="12" className="align-self-center">
                            <div className="sign-user_card ">                    
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <h3 className="mb-3 text-center">Reset Password</h3>
                                        <p className="text-body">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                        <Form className="mt-4">
                                            <div className="form-group">                                 
                                                <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control mb-0" id="exampleInputEmail2" placeholder="Enter email" autoComplete="off" required/>
                                            </div>                           
                                            <div className="sign-info">
                                                <Button type='submit' className="btn btn-hover btn-primary1" onClick={(e) => handleSubmit(e)}>Reset</Button>                                                            
                                            </div>                                       
                                        </Form>
                                    </div>
                                </div>                    
                            </div>
                        </Col>
                    </Row>
                    ) : (
                        <Row className="row justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">
                            <p className="text-body">We have sent an email with instructions to reset your password.</p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>
        </>
    )
}

export default ForgotPassword