import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { TAG } from "../../../constants/enum";
import "swiper/swiper-bundle.css";
import EventService from "../../../services/event.service";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import FormattedDate from "../../../components/utils/FormattedDate";
import Recent from "../../../components/event/Recent";
import InitializeGoogleAnalytics, {
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import Featuring from "../../../components/bout/featuring";
import boutService from "../../../services/bout.service";
import ticketService from "../../../services/ticket.service";
import eventTicketService from "../../../services/eventtickettype.service";
import TicketButtons from "../../../common/TicketButtons";

import { set } from "react-ga";

const EventDetail = (props) => {
  const [payType, setPayType] = useState([]);
  const [data, setData] = useState();
  const [id, setId] = useState();
  const { subdomain, slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [fighters, setFigheters] = useState(props.fighters);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const [isTicketType, setTicketType] = useState(false);
  const [isContenders, setContenders] = useState(false);

  const listContenders = async (id) => {
    let listFighters = [];
    await boutService
      .listContendersByEvent({ eventId: id })
      .then((response) => {
        if (response) {
          response.map((item) => {
            let contender1 = item.fighter1?.user;
            let contender2 = item.fighter2?.user;

            if (contender1) {
              listFighters.push({
                id: contender1.id,
                name: contender1.fullName,
                weight: item.fighter1.weight,
                height: item.fighter1.height,
              });
            }
            if (contender2) {
              listFighters.push({
                id: contender2.id,
                name: contender2.fullName,
                weight: item.fighter2.weight,
                height: item.fighter2.height,
              });
            }
          });
          setFigheters(listFighters);
          if (listFighters.length > 0) {
            setContenders(true);
          }
        }
      });
  };

  const isTicketTypeAvailble = async (id) => {
    let isTypeAvailble = false;
    let res = await eventTicketService.list(id);
    if (res.length > 0) {
      isTypeAvailble = true;
    }
    setTicketType(isTypeAvailble);
    return isTypeAvailble;
  };

  const eventDetails = async (slug) => {
    let res = await EventService.findBySubdomainAndSlug(subdomain, slug);
    let event = res.data;
    //setData(event);

    let tkts = [];

    if (currentUser) {
      const purchasefilter = {
        userId: currentUser.user?.id,
      };

      tkts = await ticketService.findTicketByUser(purchasefilter);
    }

    let purchasetkt = tkts.find((tkt) => tkt.eventId === event.id && tkt.type === TAG.PPV);
    if (purchasetkt) {
      setData({ ...event, purchased: true });
    } else {
      setData({ ...event, purchased: false });
    }

    setPayType(event.tags?.map((tag) => tag.name));
    setId(event.id);
    listContenders(event.id);
    isTicketTypeAvailble(event.id);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    eventDetails(slug);
    InitializeGoogleAnalytics();
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - Event Details"
    );
  }, [subdomain, slug]);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <main className='main-content'>
          <div className='mandalorian'>
            {data && (
              <div
                className='banner-wrapper overlay-wrapper iq-main-slider'
                style={{
                  backgroundImage: "url(" + data.event_promotional_photo + ")",
                }}
              >
                <div className='banner-caption'>
                  <div className='movie-detail'>
                    <Row>
                      <Col lg='12'>
                        <div className='trending-info p-0'>
                          <h1 className='trending-text big-title text-uppercase mt-0'>
                            {data.name}
                          </h1>
                          <div
                            className='d-flex flex-wrap align-items-center text-white text-detail'
                            data-animation-in='fadeInLeft'
                          >
                            {data?.event_date && (
                              <FormattedDate date={data.event_date} />
                            )}
                          </div>
                          {data.description && (
                            // <div className="d-flex flex-wrap align-items-center text-white text-detail" data-animation-in="fadeInLeft">
                            <div className='trending-dec'>
                              <p>{data.description}</p>
                            </div>
                          )}
                          <div
                            className='d-flex flex-wrap align-items-center text-white '
                            data-animation-in='fadeInLeft'
                          >
                            <span className=''>
                              Combat Type:{" "}
                              {data?.combattypes
                                ?.map((combattype) => combattype.name)
                                .join(", ")}
                            </span>
                          </div>
                          <div
                            className='d-flex flex-wrap align-items-center text-white'
                            data-animation-in='fadeInLeft'
                          >
                            <div className='iq-secondary title genres'>
                              <span className=''>
                                {" "}
                                Venue Name & Location: {data?.location},{" "}
                                {data?.city}, {data?.state}
                              </span>
                            </div>
                          </div>
                          <div
                            className='d-flex flex-wrap align-items-center text-white'
                            data-animation-in='fadeInLeft'
                          >
                            <span className=''>
                              Tag:{" "}
                              {data?.tags?.map((tag) => tag.name).join(", ")}{" "}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row className="p-3">
                    <TicketButtons data={data} currentUser={currentUser} currentSubscription={currentSubscription} pagetype="Detail"/>
                  </Row>
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
          {data && <Recent event={data}></Recent>}
          {fighters && <Featuring fighters={fighters}></Featuring>}
        </main>
      )}
    </>
  );
};

export default EventDetail;
