import { createSlice } from "@reduxjs/toolkit";

const initialState =  {
    value: null
};

const alertSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    success: (state, action) => {
        state.value =  {
            type: 'success',
            message: action.payload?.message || action.payload,
            showAfterRedirect: action.payload?.showAfterRedirect
        }    
    },
    error: (state, action) => {
        state.value = {
            type: 'danger',
            message: action.payload?.message || action.payload,
            showAfterRedirect: action.payload?.showAfterRedirect
        };
    },
    clear: (state, action) => {
        if (state.value?.showAfterRedirect) {
            state.value.showAfterRedirect = false;
        } else {
            state.value = null;
        }
    },
  },
});

const { reducer, actions } = alertSlice;

export const { success, error, clear } = actions
export const alertActions = { ...alertSlice.actions };
export default reducer;