import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "contenders/";

const list = async (data) => {
  return axios
  .get(API_URL + "findAll", data
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
    
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
  });
       
};

const find = async (id) => {
  return axios
  .get(API_URL + "find/"+id
   , {headers: authHeader(),'content-type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*"}).then((response) => {
      return response.data;
    })
      .catch(function (error) {
    
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
  });
       
};




export default {
    list,
    find
};