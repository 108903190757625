import React, { useState, useEffect } from "react";
import { Col, Form, Row, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link, useLocation, useParams } from "react-router-dom";
import EventTicketType from "../../services/eventtickettype.service";
import FormattedDate from "../../components/utils/FormattedDate";
import NoBannerImg from "../../assets/images/no-banner.gif";
import NoSeatMapImg from "../../assets/images/no-seatmap.gif";
import boutService from "../../services/bout.service";
import EventService from "../../services/event.service";
import PTEvent from "../../components/event/PTEvent";
import { format } from "date-fns";
import ZoomImg from "./zoom";

const Ticket = (props) => {
  //console.log("In Details page", props);
  const { subdomain, slug } = useParams();
  const { user: currentUser } = useSelector((state) => state.auth);

  const location = useLocation();
  let dispatch = useDispatch();
  const quantity = [];

  const paymentElementOptions = {
    layout: "tabs",
    theme: "night",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#24292d",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#24292d",
      textColor: "white",
      borderColor: "#000000",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "white";

      return { ...provided, opacity, transition, color };
    },
  };
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [contender, setContender] = useState(null);
  const [validated, setValidated] = useState(false);
  const [ticketType, setTicketType] = useState(null);
  const [fighterList, setFighterList] = useState(null);
  const [eventDetails, setEventDetails] = useState(props);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [PTformError, setPTformError] = useState(null);
  const [selectedFighter, setSelectedFighter] = useState(null);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const listContenders = async (id) => {
    let listFighters = [];
    await boutService
      .listContendersByEvent({ eventId: id })
      .then((response) => {
        if (response) {
          response.forEach((item) => {
            let contender1 = item.fighter1?.user;
            let contender2 = item.fighter2?.user;

            // Check and add contender1 if not already in the list
            if (
              contender1 &&
              !listFighters.some((fighter) => fighter.id === contender1.id)
            ) {
              listFighters.push({
                id: contender1.id,
                name: contender1.fullName,
                weight: item.fighter1.weight,
                height: item.fighter1.height,
              });
            }

            // Check and add contender2 if not already in the list
            if (
              contender2 &&
              !listFighters.some((fighter) => fighter.id === contender2.id)
            ) {
              listFighters.push({
                id: contender2.id,
                name: contender2.fullName,
                weight: item.fighter2.weight,
                height: item.fighter2.height,
              });
            }
          });
          setFighterList(listFighters);
        }
      });
  };

  const getTicketType = async () => {
    let res = await EventTicketType.list(props.event.id);
    //console.log("In Ticket Type", res);
    setTicketType(res);
  };
  const eventData = async () => {
    let res = await EventService.findBySubdomainAndSlug(subdomain, slug);
    setData(res.data);
  };
  useEffect(() => {
    getTicketType();
    eventData();
    listContenders(props.event.id);
  }, [props.event.id]);

  const handleTickets = (e, id, name, price, eventId) => {
    //console.log("Fighter", selectedFighter);
    // Define the new or updated ticket structure
    const ticket = {
      eventId: eventId,
      ticketId: "", // Assuming this gets filled elsewhere or is not needed for uniqueness
      ticketTypeId: id,
      ticketTypeName: name,
      purchase_qty: e.value,
      price: price,
      support_contender_id: selectedFighter,
    };

    // Attempt to find an existing ticket by ID
    const existingTicketIndex = selectedTickets.findIndex(
      (item) => item.ticketTypeId === id
    );

    let updatedTickets;
    if (existingTicketIndex !== -1) {
      // Ticket exists, update the quantity of the existing ticket
      updatedTickets = selectedTickets.map((item, index) => {
        if (index === existingTicketIndex) {
          return {
            ...item,
            purchase_qty: e.value, // Update the purchase quantity
          };
        }
        return item;
      });
    } else {
      // Ticket doesn't exist, add the new ticket
      updatedTickets = [...selectedTickets, ticket];
    }

    setSelectedTickets(updatedTickets); // Update the state with the new or updated tickets array
    setPTformError(null); // Clear any existing form errors
  };

  const getOptionsForTicketType = (quantity) => {
    let options = [];
    // Generate options based on the available quantity
    for (let i = 1; i <= quantity; i++) {
      options.push({ value: i, label: i.toString() });
    }

    return options;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedTickets || selectedTickets.length === 0) {
      setPTformError("Please select at least 1 quantity of tickets");
    } else {
      //console.log("Selected Tickets:", selectedTickets);
      setRedirectToCheckout(true); // Set the flag to true to redirect
    }
  };
  //console.log(props.event);

  function handleFighterChange(e) {
    const selectedFighterId = e.target.value;
    //console.log("In Fighter Change", selectedFighterId);
    setSelectedFighter(selectedFighterId);
  }

  if (redirectToCheckout) {
    return (
      <PTEvent
        event={data}
        ticket={selectedTickets}
        payType={"PT Event"}
        contender={selectedFighter}
        fighterId={props.fighterId ? props.fighterId.toString() : null}
      ></PTEvent>
    ); // Render your checkout component
  }

  const TimeDisplay = ({ event }) => {
    // Directly access event_date from event prop
    return <span>{format(new Date(event.event_date), "p")}</span>; // Formats the date to something like "5:30 PM"
  };
  return (
    <>
      <div className='main-content'>
        <div className='container'>
          <div className='row justify-content-center align-items-center height-self-center'>
            <section>
              <Card>
                <Card.Body>
                  <form id='physicalticketform' onSubmit={handleSubmit}>
                    <Row>
                      <Col sm='4'>
                        <img
                          src={props.event.event_promotional_photo || NoBannerImg}
                          alt='Event Banner'
                          style={{ display: "none" }}
                        />

                        <img
                          src={props.event.event_promotional_photo}
                          alt='Event Banner'
                          onError={(e) => {
                            if (e.target.src !== NoBannerImg) {
                              // Check to prevent infinite loop
                              e.target.src = NoBannerImg; // Replace with your fallback image path
                            }
                          }}
                        />

                        <div className='movie-detail '>
                          <div className='trending-info p-0' style={{ "zIndex": "0" }}>
                            <h1 className='trending-text big-title text-uppercase mt-0'>
                              {props.event.name}
                            </h1>
                            <div
                              className='d-flex flex-wrap align-items-center text-white text-detail'
                              data-animation-in='fadeInLeft'
                            >
                              {props.event?.event_date && (
                                <FormattedDate date={props.event.event_date} />
                              )}
                            </div>
                            <div>
                              Event Time : <TimeDisplay event={props.event} />
                            </div>

                            <div className='event-dec'>
                              <p>{props.event.description}</p>
                            </div>

                            <div
                              className='d-flex flex-wrap align-items-center text-white '
                              data-animation-in='fadeInLeft'
                            >
                              <span className='event-type'>
                                Combat Type:
                                {props?.event.combattypes
                                  ?.map((combattype) => combattype.name)
                                  .join(", ")}
                              </span>
                            </div>
                            <div
                              className='d-flex flex-wrap align-items-center text-white'
                              data-animation-in='fadeInLeft'
                            >
                              <div className='iq-secondary title genres'>
                                <span className='event-venue'>
                                  {" "}
                                  Venue Name & Location: {
                                    props?.event.location
                                  }, {props?.event.city}, {props?.event.state}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm='8'>
                        <Row className='d-flex justify-content-center p-5'>
                          <h3>Event Tickets</h3>
                        </Row>

                        <Row>
                          <Col sm='12'>
                            <div className='table-responsive-sm'>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th className="align-middle" scope='col'>Ticket Type</th>
                                    <th className="text-center align-middle">Price</th>
                                    <th className='text-center align-middle'>
                                      Quantity
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ticketType &&
                                    ticketType.map((item, index) => (
                                      <tr key={index}>
                                        <td className="align-middle">
                                          {item.ticketSubType}
                                        </td>
                                        <td className='align-middle text-center'>
                                          ${(
                                            parseFloat(item.price) + // Ensure item.price is treated as a number
                                            (parseFloat(item.price * 0.04)) + // 4% fee
                                            2.5 // Additional $2.5
                                          ).toFixed(2)}
                                        </td>
                                        <td className='align-middle text-center d-flex justify-content-center'>
                                          <div className="col-sm-8">
                                            <Select
                                              className='align-middle'
                                              styles={customStyles}
                                              name='contender'
                                              onChange={(e) =>
                                                handleTickets(
                                                  e,
                                                  item.id,
                                                  item.ticketSubType,
                                                  item.price,
                                                  item.eventId
                                                )
                                              }
                                              options={getOptionsForTicketType(
                                                item.qty - item.soldTicketCount
                                              )}
                                              isSearchable={true}
                                              placeholder='0'
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <div className='col-md-12 text-right p-1'>
                            <div className='p-2 text-center'>
                              <span
                                className=''
                                style={{ color: "red", "fontSize": "20px" }}
                              >
                                {PTformError}
                              </span>
                            </div>
                            <div className='p-5 text-right '>
                              <button
                                type='submit'
                                className='btn btn-hover btn-primary1 btn-primary'
                                style={{ "zIndex": "0" }}
                              >
                                Buy Now
                              </button>
                            </div>

                            {/* <Link
                            to={{
                              pathname: "/checkout",
                              state: {
                                tickets: selectedTickets,
                                event: props.event,
                              },
                            }}
                            className='btn btn-primary'
                          >
                            Buy Now
                          </Link> */}
                          </div>
                        </Row>
                        <Row className='text-center'>
                          <Col sm='12'>
                            <img
                              src={
                                props.event.event_seat_map_photo || NoSeatMapImg
                              }
                              alt='Event Banner'
                              style={{ display: "none", width: "200px" }}
                            />

                            <ZoomImg src={props.event.event_seat_map_photo} alt="Descriptive alt text" />

                            {/* <img
                            src={props.event.event_seat_map_photo}
                            alt='Event Banner'
                            onError={(e) => {
                              if (e.target.src !== NoSeatMapImg) {
                                // Check to prevent infinite loop
                                e.target.src = NoSeatMapImg; // Replace with your fallback image path
                              }
                            }} 
                          />{" "}*/}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ticket;
