import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
// import CustomToggle from '../../../components/dropdowns'

//img

import image1 from "../../../assets/images/faq/faq.png";
import InitializeGoogleAnalytics, {
  TrackGoogleAnalyticsEvent,
  TrackPageView,
} from "../../../components/utils/googleanalytics";
import { Link } from "react-router-dom";

const FAQ = () => {
  const [faq, setfaq] = useState("1");

  useEffect(() => {
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
    TrackPageView(
      "pageview",
      window.location.pathname + window.location.search,
      "Combat Labs - FAQ"
    );
  }, []);

  return (
    <>
      <div
        className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        style={{ backgroundImage: `url(${image1})` }}
      >
        <Container fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav
                aria-label="breadcrumb"
                className="text-center iq-breadcrumb-two"
              >
                <h2 className="title">FAQ & Support</h2>
                <Breadcrumb className="main-bg">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>FAQ & Support</Breadcrumb.Item>
                </Breadcrumb>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="iq-accordion iq-accordion-square">
                <div
                  className={`iq-accordion-block  1 ${
                    faq === "1" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("1");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      How do I watch content?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "1" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      All content requires an account, please sign up using the
                      top right icon and registering a new account or signing in
                      if you’ve already created an account. Most content
                      requires an active subscription or a purchase for
                      pay-per-view content.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 2  ${
                    faq === "2" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("2");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      Do I need to download anything like an app to watch?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "2" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      No, you can stream it directly from any internet enabled
                      device by logging into your account at combatlabs.tv
                      navigating to the content you have purchased/subscribed to
                      and click play.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 3  ${
                    faq === "3" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("3");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      Can I watch a PPV on a second device / multiple devices at
                      the same time?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "3" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      Yes, you can watch on more than one device (up to two) at
                      the same time as long as you are logged in with your
                      account and the devices are on the same network and IP
                      address. Be aware that it will require 2 times the
                      internet bandwidth and that you cannot share your login
                      and watch at the same time with someone who is in a
                      different network/location. Just sign in with your email
                      and password in the Combat Labs app or from the top right
                      corner on combatlabs.tv Then navigate to the show you want
                      to watch - it will show as already purchased.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 4 ${
                    faq === "4" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("4");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      When is a replay available to watch?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "4" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      Immediately after the live broadcast ends it is available
                      for replay, your PPV access only allows you to watch the
                      event for 7 days from when it is live-streamed.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 5 ${
                    faq === "5" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("5");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      Is a replay included in my purchase?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "5" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      Yes, replays are included in the order. Replay windows
                      vary for different events, usually only up to 7 days from
                      the date of event.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 6 ${
                    faq === "6" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("6");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      How do I cancel my subscription?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "6" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      You can cancel and manage your subscription and profile by
                      clicking on the top right icon -- Manage Profile --Cancel
                      Subscription - you will no longer be billed and your
                      access to content will be removed once your subscription
                      period expires.{" "}
                    </p>
                  </div>
                </div>

                <div
                  className={`iq-accordion-block 7 ${
                    faq === "7" ? "iq-active" : ""
                  }`}
                  onClick={() => {
                    setfaq("7");
                  }}
                >
                  <div className="iq-accordion-title">
                    <div className="iq-icon-right">
                      <i aria-hidden="true" className="fa fa-minus active"></i>
                      <i aria-hidden="true" className="fa fa-plus inactive"></i>
                    </div>
                    <h4 className="mb-0 accordion-title iq-heading-title">
                      How to get a refund for a purchased membership?
                    </h4>
                  </div>
                  <div
                    className={`iq-accordion-details ${
                      faq === "7" ? "d-block" : "d-none"
                    }`}
                  >
                    <p className="iq-content-text">
                      {" "}
                      As outlined in our Terms and Conditions; memberships are
                      non-refundable unless they are duplicates or have been
                      purchased by accident and have not been used in any way.
                      Membership refund requests on these grounds need to be
                      sent to sales@combatlabs.io no later than 48 hours after a
                      membership has been purchased.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 m-3">
                  <Link
                    to="mailto:sales@combatlabs.io"
                    className="btn btn-primary1"
                  >
                    I Still Need Help?
                  </Link>
                  {/* <i className="btn btn-primary1">I Still Need Help?</i> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default FAQ;
