import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import SubscriptionService from "../services/subscription.service";

const sub = JSON.parse(localStorage.getItem("sub"));

export const createSubscription = createAsyncThunk(
  "sub/create",
  async ({ input }, thunkAPI) => {
    try {
      const data = await SubscriptionService.create(input);
      //console.log(data);
      return { sub: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "sub/update",
  async ({ input }, thunkAPI) => {
    try {
      const data = await SubscriptionService.update(input);
      //console.log(data);
      return { sub: { isActive: input.isActive } };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const findSubscription = createAsyncThunk(
  "sub/find",
  async (input, thunkAPI) => {
    //console.log(input)
    try {
      const data = await SubscriptionService.find(input);
      return { sub: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeSubscription = createAsyncThunk(
  "sub/remove",
  async (thunkAPI) => {
    //console.log("remove");
  }
);

const initialState = sub
  ? { isActive: true, sub }
  : { isActive: false, sub: null };

const subSlice = createSlice({
  name: "sub",
  initialState,
  extraReducers: {
    [createSubscription.fulfilled]: (state, action) => {
      state.isActive = true;
      state.sub = action.payload.sub;
    },
    [createSubscription.rejected]: (state, action) => {
      state.isActive = false;
    },
    [updateSubscription.fulfilled]: (state, action) => {
      state.isActive = true;
      state.sub = action.payload.sub;
    },
    [updateSubscription.rejected]: (state, action) => {
      state.isActive = false;
      state.sub = null;
    },
    [findSubscription.fulfilled]: (state, action) => {
      state.isActive = true;
      state.sub = action.payload.sub;
    },
    [findSubscription.rejected]: (state, action) => {
      state.isActive = false;
      state.sub = null;
    },
    [removeSubscription.fulfilled]: (state, action) => {
      state.isActive = false;
      state.sub = null;
    },
    [removeSubscription.rejected]: (state, action) => {
      state.isActive = false;
      state.sub = null;
    },
  },
});

const { reducer } = subSlice;
export default reducer;
