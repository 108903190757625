//import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const { REACT_APP_ENV } = process.env;

const InitializeGoogleAnalytics = () => {
  // Initialize UA
  // ReactGA.initialize("UA-XXXXXXXX-X");
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize("G-BY05D5W2HR");

  //console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (category, action, label) => {
  // Send UA Event
  /* ReactGA.event({
    category: category,
    action: action,
    label: label,
  });*/
  // Send GA4 Event
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

const TrackPageView = (hitType, page, title) => {
  // Send pageview with a custom path
  ReactGA4.send({ hitType: hitType, page: page, title: title });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackPageView };
