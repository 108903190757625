import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import FormattedDate from '../../../components/utils/FormattedDate';
import PhysicalTicket from '../../../services/physicalticket.service';
const { REACT_APP_DOMAIN } = process.env;

function ViewTicket() {
  const location = useLocation();
  const [ticketDetail, setTicketData] = useState(null);
  const { ticketData } = location.state || {};
  const [ticketTypeDetails, setTicketTypeDetails] = useState(null);
  const [totalNoTicket, setTotalNoTicket] = useState(0);
  const [totalTicketPrice, setTotalTicketPrice] = useState(0);
  const data = ticketData;

  const getTicketType = async () => {
    const ticketData = { ticketId: data.id };
    let res = await PhysicalTicket.purchasedticketlist(ticketData);
    //console.log("Ticket Details", res);
    setTicketTypeDetails(res);
    setTotalNoTicket(
      res.reduce((acc, item) => acc + Number(item.purchase_qty), 0)
    );

    setTotalTicketPrice(
      res.reduce((acc, item) => acc + item.purchase_qty * item.priceToCustomer, 0)
    );
  };

  useEffect(() => {
    getTicketType();
    const qrData = REACT_APP_DOMAIN +"tickets/"+data.id;
    setTicketData(qrData);
  }, []);

  return (
    <main className="main-content">
      <div className="mandalorian">
        <div className="container mt-5">
          <div className="card">
            <div className="card-header bg-primary text-white text-center">
              <h4 className="card-title">Event Ticket Details</h4>
            </div>
            <div className="card-body bg-light text-black">
              <div className="row">
                <div class="img-box col-sm-3 ">
                  <img
                    src={data.event.event_promotional_photo}
                    class="cl-recent-slide-image"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="col-sm-6">
                  <h5 className="card-title text-black">{data.event.name}</h5>
                  <p className="card-text text-black">
                    <FormattedDate
                      date={data.event.event_date}
                      className="text-black"
                    />
                  </p>
                  {ticketData?.event?.location && (
                  <p className="card-text">
                    Venue : {ticketData?.event?.location},{' '}
                    {ticketData?.event?.city}, {ticketData?.event?.state}
                  </p>
                  )}
                  
                  <p className="card-text">{totalNoTicket} Ticket(s)</p>
                  {ticketTypeDetails && (
                    <div className="ticket-type-details">
                      <ul>
                        {ticketTypeDetails.map((item, index) => (
                          <li key={index}>
                            {item.eventtickettype.ticketSubType}:{' '}
                            {item.purchase_qty}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <p>Amount Paid: ${totalTicketPrice.toFixed(2)}</p>{' '}
                </div>

                <div className="col-sm-3 qrcode  text-center">
                  <QRCode value={ticketDetail} size={200} level={'H'} />
                  <p className="card-text mt-3">Booking ID: {ticketData?.id}</p>
                </div>
              </div>
            </div>
            <div className="card-footer">
              Thank you for your purchase!
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ViewTicket;
