import React, { useState, useEffect } from 'react';
import { Container ,Row, Col, Table } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";

import user from '../../../assets/images/user/user.jpg';
import figtherpic from '../../../assets/images/user/logo-placeholder-big.jpg';
import ContenderService from '../../../services/contender.service';
import BoutService from '../../../services/bout.service';
import InitializeGoogleAnalytics, { TrackPageView } from '../../../components/utils/googleanalytics';
import Upcoming from "../../../components/event/Upcoming";

const FighterDetails = () => {
  
    const [data, setData] = useState();
    const [upcomingBouts, setUpcomingBouts] = useState();
    const [recentBouts, setRecentBouts] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
  
    const fighterDetails = async () => {

        let res = await ContenderService.find(id);
        setData(res[0]);
        setLoading(false);
    }

    const listUpcomingBouts = async () => {
        let res = await BoutService.listUpcomingContenderBouts(id);  
        setUpcomingBouts(res);
    }

    const listRecentBouts = async () => { 
        let res = await BoutService.listRecentContenderBouts(id);
        setRecentBouts(res);
    }
  
    useEffect(() => {
        setLoading(false);
        fighterDetails();
        listUpcomingBouts();
        listRecentBouts();
        InitializeGoogleAnalytics();
        TrackPageView('pageview',window.location.pathname + window.location.search,'Combat Labs - Fighter Details');
    }, [id]);

    return (
      <>
        <div class="main-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="trending-info mt-4 pt-0 pb-4">
                <div class="row">
                  <div class="col-md-9 col-12  mb-auto">
                    <div class="d-md-flex">
                      <h3
                        class="trending-text big-title text-uppercase mt-0 fadeInLeft animated"
                        data-animation-in="fadeInLeft"
                        data-delay-in="0.6"
                      >
                        {data?.user.firstname} {data?.user.lastname}
                      </h3>
                    </div>

                    {/* <div className="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4">
                      <span class="badge badge-secondary font-size-16">
                        Combat Type:
                      </span>
                      <span className="ml-3 font-Weight-500 genres-info">
                        
                      </span>
                    </div>
                    <div className="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4">
                      <span class="badge badge-secondary font-size-16">
                        Location: {data?.user.city} {data?.user.state}
                      </span>
                      <span className="ml-3 font-Weight-500 genres-info">
                       
                      </span>
                    </div> */}
                  </div>
                  <div class="trailor-video col-md-3 col-12 mt-lg-0 mt-4 mb-md-0 mb-1 text-lg-right">
                    <img
                      width="1920"
                      height="1080"
                       src={figtherpic}
                      class="attachment-medium-large size-medium-large wp-post-image"
                      alt=""
                      loading="lazy"
                      sizes="(min-width: 960px) 75vw, 100vw"
                    />
                  </div>
                </div>
              </div>
              <div
                id="dectripton-1"
                class="tab-pane fade active show"
                role="tabpanel"
              >
                <div class="description-content">
                  <p></p>
                </div>
              </div>
              <div class="streamit-content-details trending-info g-border iq-rtl-direction">
                {upcomingBouts?.length > 0 && (
                <>
                <div class="mt-3 my-3">
                <h5 className="main-title">Upcoming Events</h5>
                </div>
                <Table class="movie-sources sources-table" variant="dark">
                  <thead class="trending-pills">
                    <tr>
                      <th class="genres-table-heading">Links</th>
                      <th class="genres-table-heading">Event Name</th>
                      <th class="genres-table-heading">Combat Types</th>
                      <th class="genres-table-heading">Date</th>
                      <th class="genres-table-heading">Location</th>
                    </tr>
                  </thead>
                  <tbody class="trending-pills">
                   
                  {upcomingBouts?.map((data, index) => (
                      <tr>
                        <td>
                          <a
                            href={"/eventdetails/" + data.event.id}
                            class="play-source movie-play-source btn-hover iq-button"
                          >
                            <i class="ri-play-fill"></i>
                            Play Now
                          </a>
                        </td>
                        <td>{data.event.name}</td>
                        <td>
                          {data?.event.combattypes
                            ?.map((combattype) => combattype.name)
                            .join(", ")}
                        </td>
                        <td>
                          {new Date(data?.event.event_date).toLocaleDateString()}
                        </td>
                        <td>
                          {data?.event.state}, {data?.event.country}{" "}
                        </td>
                      </tr>
                    ))}                 
                  </tbody>
                </Table>
                </>
                )}
                {recentBouts?.length > 0 && (
                <>
                <div class="mt-5 my-3">
                <h5 className="main-title">Recent Events</h5>
                </div>
                <Table class="movie-sources sources-table" variant="dark">
                  <thead class="trending-pills">
                    <tr>
                      <th class="genres-table-heading">Links</th>
                      <th class="genres-table-heading">Event Name</th>
                      <th class="genres-table-heading">Combat Types</th>
                      <th class="genres-table-heading">Date</th>
                      <th class="genres-table-heading">Location</th>
                    </tr>
                  </thead>
                  <tbody class="trending-pills">
                   
                    {recentBouts?.map((data, index) => (
                      <tr>
                        <td>
                          <a
                            href={"/eventdetails/" + data.event.id}
                            class="play-source movie-play-source btn-hover iq-button"
                          >
                            <i class="ri-play-fill"></i>
                            Play Now
                          </a>
                        </td>
                        <td>{data.event.name}</td>
                        <td>
                          {data?.event.combattypes
                            ?.map((combattype) => combattype.name)
                            .join(", ")}
                        </td>
                        <td>
                          {new Date(data?.event.event_date).toLocaleDateString()}
                        </td>
                        <td>
                          {data?.event.state}, {data?.event.country}{" "}
                        </td>
                      </tr>
                    ))}
                 
                  </tbody>
                </Table>
                </>
                )}
              </div>
            </div>
          </div>
        </div>

        
      </div>

      </>
   )
}

export default FighterDetails