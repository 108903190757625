import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Container, Card } from "react-bootstrap";

const Zoom = ({ src, alt }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoom, setZoom] = useState(1); // 1 means 100% zoom

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const toggleZoom = () => {
    setZoom(zoom => zoom === 1 ? 2 : 1); // Toggle between 100% and 200% zoom
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const zoomStyle = {
    transform: `scale(${zoom})`,
    transition: 'transform 0.2s', // Smooth transition for zoom effect
    cursor: 'pointer' // Change cursor on hover to indicate the image is clickable
  };

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(5px)', // Apply blur effect to the background
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isModalOpen]);

  return (
    <>

      <Row>
        <Col sm='12'>
          <div>
            <img
              src={src}
              alt={alt}
              onClick={openModal}
              style={{ cursor: 'pointer', width: "300px" }}
            />
          </div>
          <div className='p-2'>Note: Click on seatmap image to zoom</div>

        </Col>
      </Row>

      {isModalOpen && (
        <div style={modalStyle} onClick={handleClickOutside}>
          {/* Ensure the container for the close button is positioned relatively */}
          <div style={{ position: 'relative', width: 'fit-content', height: 'fit-content' }}>
            {/* Position the close button absolutely within the relative container */}
            <span style={{ position: 'absolute', top: 0, right: 0, fontSize: '25px', cursor: 'pointer', zIndex: 10 }} onClick={closeModal}>&times;</span>
            <img
              src={src}
              alt={alt}
              style={zoomStyle}
              onClick={toggleZoom}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Zoom;
