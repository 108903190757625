import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import UserEventService from '../../../services/userevent.service';
import { useSelector } from 'react-redux';
import ticketService from '../../../services/ticket.service';
import InitializeGoogleAnalytics, {
  TrackPageView,
} from '../../../components/utils/googleanalytics';
import { TAG } from '../../../constants/enum';
import FormattedDate from '../../../components/utils/FormattedDate';
import { QRCode } from 'qrcode.react';
import ViewTicket from './ticketdetail';


// install Swiper modules
SwiperCore.use([Navigation]);

const UserEvent = () => {
  let navigate = useNavigate();

  const [myList, setMyList] = useState([]);
  const { sub: currentSubscription } = useSelector((state) => state.sub);
  const [recentTickets, setRecentTickets] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [showTicket, setShowTicket] = useState(false);
  if (!currentUser) {
    navigate('/login');
  }

  const reShapeEvents = (evts, tkts) => {
    for (let i = 0; i < evts.length; i++) {
      evts[i].isPurchased = false;
      //    evts[i].isShow = true;
      for (let j = 0; j < tkts.length; j++) {
        if (evts[i].id == tkts[j].eventId) {
          evts[i].isPurchased = true;
          //      evts[i].show = false;
          break;
        }
      }
    }

    return evts;
  };

  const mylist = async () => {
    const data = {
      userId: currentUser.user?.id,
    };

    const tkts = await ticketService.findTicketByUser(data);
    setRecentTickets(tkts);

    //console.log('Recent Ticket', tkts);

    const myEvents = await UserEventService.list(data);
    //  console.log(reShapeEvents(myEvents?.events, tkts));
    setMyList(reShapeEvents(myEvents?.events, tkts));
  };

  useEffect(() => {
    mylist();
    //recentPurchase();
    InitializeGoogleAnalytics();
    // TrackGoogleAnalyticsEvent('Footer', 'FAQ', 'FAQ');
    TrackPageView(
      'pageview',
      window.location.pathname + window.location.search,
      'Combat Labs - MyList'
    );
  }, []);

  const navigateToViewTicket = (data) => {
    navigate('/mylist/viewticket', {
      state: { ticketData: data},
    });
  };


  const navigateToPlayEvent = (data,type) => {
    let url='/' +data.event.promotion?.subdomain +'/' +data.event.slug +'/ppv';
    navigate(url, {
      state: { ticketData: data,type: type },
    });
  };
  

  return (
    <div id="main" class="site-main watchlist-contens">
      <div class="container-fluid">
        {recentTickets?.length > 0 && (
          <section class="mb-2">
            <div class="iq-main-header d-flex align-items-center justify-content-between mt-5 mt-lg-0">
              <h4 class="main-title">Recent Purchase</h4>
            </div>
            <ul class="row list-inline mb-0 iq-rtl-direction">
              {recentTickets?.map((data, index) => (
                <li class="slide-item col-lg-3 col-md-4 col-6 mb-4">
                  <div
                    class="block-images position-relative  watchlist-first"
                    style={{ width: '65%' }}
                  >
                    <div class="img-box">
                      <img
                        src={data.event?.event_promotional_photo}
                        class="cl-recent-slide-image"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div class="block-description">
                      <h6 class="iq-title text-left">
                        {/* <a href="../html/genres-detail.html"> */}
                        {data.event?.name}
                        {/* </a> */}
                      </h6>
                      <div
                        className="trending-list"
                        data-wp_object-in="fadeInUp"
                        data-delay-in="1.2"
                      >
                        <div className="iq-secondary title starring">
                          <span className="text-body">
                            {data?.event?.combattypes
                              ?.map((combattype) => combattype.name)
                              .join(', ')}
                          </span>
                        </div>
                      </div>
                      <div className="iq-secondary title tag">
                        Date & Time:
                        <FormattedDate date={data?.event?.event_date} />
                      </div>
                      {/* <div class="movie-time d-flex align-items-center my-2">
                                        <span class="text-white">1hr : 45mins</span>
                                    </div> */}

                      <div
                        class="hover-buttons text-left"
                        style={{ width: '100%' }}
                      >
                        {data.type === 'In-Person' ? (
                          <button
                            type="reset"
                            class="btn btn-hover btn-primary1 m-2 btn btn-primary"
                            style={{ 'font-size': '12px' }}
                            onClick={() => navigateToViewTicket(data)}
                          >
                            <i class="fa fa-ticket mr-1" aria-hidden="true"></i>
                            View Ticket
                          </button>
                        ) : (
                          <button
                            type="summit"
                            class="btn btn-hover btn-primary1 btn-primary"
                            style={{ 'font-size': '12px' }}
                            onClick={() => navigateToPlayEvent(data,"Pay Per View")}
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"></i>
                            Play Now
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="block-social-info">
                      <ul class="list-inline p-0 m-0 music-play-lists">
                        {/* <li class="share">
                                                    <span><i class="ri-share-fill"></i></span>
                                                    <div class="share-box">
                                                        <div class="d-flex align-items-center">
                                                            <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-facebook-fill"></i></a>
                                                            <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                                rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-twitter-fill"></i></a>
                                                            <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                        </div>
                                                    </div>
                                                </li> */}
                        {/* <li>
                                                    <span><i class="ri-heart-fill"></i></span>
                                                    <span class="count-box">+3</span>
                                                </li>
                                                <li><span><i class="ri-add-line"></i></span></li> */}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/* <button
              class="btn btn-hover hide-me"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample3"
            >
              <span class="genres-btn">LOAD MORE</span>
            </button> */}
            {/* <div class="collapse" id="collapseExample3">
                        <ul class=" row list-inline  mb-0 iq-rtl-direction ">
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/09.jpg" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">
                                            The Illusion</a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">1hr : 22mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+2</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/36.png" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">King of Jungle </a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">1hr : 35mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+0</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/34.png" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">
                                            The Dinosaur </a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">1hr : 44mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+3</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/04.jpg" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">
                                            Jumbo Queen</a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">2hr : 12mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+4</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/33.jpg" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">Black Pearl</a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">
                                                1hr : 24mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+0</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6  mb-4">

                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/01.jpg" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">
                                            Zombie Island</a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">1hr : 48mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+2</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>

                            </li>
                            <li class="slide-item col-lg-3 col-md-4 col-sm-6 mb-4">
                                <div class="block-images position-relative">
                                    <div class="img-box">
                                        <img src="images/genre/09.jpg" class="img-fluid" alt="" loading="lazy" />
                                    </div>
                                    <div class="block-description">
                                        <h6 class="iq-title"><a href="../html/genres-detail.html">
                                            The Illusion</a></h6>
                                        <div class="movie-time d-flex align-items-center my-2">
                                            <span class="text-white">1hr : 22mins</span>
                                        </div>
                                        <div class="hover-buttons">
                                            <a href="../html/genres-detail.html" role="button" class="btn btn-hover"><i class="fa fa-play mr-1"
                                                aria-hidden="true"></i>
                                                Play Now</a>
                                        </div>
                                    </div>
                                    <div class="block-social-info">
                                        <ul class="list-inline p-0 m-0 music-play-lists">
                                            <li class="share">
                                                <span><i class="ri-share-fill"></i></span>
                                                <div class="share-box">
                                                    <div class="d-flex align-items-center">
                                                        <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-facebook-fill"></i></a>
                                                        <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                            rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                class="ri-twitter-fill"></i></a>
                                                        <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                            class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span><i class="ri-heart-fill"></i></span>
                                                <span class="count-box">+2</span>
                                            </li>
                                            <li><span><i class="ri-add-line"></i></span></li>

                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> */}
          </section>
        )}

        {myList?.length > 0 && (
          <section class="mb-2">
            <div class="iq-main-header d-flex align-items-center justify-content-between mt-5 mt-lg-0">
              <h4 class="main-title">Favorites</h4>
            </div>
            <ul class="row list-inline mb-0 iq-rtl-direction">
              {myList?.map((data, index) => (
                <li class="slide-item col-lg-3 col-md-4 col-6 mb-4">
                  <Link
                    to={{
                      pathname:
                        '/' + data.promotion?.subdomain + '/' + data.slug,
                      state: {
                        item: data.event,
                      },
                    }}
                  >
                    <div class="block-images position-relative  watchlist-first">
                      <div class="img-box">
                        <img
                          src={data?.event_promotional_photo}
                          class="cl-fav-slide-image"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="block-description" style={{ width: '280px' }}>
                        <h6 class="iq-title text-left">{data?.name}</h6>
                        {/* <div class="movie-time d-flex align-items-center my-2">
                                        <span class="text-white">1hr : 45mins</span>
                                    </div> */}
                        <div
                          className="trending-list"
                          data-wp_object-in="fadeInUp"
                          data-delay-in="1.2"
                        >
                          <div className="iq-secondary title starring">
                            <span className="text-body">
                              {data?.combattypes
                                ?.map((combattype) => combattype.name)
                                .join(', ')}
                            </span>
                          </div>
                        </div>
                        <div className="iq-secondary title tag">
                          Date & Time: <FormattedDate date={data.event_date} />
                        </div>
                        {data.tags && data.tags.length > 0 && (
                          <div>
                            {data.tags.map((tag, tagIndex) => (
                              <span key={tagIndex} className="tag">
                                <div className="hover-buttons">
                                <i className={`fa ${
                                      tag.name === TAG.PPV ? 'fa-play' : tag.name === TAG.IP ? 'fa-ticket' : ''
                                    } mr-1`}
                                    aria-hidden='true'
                                  ></i>

                                  {tag.name === TAG.PPV && (
                                    <Link
                                      to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ppv'} 
                                      state={{ item: data, type: 'Pay Per View' }}
                                    >
                                      {data.isPurchased ? 'Play Now' : `Buy $`+ data.price}
                                    </Link>
                                  )}

                                  {tag.name === TAG.IP && (
                                    <Link
                                      to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ticket'}
                                      state={{ item: data, type: 'In-Person' }}
                                    >
                                      Buy Ticket
                                    </Link>
                                  )}

                                  {tag.name === TAG.SUB && (
                                    <Link
                                      to={'/' + data.promotion?.subdomain + '/' + data.slug + '/ppv'}
                                      state={{ item: data, type: 'Subscription' }}
                                    >
                                      {
                                        currentUser && currentSubscription && currentSubscription.isActive ? (
                                          <>
                                            <i className="fa fa-play" aria-hidden="true"></i> Play Now
                                          </>
                                        ) : (
                                          <>
                                            <i className="fa fa-bell" aria-hidden="true"></i> Subscribe Now
                                          </>
                                        )
                                      }
                                    </Link>
                                    )}
                                </div>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                      <div class="block-social-info">
                        <ul class="list-inline p-0 m-0 music-play-lists">
                          {/* <li class="share">
                                                    <span><i class="ri-share-fill"></i></span>
                                                    <div class="share-box">
                                                        <div class="d-flex align-items-center">
                                                            <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-facebook-fill"></i></a>
                                                            <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank"
                                                                rel="noopener noreferrer" class="share-ico" tabindex="0"><i
                                                                    class="ri-twitter-fill"></i></a>
                                                            <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/"
                                                                class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                                        </div>
                                                    </div>
                                                </li> */}
                          {/* <li>
                                                    <span><i class="ri-heart-fill"></i></span>
                                                    <span class="count-box">+3</span>
                                                </li>
                                                <li><span><i class="ri-add-line"></i></span></li> */}
                        </ul>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <button
              class="btn btn-hover hide-me"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample3"
            >
              <span class="genres-btn">LOAD MORE</span>
            </button>
          </section>
        )}
      </div>
    </div>
  );
};
export default UserEvent;
