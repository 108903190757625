import axios from "axios";
import authHeader from "./auth-header";
import EventBus from "../common/EventBus";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL + "bouts/";

const listContendersByEvent = async ( data ) => {
    return axios.post(API_URL + "find/event/" , data , {headers: {"Access-Control-Allow-Origin": "*"}})
      .then((response) => {
        return response.data;
    })  .catch(function (error) {
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }

        if (error.response && error.response.status === 500) {
          EventBus.dispatch("error500");
        }
    });
};

const listContenderBouts = async ( id ) => {
  return axios.get(API_URL + "find/contender/"+id , {headers: {"Access-Control-Allow-Origin": "*"}})
    .then((response) => {
      return response.data;
  })  .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
  });
};

const listUpcomingContenderBouts = async ( id ) => {
  return axios.get(API_URL + "find/contender/upcoming/"+id , {headers: {"Access-Control-Allow-Origin": "*"}})
    .then((response) => {
      return response.data;
  })  .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
  });
};

const listRecentContenderBouts = async ( id ) => {
  return axios.get(API_URL + "find/contender/recent/"+id , {headers: {"Access-Control-Allow-Origin": "*"}})
    .then((response) => {
      return response.data;
  })  .catch(function (error) {
      if (error.response && error.response.status === 403) {
        EventBus.dispatch("logout");
      }

      if (error.response && error.response.status === 500) {
        EventBus.dispatch("error500");
      }
  });
};

export default {
    listContendersByEvent,
    listContenderBouts,
    listUpcomingContenderBouts,
    listRecentContenderBouts
}