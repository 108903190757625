import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate, Link, useParams } from 'react-router-dom';
import authService from "../../../../services/auth.service";
import { isExpired, decodeToken } from "react-jwt";
import { useSelector } from "react-redux";
const { REACT_APP_DOMAIN } = process.env;

const ForgotPassword = (props) => {
    let navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const { token } = useParams();
    const [jwtDecode, setJwtDecode] = useState(decodeToken(token));
    const [isTokenExpired, setIsTokenExpired] = useState(isExpired(token));
    const [matchPassword, setMatchPassword] = useState(true);
    const [resetSuccess, setResetSuccess] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    const handleResetPassword = (e) => {
        setRepassword(e);
        if (password !== e) {
            setMatchPassword(false);
        } else {
            setMatchPassword(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!matchPassword) 
            return;
        const userData = {
            password: password,
            repassword: repassword,
            username: jwtDecode.username,
            token: token
        };
        authService.resetpassword(userData)
            .then((response) => {
                setResetSuccess(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    return (
        <>
            <section className="sign-in-page">
                <Container>
                    {!isTokenExpired ? (
                        <Row className="row justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">
                                <div className="sign-user_card ">
                                    <div className="sign-in-page-data">
                                        <div className="sign-in-from w-100 m-auto">
                                            <h3 className="mb-3 text-center">Reset Password</h3>
                                            {!resetSuccess ? (
                                                <>
                                                    <p className="text-body">Enter your email address and we'll send you an email with instructions to reset your password.</p>        
                                                    <Form className="mt-4">
                                                        <div className="form-group">
                                                            <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control mb-0" id="exampleInputEmail2" placeholder="Password" autoComplete="off" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="password" onChange={(e) => handleResetPassword(e.target.value)} className="form-control mb-0" id="exampleInputEmail2" placeholder="Confirm Password" autoComplete="off" required />
                                                            {!matchPassword && (
                                                                <p className="text-danger">Confirm Password does not match</p>
                                                            )}
                                                        </div>
                                                        <div className="sign-info">
                                                            <Button type='submit' className="btn btn-hover btn-primary1" onClick={(e) => handleSubmit(e)}>Reset</Button>
                                                        </div>
                                                    </Form>
                                                </>
                                            ) : (
                                                <p className="text-success text-center">Password reset successfully</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Row className="row justify-content-center align-items-center height-self-center">
                            <Col lg="5" md="12" className="align-self-center">
                                <p className="text-body">Oops! Looks like your reset password link has expired. Please request a new one.</p>
                                <p><a href={`${REACT_APP_DOMAIN}/embed/forgot-password`}><u>Click Here</u></a></p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section>
        </>
    );
}

export default ForgotPassword;