import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Col, Form, Row, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";
import AlertNotification from "../AlertNotification/AlertNotification";
import { alertActions } from "../../slices/alert";
import PhysicalTicket from "../../services/physicalticket.service";
import PaymentService from "../../services/payment.service";

const CheckoutForm = (props) => {
  //console.log("IN Checkout", props);

  const { user: currentUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  let dispatch = useDispatch();
  const payType = props.payType ? props.payType : "";
  const paymentElementOptions = {
    layout: "tabs",
    theme: "night",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#30313d",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#30313d",
      textColor: "white",
      borderColor: "#000000",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "white";

      return { ...provided, opacity, transition, color };
    },
  };

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const selectedFighter = props.contenders?.find(({ value }) => value.toString() === props.fighterId)?.value || null;
  const [contender, setContender] = useState(selectedFighter);
  const [validated, setValidated] = useState(false);
  const [grandTotal, setGrandTotal] = useState("");

  const physicalTicketsData = async (phyaicalTickets) => {
    
    const physicalTicketsData = phyaicalTickets.map((ticket) => ({
      eventId: Number(ticket.eventId),
      ticketId: null,
      userId: currentUser.user?.id,
      ticketTypeId: Number(ticket.ticketTypeId),
      purchase_qty: Number(ticket.purchase_qty),
      price: ticket.price,
      priceToCustomer:  (parseFloat((4 / 100) * ticket.price) + parseFloat(ticket.price) + parseFloat(2.5)).toFixed(2),
    }));

    const responds = await PhysicalTicket.create(physicalTicketsData);
    //console.log("PhysicalTicketInsertData", responds);
  };
  useEffect(() => {
  
      
    if (props.payType === "PT Event") {
      const total = props.data.reduce(
        (acc, item) =>
          acc +
          parseFloat(
            item.purchase_qty * ((
              parseFloat(item.price) + 
              (parseFloat(item.price) * 0.04) + 
              2.5
            ))
          ),
        0
      ).toFixed(2);
      setGrandTotal(total);
      physicalTicketsData(props.data);
    }

    if(selectedFighter) updateStripeContent(selectedFighter);
  }, [props.data, props.payType]); // Add dependencies here

  const handleError = (error) => {
    setLoading(false);
    if(error) {
      setErrorMessage(error.message);
      dispatch(alertActions.error(error.message));
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    dispatch(alertActions.clear());

    event.preventDefault();
    const form = event.currentTarget;

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError || form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      handleError(submitError);
      return;
    }

    setValidated(true);

    let returnURL = "http://" + window.location.host + "/status";

    if (!props.sub && props.event) {
      returnURL =
        returnURL +
        "?&eventId=" +
        props.event?.id +
        "&type=" +
        props.data?.type +
        "&fighterId=" +
        contender +
        "&payType=" +
        payType +
        "&amount=" +
        grandTotal +
        "&consent=true";
      if (props.embed) returnURL = returnURL + "&embed=true";
    }

    if (props.sub && props.event) {
      returnURL =
        returnURL +
        "?&eventId=" +
        props.event?.id +
        "&subId=" +
        props.sub +
        "&type=" +
        props.data?.type +
        "&consent=true";
    }

    if (props.sub && !props.event) {
      returnURL =
        returnURL +
        "?&subId=" +
        props.sub +
        "&type=" +
        props.data?.type +
        "&consent=true";
    }

    // Confirm the Subscription using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnURL,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const updateStripeContent = async (fighterId) => {
    const updatedStripChargeData = {
      ...props.stripChargeData,
      metadata: {
        ...props.stripChargeData.metadata,
        fighterId: fighterId,
      },
    };
    const updatedData={
      paymentIntentId:props.paymentId,
      metadata:updatedStripChargeData.metadata
    }
    await PaymentService.updateCharge(updatedData);
  }

  const handleChange = (newValue, actionMeta) => {
    setValidated(false);
    setContender(newValue.value);
    updateStripeContent(newValue.value);
  };

  const handleCheckboxcChange = (e) => {
    setValidated(false);
  };

  dispatch(alertActions.clear());

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col lg='6'>
                <img src={logo} className='img-fluid' alt='' />
              </Col>
              <Col lg='6' className='align-self-center'>
                <h4 className='mb-0 float-right'>Invoice</h4>
              </Col>
              <Col sm='12'>
                <hr className='mt-3' />
                <h5 className='mb-0'>Hello, {currentUser.user?.fullName}</h5>
                <p>
                  Combat Labs is proud to provide a special selection of premium
                  combat events from MMA, Boxing, BJJ & many other combative
                  sports! Check out our subscription for unlimited on-demand
                  access to our extensive library of content.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <h5>Order Summary</h5>
                <div className='table-responsive-sm'>
                  {payType === "PT Event" ? (
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th className='text-center' scope='col'>
                            #
                          </th>
                          <th scope='col'>Item</th>
                          <th className='text-center' scope='col'>
                            Ticket Type
                          </th>
                          <th className='text-center' scope='col'>
                            Quantity
                          </th>
                          <th className='text-center' scope='col'>
                            Price
                          </th>
                          <th className='text-center' scope='col'>
                            Total
                          </th>
                        </tr>
                      </thead>
                      {props.data && props.data.length > 0 && (
                        <tbody>
                          {props.data.map((item, index) => (
                            <tr key={index}>
                              <th className='text-center' scope='row'>
                                {index + 1}
                              </th>
                              <td>
                                <h6 className='mb-0'>
                                  {props.event.name}
                                </h6>

                                {item.type === "PT Event" &&
                                  props.event.city &&
                                  props.event.state &&
                                  props.event.country && (
                                    <p className='mb-0'>
                                      {props.event.city}, {props.event.state},{" "}
                                      {props.event.country}
                                    </p>
                                  )}
                              </td>
                              <td className='text-center'>
                                {item.ticketTypeName}
                              </td>
                              <td className='text-center'>
                                {item.purchase_qty}
                              </td>
                              <td className='text-center'>
                                ${(
                                  parseFloat(item.price) + // Ensure item.price is treated as a number
                                  (parseFloat(item.price) * 0.04) + // 4% fee
                                  2.5 // Additional $2.5
                                ).toFixed(2)} USD
                              </td>

                              <td className='text-center'>
                                <b>
                                  ${(item.purchase_qty * ((
                                  parseFloat(item.price) + // Ensure item.price is treated as a number
                                  (parseFloat(item.price) * 0.04) + // 4% fee
                                  2.5 // Additional $2.5
                                ))).toFixed(2)}{" "}
                                  USD
                                </b>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan='5' className='text-right'>
                              <b>Grand Total</b>
                            </td>
                            <td className='text-center'>
                              <b>
                                $
                                {(
                                  props.data.reduce(
                                    (acc, item) =>
                                      acc +
                                      parseFloat(
                                        item.purchase_qty * ((
                                          parseFloat(item.price) + // Ensure item.price is treated as a number
                                          (parseFloat(item.price) * 0.04) + // 4% fee
                                          2.5 // Additional $2.5
                                        ))
                                      ),
                                    0
                                  ) 
                                ) // Add 4 to the total sum
                                  .toFixed(2)}{" "}
                                USD
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  ) : (
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th className='text-center' scope='col'>
                            #
                          </th>
                          <th scope='col'>Item</th>
                          <th className='text-center' scope='col'>
                            Quantity
                          </th>
                          <th className='text-center' scope='col'>
                            Price
                          </th>
                          <th className='text-center' scope='col'>
                            Total
                          </th>
                        </tr>
                      </thead>
                      {props.data && (
                        <tbody>
                          <tr>
                            <th className='text-center' scope='row'>
                              1
                            </th>
                            <td>
                              {props.data?.type === "monthly" && (
                                <h6 className='mb-0'>Monthly Subscription</h6>
                              )}
                              {props.data?.type === "yearly" && (
                                <h6 className='mb-0'>Yearly Subscription</h6>
                              )}
                              {props.data?.type === "PPV Event" && (
                                <h6 className='mb-0'>
                                  PPV Event - {props.event.name}{" "}
                                </h6>
                              )}
                              {props.data?.type === "PPV Event" &&
                                props.event.city &&
                                props.event.state &&
                                props.event.country && (
                                  <p className='mb-0'>
                                    {props.event.city}, {props.event.state},{" "}
                                    {props.event.country}
                                  </p>
                                )}
                              {/* <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                            </td>
                            <td className='text-center'>1</td>
                            <td className='text-center'>
                              ${props.data?.priceRate} USD
                            </td>
                            <td className='text-center'>
                              <b>${props.data?.priceRate} USD</b>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan='4' className='text-right'>
                              <b>Grand Total</b>
                            </td>
                            <td className='text-center'>
                              <b>${props.data?.priceRate} USD</b>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <PaymentElement options={paymentElementOptions} />
              </Col>
            </Row>
            {props.contenders?.length > 0 && (
              <Row>
                <Col sm='12' className='mt-3'>
                  <Form.Group>
                    {/* <Form.Label>Give Referral To Contender</Form.Label> */}
                    <Select
                      styles={customStyles}
                      name='contender'
                      defaultValue={props.contenders.find(({ value }) => value.toString() === props.fighterId) || null}
                      onChange={handleChange}
                      options={props.contenders}
                      isSearchable={true}
                      placeholder='Purchase tickets from a specific fighter/athlete?'
                    />
                    {/* <Form.Control.Feedback type="invalid">Check!</Form.Control.Feedback>
                           <Form.Control.Feedback>Ok!</Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm='12' className='mt-3'>
                <div className='custom-control custom-checkbox'>
                  <input
                    type='checkbox'
                    className='custom-control-input'
                    id='consent'
                    onChange={handleCheckboxcChange}
                    required
                  />
                  <Form.Label
                    className='custom-control-label'
                    htmlFor='consent'
                  >
                    {" "}
                    I agree to{" "}
                    <Link
                      to={{
                        pathname: "/terms-of-service",
                        state: {
                          from: location.pathname,
                        },
                      }}
                    >
                      {" "}
                      <u>Terms of use</u>
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={{
                        pathname: "/privacy-policy",
                        state: {
                          from: location.pathname,
                        },
                      }}
                    >
                      {" "}
                      <u>Privacy Policy.</u>
                    </Link>{" "}
                  </Form.Label>
                  <Form.Control.Feedback type='invalid'>
                    You must agree before submitting.
                  </Form.Control.Feedback>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='12' className='mt-3'>
                <AlertNotification />
              </Col>
            </Row>
            <Row>
              <Col sm='12' className='text-right mt-4' style={{ zIndex: "0" }}>
                {/* <button type="button" className="btn text-primary btn-link mr-3"><i className="ri-printer-line"></i> Download Print</button> */}
                <button
                  className='btn btn-hover iq-button btn-primary1'
                  type='submit'
                  disabled={!stripe || loading}
                >
                  Submit
                </button>
              </Col>
              <Col sm='12' className='mt-'>
                <b className='text-danger'>Notes:</b>
                <p>
                  If you are pre-purchasing a live event it will be available on
                  the date/time of event and can easily be found under “My List”
                  for your convenience.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
export default CheckoutForm;
