import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import "swiper/swiper-bundle.css";
import PaymentService from "../../services/payment.service";
import TicketService from "../../services/ticket.service";
import CheckoutForm from "../payment/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VideoPlayer from "../video/VideoPlayer";
import CommingSoon from "../utils/CommingSoon";
import boutService from "../../services/bout.service";
import Recent from "./Recent";
import authService from "../../services/auth.service";

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PPVEvent = (props) => {
  let navigate = useNavigate();
  //const selectedData = props.event;
  let purchasedTicket = null;
  const [ticket, setTicket] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [paymentId, setPaymentId] = useState();
  const [contenders, setContenders] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [selectedData, setSelectedData] = useState(props.event);
  const [isEmbed, setIsEmbed] = useState(props.embed ? true : false);
  const [isValidStateCode, setIsValidStateCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [payType, setPayType] = useState("PPV Event");
  const [stripChargeData, setStripChargeData] = useState();
  const data = {
    type: "PPV Event",
    priceId: "",
    priceRate: selectedData.price,
    qty: 1,
  };

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: false,
    fluid: true,
    liveui: true,
    poster: selectedData.event_promotional_photo,
    sources: {
      src: selectedData.liveStreamURL,
      type: selectedData.isReplay ? "video/mp4" : "application/x-mpegURL",
    },
  };

  const appearance = {
    theme: "night",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const isCommingSoon = (date) => {
    const today = new Date();
    if (today > date) {
      return true;
    }
    return false;
  };

  const findTicket = async () => {
    const ticketData = {
      userId: currentUser.user.id,
      eventId: selectedData.id,
    };

    purchasedTicket = await TicketService.findTicketByUserEvent(ticketData);

    if (!purchasedTicket) {
      return null;
    }

    return purchasedTicket;
  };

  const generatePPVTicket = async () => {
    let confirmTkt = await findTicket();
    if (confirmTkt) {
      setTicket(confirmTkt);
      setLoading(false);
      return;
    }

    if (selectedData.isInternational) {
      authService.verifyIP().then((response) => {
        //console.log("IP",response);
        if (
          response.country.isoCode == "US" ||
          response.country.isoCode == "CA"
        ) {
          setIsValidStateCode(false);
          setLoading(false);
          return;
        }
      });
    }
    if (!confirmTkt) {
      if (
        selectedData.price == null ||
        selectedData.price == undefined ||
        selectedData.price == "" ||
        selectedData.price == 0
      ) {
        return;
      }

      const data = {
        customerId: currentUser.user.stripeCustomerId,
        amount: (selectedData.price * 100).toFixed(0),
        items: 1,
        description: "Event Name:: " + selectedData.name,
        metadata: {
          eventId: selectedData.id,
          eventName: selectedData.name,
          eventDate: selectedData.eventDate,
          eventTime: selectedData.eventTime,
          eventLocation: selectedData.eventLocation,
          type: "PPV Event",
        },
      };
      setStripChargeData(data);
      // Create the Subscription
      const res = await PaymentService.charge(data);
      const { paymentId } = res;
      setClientSecret(res.clientSecret);
      setPaymentId(paymentId);
    }

    setLoading(false);
  };

  const listContenders = async () => {
    let listFighters = [];
    await boutService
      .listContendersByEvent({ eventId: selectedData.id })
      .then((response) => {
        if (response) {
          response.map((item) => {
            let contender1 = item.fighter1?.user;
            let contender2 = item.fighter2?.user;

            if (contender1) {
              listFighters.push({
                value: contender1.id,
                label: contender1.fullName,
              });
            }
            if (contender2) {
              listFighters.push({
                value: contender2.id,
                label: contender2.fullName,
              });
            }
          });
          setContenders(listFighters);
        }
      });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
    setLoading(true);
    generatePPVTicket();
    listContenders();
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <div className='main-content'>
          {!isValidStateCode && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <section className='text-center'>
                    <h1 className='display-4 mb-5'>Sorry!</h1>
                    <p className='lead'>
                      Due to streaming media rights this PPV is ONLY available
                      for purchase outside the US and Canada.
                    </p>
                  </section>
                </div>
              </div>
            </div>
          )}
          {!ticket && isValidStateCode && (
            <Container>
              <Row className='justify-content-center align-items-center height-self-center'>
                <section>
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm
                        event={selectedData}
                        data={data}
                        contenders={contenders}
                        payType={payType}
                        embed={isEmbed}
                        paymentId={paymentId}
                        stripChargeData={stripChargeData}
                        fighterId={props.fighterId ? props.fighterId.toString() : null}
                      />
                    </Elements>
                  )}
                </section>
              </Row>
            </Container>
          )}
          {ticket &&
            isValidStateCode &&
            !isCommingSoon(new Date(selectedData.event_date)) && (
              <>
                <CommingSoon data={selectedData}></CommingSoon>
                {selectedData && !props.embed && (
                  <Recent event={selectedData}></Recent>
                )}
              </>
            )}
          {ticket &&
            isValidStateCode &&
            isCommingSoon(new Date(selectedData.event_date)) && (
              <div className='container-fluid'>
                <div className='row'>
                  <div
                    className='col-lg-12'
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <VideoPlayer {...videoJsOptions}></VideoPlayer>
                  </div>
                </div>
                {selectedData && !props.embed && (
                  <Recent event={selectedData}></Recent>
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default PPVEvent;
